<template>
  <ion-item :lines="lines" :detail="!readonly" @click="openModal">
    <ion-label>
      <span
        :style="
          'font-weight: bold;' +
          (isParentField === true ? 'color: var(--ion-color-primary)' : '')
        "
        >{{ displayTitle }}</span
      >
      <span
        style="color: var(--ion-color-danger)"
        v-if="required && !isParentField"
        >*</span
      >
      <div
        v-if="isParentField"
        style="
          padding-top: 5px;
          padding-bottom: 5px;
          width: 100%;
          height: auto;
          overflow: auto;
        "
        v-html="displayText === undefined ? '' : displayText"
      ></div>
    </ion-label>
    <ion-text
      v-if="!isParentField"
      style="text-align: right; margin-top: 10px; margin-bottom: 10px"
      v-html="displayText === 'undefined' ? '' : displayText"
    ></ion-text>
    <ion-modal
      :is-open="modalIsOpen"
      @didDismiss="closeModal"
      :show-backdrop="true"
      :presenting-element="presentingElementValue"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              v-if="isMobile"
              @click="closeModal"
              fill="clear"
              color="primary"
              >Abbrechen</ion-button
            >
          </ion-buttons>
          <ion-title>{{ displayTitle }}</ion-title>
          <ion-buttons slot="end">
            <ion-button
              v-if="isMobile"
              @click="saveModal"
              fill="clear"
              color="primary"
              >Anwenden</ion-button
            >
            <ion-button v-if="!isMobile" fill="clear" @click="closeModal"
              ><custom-icon slot="icon-only" name="closeOutline"></custom-icon
            ></ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar>
          <ion-searchbar
            :placeholder="displayTitle + ' suchen'"
            @ionInput="search"
            :value="searchInList"
          ></ion-searchbar>
        </ion-toolbar>
      </ion-header>
      <ion-content
        :style="
          !isMobile
            ? 'height: calc(100% - 164px)'
            : 'height: calc(100% - 108px)'
        "
      >
        <ion-list :inset="true">
          <ion-item-group>
            <ion-radio-group :value="value._id">
              <ion-item
                @click="value = option"
                v-for="option in filteredObjects"
                :key="option._id"
              >
                <ion-label
                  v-html="
                    formatText(referencedModel, referenceDisplayFormat, option)
                  "
                ></ion-label>
                <ion-radio slot="start" :value="option._id"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-item-group>
        </ion-list>
      </ion-content>
      <ion-footer v-if="!isMobile">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button fill="clear" @click="closeModal">Abbrechen</ion-button>
            <ion-button color="primary" @click="saveModal" fill="clear"
              >Anwenden</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </ion-modal>
  </ion-item>
</template>

<script>
  import {
    IonText,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItemGroup,
    IonItem,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonFooter,
    IonTitle,
    IonSearchbar,
    modalController,
  } from '@ionic/vue';
  import { Data } from '@/services/dataService';
  import { mapState } from 'vuex';
  import { Auth } from '@/services/authService';
  import * as allIcons from 'ionicons/icons';
  import moment from 'moment';

  export default {
    components: {
      IonText,
      IonModal,
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonContent,
      IonList,
      IonItemGroup,
      IonItem,
      IonLabel,
      IonRadio,
      IonRadioGroup,
      IonFooter,
      IonTitle,
      IonSearchbar,
    },
    props: {
      name: {
        type: String,
        default: '',
      },
      modelValue: {
        type: Object,
        default: () => {
          return { _id: '' };
        },
      },
      referencedModel: {
        type: String,
        default: '',
      },
      referenceDisplayFormat: {
        type: String,
        default: '{{name}}',
      },
      label: {
        type: Array,
        default: () => [],
      },
      isParentField: {
        type: Boolean,
        default: false,
      },
      presentingElement: {
        type: HTMLElement,
        default: undefined,
      },
      required: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      lines: {
        type: String,
        default: 'none',
      },
    },
    data() {
      return {
        modalIsOpen: false,
        value: 0,
        presentingElementValue: undefined,
        searchInList: '',
        referenceList: [],
      };
    },
    computed: {
      ...mapState('models', ['models']),
      ...mapState('user', ['user']),
      isMobile: function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      displayTitle() {
        let title = this.name;

        if (this.label !== undefined) {
          if (
            this.label.findIndex((e) => e.language === this.language) !== -1
          ) {
            return this.label[
              this.label.findIndex((e) => e.language === this.language)
            ].text;
          }
        }

        return title === undefined ? '' : title;
      },
      displayText() {
        try {
          if (this.referenceDisplayFormat !== undefined) {
            var text = this.formatText(
              this.referencedModel,
              this.referenceDisplayFormat,
              this.value
            );
            return text === undefined ? '' : text;
          } else {
            return this.value.name === undefined ? '' : this.value.name;
          }
        } catch {
          return '';
        }
      },
      filteredObjects: function () {
        const modelData =
          this.models[
            this.models.findIndex((e) => e.name === this.referencedModel)
          ];
        if (
          this.searchInList === '' ||
          this.searchInList === undefined ||
          this.searchInList === null
        ) {
          if (modelData.archive === true) {
            return this.referenceList.filter((e) => e._archive !== 1);
          } else {
            return this.referenceList;
          }
        }

        var result = [];
        this.referenceList.forEach((object) => {
          var isValid = false;
          modelData.fields.forEach((field) => {
            if (!isValid) {
              switch (field.dataType) {
                case 'ObjectReference':
                case 'FileReference':
                  var model =
                    this.models[
                      this.models.findIndex(
                        (e) => e.name === field.referencedModel
                      )
                    ];

                  if (model !== undefined) {
                    if (model.fields !== undefined) {
                      model.fields.forEach((childField) => {
                        var childObject = object[field.name];
                        if (!isValid) {
                          isValid = (
                            childObject[childField.name] === undefined
                              ? ''
                              : childObject[childField.name]
                          )
                            .toString()
                            .toLowerCase()
                            .includes(this.searchInList.toLowerCase());
                        }
                      });
                    }
                  }
                  break;
                default:
                  if (!isValid) {
                    isValid = (
                      object[field.name] === undefined ? '' : object[field.name]
                    )
                      .toString()
                      .toLowerCase()
                      .includes(this.searchInList.toLowerCase());
                  }
                  break;
              }
            }
          });

          if (isValid) {
            result.push(object);
          }
        });

        if (modelData.archive === true) {
          return result.filter((e) => e._archive !== 1);
        } else {
          return result;
        }
      },
    },
    async mounted() {
      this.presentingElementValue = this.presentingElement;
      this.value = this.modelValue;
    },
    methods: {
      async openModal() {
        if (this.readonly === true) {
          return;
        }
        this.referenceList = [];
        await this.getCurrentEmployee();
        await this.loadData();
        this.value = this.modelValue;
        this.modalIsOpen = true;
      },
      async loadData() {
        await this.getCurrentEmployee();
        if (!this.isAdministrator) {
          var filter = {};

          switch (this.referencedModel) {
            case 'projectTime':
              filter = {
                'employee._id': { $eq: { $oid: this.employee._id } },
              };
              break;
            case 'employee':
              filter = {
                _id: { $eq: { $oid: this.employee._id } },
              };
              break;
            default:
              break;
          }

          this.referenceList = await Data.Instance.find(
            this.referencedModel,
            filter,
            {}
          );
        } else {
          this.referenceList = await Data.Instance.find(
            this.referencedModel,
            {},
            {}
          );
        }
      },
      async getCurrentEmployee() {
        if (this.user.firstName === undefined) {
          await Auth.Instance.loadUserInfo();
        }

        var e = await Data.Instance.find(
          'employee',
          { name: { $eq: this.user.firstName + ' ' + this.user.lastName } },
          {}
        );

        if (e !== undefined) {
          if (e.length !== 0) {
            this.employee = e[0];
          }
        }
      },
      async modalTop() {
        return await modalController.getTop();
      },
      async closeModal() {
        this.modalIsOpen = false;
      },
      async saveModal() {
        await this.setValue();
        this.modalIsOpen = false;
      },
      async setValue() {
        await this.$emit('update:modelValue', this.value);
      },
      async search(event) {
        this.searchInList = event.target.value;
      },
      formatText(model, format, object) {
        if (format === undefined || object === undefined) {
          return '';
        }
        var text = format.toString();
        this.models[
          this.models.findIndex((e) => e.name === model)
        ].fields.forEach((field) => {
          var formattedValue = object[field.name];
          switch (field.dataType) {
            case 'ObjectReference':
              formattedValue =
                object[field.name] !== undefined
                  ? object[field.name].name === undefined
                    ? ''
                    : object[field.name].name
                  : '';
              break;
            case 'FileReference':
              formattedValue = '<div style="width: 100%; height: 40px">';
              if (field.referenceType === 'Single') {
                formattedValue += `<img style="width: 40px; height: 40px; margin-right: 5px" src="${
                  object[field.name].data
                }" />`;
              } else {
                object[field.name].forEach((file) => {
                  console.log(file);
                  formattedValue += `<img style="width: 40px; height: 40px; margin-right: 5px" src="${file.data}" />`;
                });
              }
              formattedValue += '</div>';
              break;
            case 'Date':
              formattedValue = this.formatDate(object[field.name]);
              break;
            case 'Enumeration':
              var icon = this.formatEnumerationIcon(field, object[field.name]);
              if (icon !== '') {
                icon = allIcons[icon]
                  .replaceAll('data:image/svg+xml;utf8,', '')
                  .replaceAll(
                    '<svg ',
                    '<svg style="width: 28px; height: 28px; stroke-width: var(--ionicon-stroke-width, 32px)"'
                  )
                  .replaceAll(
                    'class=',
                    'style="fill: none; stroke-width: var(--ionicon-stroke-width, 32px); stroke: var(--ion-color-primary, #3880ff)" class='
                  );
                formattedValue = icon;
              } else {
                formattedValue = this.formatEnumeration(
                  object[field.name],
                  field.enumeration.options
                );
              }

              break;
            default:
              break;
          }
          text = text.replaceAll('{{' + field.name + '}}', formattedValue);
        });

        return text;
      },
      formatEnumeration(value, options) {
        if (options.findIndex((e) => e.value === value) !== -1) {
          var option = options[options.findIndex((e) => e.value === value)];

          if (option.label !== undefined) {
            if (
              option.label.findIndex((e) => e.language === this.language) !== -1
            ) {
              return option.label[
                option.label.findIndex((e) => e.language === this.language)
              ].text;
            } else {
              return option.name;
            }
          } else {
            return option.name;
          }
        }

        return '';
      },
      formatEnumerationIcon(field, value) {
        var options = field.enumeration.options;
        if (options === null || options === undefined) {
          return '';
        }

        if (
          options.length === 0 ||
          options.length === undefined ||
          options.length === null
        ) {
          return '';
        }

        if (options.findIndex((e) => e.value === value) !== -1) {
          return options[options.findIndex((e) => e.value === value)].icon !==
            undefined &&
            options[options.findIndex((e) => e.value === value)].icon !== null
            ? options[options.findIndex((e) => e.value === value)].icon
            : '';
        }

        return '';
      },
      formatDate(date) {
        return moment(date).toDate().toLocaleDateString();
      },
      formatTime(date) {
        return moment(date).toDate().toLocaleTimeString();
      },
      formatTimeSimple(date) {
        return moment(date).format('HH:mm');
      },
    },
    watch: {
      modelValue(n) {
        if (
          JSON.parse(JSON.stringify(this.value)) !==
          JSON.parse(JSON.stringify(n))
        ) {
          if (n === undefined || n._id === undefined) {
            this.value = { _id: '' };
          } else {
            this.value = n;
          }
        }
      },
      presentingElement(n) {
        this.presentingElementValue = n;
      },
    },
  };
</script>

<style scoped>
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-toolbar {
    --background: var(--ion-color-light);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-img > img {
    border-radius: 10px;
  }

  ion-item.item-radio-checked {
    --background: var(--ion-color-primary-tint);
  }

  ion-item.item-radio-checked > ion-radio {
    --ion-color-base: var(
      --ion-item-color,
      var(--ion-text-color, #000)
    ) !important;
    --color-checked: var(
      --ion-item-color,
      var(--ion-text-color, #000)
    ) !important;
  }
</style>
