<template>
  <div>
    <ion-list
      :inset="true"
      v-if="
        parentField !== '' &&
        parentField !== undefined &&
        modelData !== undefined &&
        modelData.fields !== undefined
      "
    >
      <ion-item-group>
        <ObjectReferenceInput
          v-if="
            modelData.fields[
              modelData.fields.findIndex((e) => e.name === parentField)
            ].dataType === 'ObjectReference' && object !== undefined
          "
          v-model="
            object[
              modelData.fields[
                modelData.fields.findIndex((e) => e.name === parentField)
              ].name
            ]
          "
          :name="
            modelData.fields[
              modelData.fields.findIndex((e) => e.name === parentField)
            ].name
          "
          :label="
            modelData.fields[
              modelData.fields.findIndex((e) => e.name === parentField)
            ].label
          "
          lines="none"
          :readonly="true"
          :isParentField="true"
          :required="
            modelData.fields[
              modelData.fields.findIndex((e) => e.name === parentField)
            ].required
          "
          :referencedModel="
            modelData.fields[
              modelData.fields.findIndex((e) => e.name === parentField)
            ].referencedModel
          "
          :referenceDisplayFormat="
            modelData.fields[
              modelData.fields.findIndex((e) => e.name === parentField)
            ].referenceDisplayFormat === undefined
              ? '{{name}}'
              : modelData.fields[
                  modelData.fields.findIndex((e) => e.name === parentField)
                ].referenceDisplayFormat
          "
          :presentingElement="presentingElementValue"
        />
      </ion-item-group>
    </ion-list>
    <ion-list :inset="true">
      <ion-item-group>
        <template v-for="(field, index) in modelDataFields">
          <EnumerationInput
            v-show="field.show"
            v-bind:key="field"
            v-bind:index="index"
            v-if="field.dataType === 'Enumeration' && object !== undefined"
            v-model="object[field.name]"
            :name="field.name"
            :label="field.label"
            :lines="
              index ===
              modelData.fields.filter(
                (e) => e.name !== (parentField === undefined ? '' : parentField)
              ).length -
                1
                ? 'none'
                : 'inset'
            "
            :options="field.enumeration.options"
            :required="field.required"
            :readonly="field.readonly === true"
            :showAsStatus="
              field.enumeration === undefined
                ? false
                : field.enumeration.showAsStatus === true
            "
            :presentingElement="presentingElementValue"
          />
          <ObjectReferenceInput
            v-show="field.show"
            v-bind:key="field"
            v-bind:index="index"
            v-if="field.dataType === 'ObjectReference' && object !== undefined"
            v-model="object[field.name]"
            :name="field.name"
            :label="field.label"
            :lines="
              index ===
              modelData.fields.filter(
                (e) => e.name !== (parentField === undefined ? '' : parentField)
              ).length -
                1
                ? model === 'projectAccounting'
                  ? 'inset'
                  : 'none'
                : 'inset'
            "
            :required="field.required"
            :referencedModel="field.referencedModel"
            :referenceDisplayFormat="
              field.referenceDisplayFormat === undefined
                ? '{{name}}'
                : field.referenceDisplayFormat
            "
            :presentingElement="presentingElementValue"
          />
          <DateInput
            v-show="field.show"
            v-bind:key="field"
            v-bind:index="index"
            v-if="field.dataType === 'Date' && object !== undefined"
            v-model="object[field.name]"
            :name="field.name"
            :label="field.label"
            :format="field.format === undefined ? 'DD.MM.YYYY' : field.format"
            :lines="
              index ===
              modelData.fields.filter(
                (e) => e.name !== (parentField === undefined ? '' : parentField)
              ).length -
                1
                ? 'none'
                : 'inset'
            "
            :required="field.required"
            :presentingElement="presentingElementValue"
          />
          <TimeInput
            v-show="field.show"
            v-bind:key="field"
            v-bind:index="index"
            v-if="field.dataType === 'Time' && object !== undefined"
            v-model="object[field.name]"
            :name="field.name"
            :label="field.label"
            :minuteValues="
              modelData.name !== 'projectTime' &&
              modelData.name !== 'buildingDiary'
                ? undefined
                : '0, 15, 30, 45'
            "
            :format="field.format === undefined ? 'HH:mm' : field.format"
            :lines="
              index ===
              modelData.fields.filter(
                (e) => e.name !== (parentField === undefined ? '' : parentField)
              ).length -
                1
                ? 'none'
                : 'inset'
            "
            :required="field.required"
            :presentingElement="presentingElementValue"
          />

          <MultilineTextInput
            v-show="field.show"
            v-bind:key="field"
            v-bind:index="index"
            v-if="field.dataType === 'MultilineString' && object !== undefined"
            v-model="object[field.name]"
            :name="field.name"
            :label="field.label"
            :lines="
              index ===
              modelData.fields.filter(
                (e) => e.name !== (parentField === undefined ? '' : parentField)
              ).length -
                1
                ? 'none'
                : 'inset'
            "
            :required="field.required"
          />
          <TextInput
            v-show="field.show"
            v-bind:key="field"
            v-bind:index="index"
            v-if="
              (field.dataType === 'String' ||
                field.dataType === 'Integer' ||
                field.dataType === 'Double' ||
                field.dataType === 'Password' ||
                field.dataType === 'SequenceNumber') &&
              object !== undefined
            "
            v-model="object[field.name]"
            :name="field.name"
            :label="field.label"
            :placeholder="
              field.dataType === 'SequenceNumber'
                ? 'wird berechnet / manuelle Eingabe'
                : ''
            "
            :readonly="field.dataType === 'SequenceNumber' && add === false"
            :lines="
              index ===
              modelData.fields.filter(
                (e) => e.name !== (parentField === undefined ? '' : parentField)
              ).length -
                1
                ? 'none'
                : 'inset'
            "
            :required="field.required"
            :type="
              field.dataType === 'String'
                ? 'text'
                : field.dataType === 'Integer' ||
                  field.dataType === 'SequenceNumber'
                ? 'number'
                : field.dataType === 'Password'
                ? 'password'
                : field.dataType === 'Double'
                ? 'Double'
                : ''
            "
          />
          <ion-item
            v-show="field.show"
            v-if="
              field.dataType !== 'String' &&
              field.dataType !== 'Integer' &&
              field.dataType !== 'Double' &&
              field.dataType !== 'Password' &&
              field.dataType !== 'ObjectReference' &&
              field.dataType !== 'MultilineString' &&
              field.dataType !== 'Enumeration' &&
              field.dataType !== 'Date' &&
              field.dataType !== 'Time' &&
              field.dataType !== 'SequenceNumber'
            "
            @click="clickOnInput(field)"
            :detail="
              field.dataType === 'ObjectReference' ||
              field.dataType === 'Enumeration'
            "
            :lines="
              index ===
              modelData.fields.filter(
                (e) => e.name !== (parentField === undefined ? '' : parentField)
              ).length -
                1
                ? 'none'
                : 'inset'
            "
            :value="field.name"
            v-bind:key="field"
            v-bind:index="index"
          >
            <ion-label>
              <span style="font-weight: bold">
                {{ formatLabel(field.label, field.name) }}</span
              >
              <span style="color: var(--ion-color-danger)" v-if="field.required"
                >*</span
              >
              <FilesView
                :modalLabel="
                  detailTitle.replaceAll('Bilder', '') +
                  '<br/>' +
                  formatLabel(field.label, field.name)
                "
                v-if="
                  field.dataType === 'FileReference' && object !== undefined
                "
                @addFile="
                  (filename, data) => addFile(field.name, filename, data)
                "
                @deleteFile="(filename) => deleteFile(field.name, filename)"
                :imagesOnly="true"
                :files="object[field.name]"
                :presentingElement="presentingElementValue"
              />
            </ion-label>

            <ion-input
              @input="update"
              v-if="
                (field.dataType === 'String' || field.dataType === 'Integer') &&
                object !== undefined
              "
              v-model="object[field.name]"
              :required="field.required"
              placeholder=""
              style="text-align: right"
              :type="
                field.dataType === 'String'
                  ? 'text'
                  : field.dataType === 'Integer'
                  ? 'number'
                  : field.dataType === 'Password'
                  ? 'password'
                  : ''
              "
            ></ion-input>

            <ion-text
              style="text-align: right"
              v-if="
                (field.dataType === 'ObjectReference' ||
                  field.dataType === 'Date' ||
                  field.dataType === 'Time' ||
                  field.dataType === 'DateTime' ||
                  field.dataType === 'TimeSpan') &&
                object !== undefined
              "
              v-html="formatField(field, object[field.name])"
            >
            </ion-text>
            <br />
            <ion-textarea
              rows="6"
              cols="20"
              v-model="object[field.name]"
              v-if="
                field.dataType === 'MultilineString' && object !== undefined
              "
            >
            </ion-textarea>
            <ion-modal
              :is-open="editField === field.name && field.dataType !== 'String'"
              @didDismiss="editField = ''"
              :show-backdrop="true"
              :presenting-element="presentingElementValue"
            >
              <ion-header>
                <ion-toolbar>
                  <ion-buttons slot="start">
                    <ion-button
                      @click="editField = ''"
                      fill="clear"
                      color="primary"
                      >Abbrechen</ion-button
                    >
                  </ion-buttons>
                  <ion-title>{{
                    formatLabel(field.label, field.name)
                  }}</ion-title>
                  <ion-buttons slot="end">
                    <ion-button
                      @click="setValueFromModal"
                      fill="clear"
                      color="primary"
                      >Anwenden</ion-button
                    >
                  </ion-buttons>
                </ion-toolbar>
                <ion-toolbar
                  v-if="
                    field.dataType === 'FileReference' ||
                    field.dataType === 'ObjectReference'
                  "
                >
                  <ion-searchbar
                    v-if="
                      field.dataType === 'FileReference' ||
                      field.dataType === 'ObjectReference'
                    "
                    :placeholder="
                      formatLabel(field.label, field.name) + ' suchen'
                    "
                  ></ion-searchbar>
                </ion-toolbar>
              </ion-header>
              <ion-content>
                <ion-datetime
                  v-if="
                    field.dataType === 'DateTime' ||
                    field.dataType === 'Date' ||
                    field.dataType === 'Time'
                  "
                  style="width: 100%; height: 350px"
                  :presentation="
                    field.dataType === 'DateTime'
                      ? 'date-time'
                      : field.dataType === 'Time'
                      ? 'time'
                      : field.dataType
                  "
                  size="cover"
                  @ionChange="(ev) => (currentDateModalValue = ev.detail.value)"
                >
                </ion-datetime>
                <ion-list
                  v-if="
                    field.dataType === 'FileReference' ||
                    field.dataType === 'ObjectReference'
                  "
                  :inset="true"
                >
                  <ion-item-group>
                    <ion-radio-group
                      :value="
                        object[field.name] === undefined
                          ? ''
                          : object[field.name]._id
                      "
                    >
                      <ion-item
                        v-for="option in referenceList"
                        :key="option.value"
                      >
                        <ion-label>{{
                          field.referenceDisplayFormat === undefined
                            ? option.name
                            : formatText(
                                field.referencedModel,
                                field.referenceDisplayFormat,
                                option
                              )
                        }}</ion-label>
                        <ion-radio
                          slot="start"
                          @click="currentReferencedObject = option"
                          :value="option._id"
                        ></ion-radio>
                        <ion-icon
                          v-if="false"
                          slot="start"
                          :md="calendarIcon"
                          :ios="calendarIcon"
                        ></ion-icon>
                      </ion-item>
                    </ion-radio-group>
                  </ion-item-group>
                </ion-list>
              </ion-content>
            </ion-modal>
          </ion-item>
        </template>
        <ion-item v-if="model === 'projectAccounting'">
          <ion-label>
            <span style="font-weight: bold">Abrechnungsart</span>
          </ion-label>
          <ion-text
            >{{
              modelValue.projectId === undefined
                ? ''
                : modelValue.projectId.projectAccountingType === undefined ||
                  modelValue.projectId.projectAccountingType === 0
                ? 'Tagessatz'
                : 'Stunden'
            }}
          </ion-text>
        </ion-item>
        <ion-item v-if="model === 'projectAccounting'">
          <ion-label>
            <span style="font-weight: bold"> gefahrene KM abbrechnen</span>
          </ion-label>
          <ion-text
            >{{
              modelValue.projectId === undefined
                ? ''
                : modelValue.projectId.projectAccountingDrivingDistance ===
                    undefined ||
                  modelValue.projectId.projectAccountingDrivingDistance === 0
                ? 'Ja'
                : 'Nein'
            }}
          </ion-text>
        </ion-item>
      </ion-item-group>
    </ion-list>
  </div>
</template>

<script>
  import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonText,
    IonRadioGroup,
    IonRadio,
    IonItemGroup,
    IonModal,
    IonDatetime,
    IonTextarea,
    pickerController,
  } from '@ionic/vue';
  import {
    chevronBackOutline,
    calendarOutline,
    addOutline,
  } from 'ionicons/icons';
  import { Data } from '@/services/dataService';
  import { mapState, mapActions } from 'vuex';
  import moment from 'moment';
  import FilesView from '@/components/FilesView.vue';
  import EnumerationInput from '@/components/inputs/EnumerationInput.vue';
  import ObjectReferenceInput from '@/components/inputs/ObjectReferenceInput.vue';
  import DateInput from '@/components/inputs/DateInput.vue';
  import TimeInput from '@/components/inputs/TimeInput.vue';
  import MultilineTextInput from '@/components/inputs/MultilineTextInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';

  export default {
    components: {
      IonHeader,
      IonToolbar,
      IonTitle,
      IonButtons,
      IonButton,
      IonContent,
      IonIcon,
      IonList,
      IonItem,
      IonLabel,
      IonInput,
      IonItemGroup,
      IonModal,
      IonDatetime,
      IonText,
      IonTextarea,
      IonRadioGroup,
      IonRadio,
      IonSearchbar,
      FilesView,
      EnumerationInput,
      ObjectReferenceInput,
      DateInput,
      TimeInput,
      TextInput,
      MultilineTextInput,
    },
    data() {
      return {
        loading: true,
        backIcon: chevronBackOutline,
        addIcon: addOutline,
        calendarIcon: calendarOutline,
        object: undefined,
        title: '',
        editField: '',
        currentDateModalValue: new Date(),
        currentReferencedObject: {},
        currentEnumerationValue: 0,
        referenceList: [],
        presentingElementValue: undefined,
      };
    },
    props: {
      modelValue: {
        type: Object,
        default: () => {
          return {};
        },
      },
      model: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: '',
      },
      add: {
        type: Boolean,
        default: false,
      },
      parentField: {
        type: String,
        default: '',
      },
      presentingElement: {
        type: Object,
        default: undefined,
      },
    },
    computed: {
      ...mapState('models', ['models']),
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      modelData: function () {
        const defaults = {};

        if (this.models === undefined || this.models === null) {
          return defaults;
        }

        let index = this.models.findIndex((e) => e.name === this.model);

        if (index === -1) {
          return defaults;
        }

        return this.models[index];
      },
      modelDataFields() {
        if (this.modelData.fields === undefined) {
          return [];
        }

        var data = JSON.parse(
          JSON.stringify(
            this.modelData.fields.filter(
              (e) =>
                e.name !==
                (this.parentField === undefined ? '' : this.parentField)
            )
          )
        );

        data.forEach((element) => {
          if (this.model !== 'receipts') {
            element.show = true;
          } else {
            var isSet = false;
            switch (
              this.object === undefined
                ? -1
                : this.object['expense'] === undefined ||
                  this.object['expense'] === null
                ? -1
                : parseInt(this.object['expense'])
            ) {
              case 0:
                if (
                  element.name === 'projectId' ||
                  element.name === 'personsCatered' ||
                  element.name === 'event'
                ) {
                  element.show = false;
                  isSet === true;
                }
                break;
              default:
                break;
            }

            if (!isSet) {
              element.show = true;
            }
          }
        });

        return data;
      },
      listTitle() {
        if (
          this.modelData.listLabel === undefined ||
          this.modelData.listLabel === null
        ) {
          return this.modelData.name;
        }

        if (this.modelData.listLabel.length === 0) {
          return this.modelData.name;
        }

        if (this.modelData.listLabel.length === 1) {
          return this.modelData.listLabel[0].text;
        }

        if (
          this.modelData.listLabel.findIndex(
            (e) => e.language === this.language
          ) !== -1
        ) {
          return this.modelData.listLabel[
            this.modelData.listLabel.findIndex(
              (e) => e.language === this.language
            )
          ].text;
        } else {
          if (
            this.modelData.listLabel.findIndex((e) => e.language === 'en') !==
            -1
          ) {
            return this.modelData.listLabel[
              this.modelData.listLabel.findIndex((e) => e.language === 'en')
            ].text;
          } else {
            return this.modelData.name;
          }
        }
      },
      detailTitle() {
        if (
          this.modelData.detailLabel === undefined ||
          this.modelData.detailLabel === null
        ) {
          return this.modelData.name;
        }

        if (this.modelData.detailLabel.length === 0) {
          return this.modelData.name;
        }

        if (this.modelData.detailLabel.length === 1) {
          return this.modelData.detailLabel[0].text;
        }

        if (
          this.modelData.detailLabel.findIndex(
            (e) => e.language === this.language
          ) !== -1
        ) {
          return this.modelData.detailLabel[
            this.modelData.detailLabel.findIndex(
              (e) => e.language === this.language
            )
          ].text;
        } else {
          if (
            this.modelData.detailLabel.findIndex((e) => e.language === 'en') !==
            -1
          ) {
            return this.modelData.detailLabel[
              this.modelData.detailLabel.findIndex((e) => e.language === 'en')
            ].text;
          } else {
            return this.modelData.name;
          }
        }
      },
      prefersDark: function () {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      },
      isMobile: function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );
      },
    },
    async mounted() {
      await this.init();
    },
    methods: {
      ...mapActions('models', ['loadModels']),
      async init() {
        this.loading = true;
        this.editField = '';
        this.presentingElementValue = this.presentingElement;
        if (!this.add) {
          if (this.id !== undefined && this.id !== '') {
            this.object = (
              await Data.Instance.find(
                this.model,
                { _id: { $eq: { $oid: this.id } } },
                {}
              )
            )[0];
          }
        } else {
          var o = JSON.parse(JSON.stringify(this.modelValue));
          this.object = o;
        }
        this.loading = false;
      },
      async update() {
        await this.$emit('update:modelValue', this.object);
      },
      async clickOnInput(field) {
        this.referenceList = [];

        switch (field.dataType) {
          case 'Date':
          case 'Time':
          case 'DateTime':
            this.currentDateModalValue =
              this.object === undefined
                ? new Date().toISOString()
                : this.object[field.name] === undefined
                ? new Date().toISOString()
                : this.object[field.name];
            this.editField = field.name;
            break;
          case 'ObjectReference':
            this.loading = true;
            this.referenceList = await Data.Instance.find(
              field.referencedModel,
              {},
              {}
            );
            this.currentReferencedObject =
              this.object === undefined
                ? { _id: '' }
                : this.object[field.name] === undefined ||
                  this.object[field.name] === ''
                ? { _id: '' }
                : this.object[field.name];
            this.loading = false;
            this.editField = field.name;
            break;
          default:
            this.editField = '';
            break;
        }
      },
      setValueFromModal() {
        if (this.object === undefined || this.object === null) {
          this.object = {};
        }
        var field =
          this.modelData.fields[
            this.modelData.fields.findIndex((e) => e.name === this.editField)
          ];
        switch (field.dataType) {
          case 'String':
            if (this.object[field.name] === undefined) {
              this.object[field.name] = '';
            }
            break;
          case 'Date':
          case 'Time':
          case 'DateTime':
            this.object[field.name] = this.currentDateModalValue;
            break;
          case 'FileReference':
          case 'ObjectReference':
            this.object[field.name] = this.currentReferencedObject;
            break;
          case 'Enumeration':
            this.object[field.name] = this.currentEnumerationValue;
            break;
          default:
            break;
        }

        this.editField = '';
        this.update();
      },
      addFile(fieldName, fileName, data) {
        if (
          this.object[fieldName] === null ||
          this.object[fieldName] === undefined
        ) {
          this.object[fieldName] = [];
        }

        var file = {
          _id: null,
          filename: fileName,
          metadata: {},
          data,
        };

        if (this.object[fieldName].length > 0) {
          this.object[fieldName].unshift(file);
        } else {
          this.object[fieldName].push(file);
        }
        this.update();
      },
      deleteFile(fieldName, file) {
        if (
          this.object[fieldName].findIndex(
            (e) => e.filename === file.filename
          ) === -1
        ) {
          console.log('File not found', file.filename);
        } else {
          this.object[fieldName][
            this.object[fieldName].findIndex(
              (e) => e.filename === file.filename
            )
          ].deleted = true;
          this.update();
        }
      },
      formatText(model, format, object) {
        const modelData =
          this.models[this.models.findIndex((e) => e.name === model)];
        if (format === undefined || object === undefined) {
          return '';
        }
        var text = format.toString();
        modelData.fields.forEach((field) => {
          var formattedValue = object[field.name];
          text = text.replaceAll('{{' + field.name + '}}', formattedValue);
        });
        return text;
      },
      formatLabel(labels, defaultValue) {
        if (labels === undefined || labels === null) {
          return defaultValue;
        }

        if (labels.length === 0) {
          return defaultValue;
        }

        if (labels.length === 1) {
          return labels[0].text;
        }

        if (labels.findIndex((e) => e.language === this.language) !== -1) {
          return labels[labels.findIndex((e) => e.language === this.language)]
            .text;
        } else {
          if (labels.findIndex((e) => e.language === 'en') !== -1) {
            return labels[labels.findIndex((e) => e.language === 'en')].text;
          } else {
            return defaultValue;
          }
        }
      },
      formatField(field, value) {
        switch (field.dataType) {
          case 'Enumeration':
            return this.formatEnumeration(value, field.enumeration.options);
          case 'Date':
            return this.formatDate(value);
          case 'Time':
            return this.formatTime(value);
          case 'DateTime':
            return this.formatDate(value) + ' ' + this.formatTime(value);
          case 'TimeSpan':
            return this.formatTimeSpan(value);
          case 'ObjectReference':
          case 'FileReference':
            return this.formatReference(field, value);
          case 'SequenceNumber':
            return this.add
              ? '<i style="color: var(--ion-color-medium)">wird berechnet</i>'
              : value;
          default:
            return value;
        }
      },
      formatReference(field, object) {
        try {
          if (field.referenceDisplayFormat !== undefined) {
            return this.formatText(
              field.referencedModel,
              field.referenceDisplayFormat,
              object
            );
          } else {
            return object.name;
          }
        } catch {
          return object;
        }
      },
      formatDate(date) {
        return moment(date).toDate().toLocaleDateString();
      },
      formatTime(date) {
        return moment(date).toDate().toLocaleTimeString();
      },
      formatTimeSpan(timeSpan) {
        return timeSpan;
      },
      formatEnumeration(value, options) {
        if (options.findIndex((e) => e.value === value) !== -1) {
          var option = options[options.findIndex((e) => e.value === value)];

          if (option.label !== undefined) {
            if (
              option.label.findIndex((e) => e.language === this.language) !== -1
            ) {
              return option.label[
                option.label.findIndex((e) => e.language === this.language)
              ].text;
            } else {
              return option.name;
            }
          } else {
            return option.name;
          }
        }

        return '';
      },
      formatEnumerationIcon(field, value) {
        var options = field.enumeration.options;
        if (options === null || options === undefined) {
          return '';
        }

        if (
          options.length === 0 ||
          options.length === undefined ||
          options.length === null
        ) {
          return '';
        }

        if (options.findIndex((e) => e.value === value) !== -1) {
          return options[options.findIndex((e) => e.value === value)].icon !==
            undefined &&
            options[options.findIndex((e) => e.value === value)].icon !== null
            ? options[options.findIndex((e) => e.value === value)].icon
            : '';
        }

        return '';
      },
    },
    watch: {
      object: {
        deep: true,
        handler(value) {
          if (JSON.stringify(value) !== JSON.stringify(this.modelValue)) {
            this.update();
          }
        },
      },
      presentingElement(n) {
        this.presentingElementValue = n;
      },
    },
  };
</script>

<style scoped>
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-toolbar {
    --background: var(--ion-color-light);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-img > img {
    border-radius: 10px;
  }
</style>
