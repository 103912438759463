<template>
    <ion-icon :ios="currentIcon" :md="currentIcon"></ion-icon>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import icons from './../composables/icons'

export default {
    components: {
        IonIcon
    },
    props: {
        name: {
            type: String,
            default: ''
        }
    },
    computed: {
        currentIcon () {
            return this.icon(this.name)
        }
    },
    data() {
        return {
            i: icons,
        };
    },
    methods: {
        icon(name) {
            return this.i[name];
        },
    }
}
</script>