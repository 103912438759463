import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bb90b98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_icon, {
          size: "large",
          md: _ctx.icon,
          ios: _ctx.icon
        }, null, 8, ["md", "ios"]),
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.message), 1),
        _createVNode(_component_ion_button, { href: "/landing" }, {
          default: _withCtx(() => [
            _createTextVNode("zurück")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}