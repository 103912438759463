
  import { Auth } from '@/services/authService';
  import { AlertInput, IonPage, IonSpinner } from '@ionic/vue';
  import { AuthActions } from 'baas-ionic-appauth';
  import { Subscription } from 'rxjs';
  import { defineComponent } from 'vue';
  import { mapActions } from 'vuex';
  import { User } from '@/models/user';

  export default defineComponent({
    name: 'Auth-Redirect',
    components: {
      IonPage,
      IonSpinner,
    },
    data() {
      return {
        sub: Subscription.EMPTY,
      };
    },
    created() {
      this.sub = Auth.Instance.events$.subscribe(async (action) => {
        if (action.action === AuthActions.SignInSuccess) {
          await Auth.Instance.loadUserInfo();
          this.$router.push('/home');
        }

        if (action.action === AuthActions.SignInFailed) {
          if (action.error === 'End-User aborted interaction') {
            this.$router.push('/landing');
          } else {
            this.$router.push(
              '/oidc/error?message=' +
                action.error +
                '&title=Fehler beim anmelden aufgetreten'
            );
          }
        }
      });
      Auth.Instance.user$.subscribe((u) => {
        this.updateUser(u);
      });
      const url = this.$route.fullPath;
      Auth.Instance.authorizationCallback(url);
    },
    beforeUnmount() {
      this.sub.unsubscribe();
    },
    methods: {
      ...mapActions('user', ['setUser']),
      async updateUser(u: any) {
        if (u === undefined || u === null) {
          u = {
            username: '',
            profile: {
              firstName: '',
              lastName: '',
              is_administrator: false,
            },
          };
        }

        const username: string = u.username || '';
        const firstName: string = u.profile.firstName || '';
        const lastName: string = u.profile.lastName || '';
        const isAdministrator: boolean = u.is_administrator || false;
        const roles: string[] = u.roles || [];
        const id: string = u.sub || '';
        const user: User = {
          _id: id,
          username: username,
          firstName: firstName,
          lastName: lastName,
          isAdministrator: isAdministrator,
          roles: roles,
        };
        await this.setUser(user);
      },
    },
  });
