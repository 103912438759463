import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import { store } from './store';
import baas from './plugins/baas';

import './registerServiceWorker';
import CustomIcon from '@/components/CustomIcon.vue';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

import { addIcons } from 'ionicons';
addIcons({
  wheatherFog: 'assets/icons/wi-fog.svg',
});

const app = createApp(App)
  .use(store)
  .use(IonicVue, {
    mode: 'ios',
  })
  .use(router);

app.component('CustomIcon', CustomIcon);

router.isReady().then(() => {
  app.mount('#app');
});
