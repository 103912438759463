<template>
  <ion-item :lines="lines" :detail="false">
    <ion-label position="stacked" :style="'width: 100%;'">
      <span
        style="font-weight: bold; color: var(--ion-color-primary-contrast)"
        >{{ displayTitle }}</span
      >
      <span style="color: var(--ion-color-danger)" v-if="required">*</span>
    </ion-label>
    <div
      :style="'padding-top: 5px; padding-bottom: 10px; height: auto; overflow: auto; width: 100%'"
    >
      <textarea
        class="custom-textarea"
        style="width: 100%; max-width: 100%"
        rows="6"
        cols="20"
        label=""
        @change="setValue"
        :value="value"
      >
      </textarea>
    </div>
  </ion-item>
</template>

<script>
  import { IonTextarea, IonItem, IonLabel } from '@ionic/vue';

  export default {
    components: {
      IonItem,
      IonLabel,
    },
    props: {
      name: {
        type: String,
        default: '',
      },
      modelValue: {
        type: String,
        default: '',
      },
      label: {
        type: Array,
        default: () => [],
      },
      required: {
        type: Boolean,
        default: false,
      },
      lines: {
        type: String,
        default: 'none',
      },
      rows: {
        type: Number,
        default: 0,
      },
      cols: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        value: '',
      };
    },
    computed: {
      isMobile: function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      displayTitle() {
        let title = this.name;

        if (this.label !== undefined) {
          if (
            this.label.findIndex((e) => e.language === this.language) !== -1
          ) {
            return this.label[
              this.label.findIndex((e) => e.language === this.language)
            ].text;
          }
        }

        return title;
      },
    },
    async mounted() {
      this.value = this.modelValue;
    },
    methods: {
      async setValue(event) {
        this.value = event.target.value;
        await this.$emit('update:modelValue', this.value);
      },
    },
    watch: {
      modelValue(n) {
        this.value = n;
      },
    },
  };
</script>

<style scoped>
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-toolbar {
    --background: var(--ion-color-light);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  .label-stacked.sc-ion-label-md-h {
    -webkit-transform: translateY(50%) scale(1);
    transform: translateY(50%) scale(1);
  }

  .item.sc-ion-label-md-h,
  .item .sc-ion-label-md-h {
    --color: #fff !important;
  }

  .custom-textarea {
    border: 0px solid black;
  }

  .custom-textarea:focus {
    border: 0px solid black;
    outline: none;
  }
</style>
