import { Auth } from '@/services/authService';
import { AuthService } from 'baas-ionic-appauth';
import axios, { ResponseType } from 'axios';

export class Function {
    private static dataService : FunctionService | undefined;

    private static buildDataInstance() {
        const configString = localStorage.getItem('config')
        const config:{ oidc:string, api:string } = configString === undefined ? { oidc: '', api:'' } : JSON.parse(configString || '{ "oidc":"", "api":"" }')
        const url = config.api
        const dataService = new FunctionService(url, Auth.Instance)
        return dataService
    }

     public static get Instance() : FunctionService {
        if (!this.dataService) {
             this.dataService = this.buildDataInstance();
        }
        return this.dataService
     }
}

export class FunctionService {
    private url:string
    private authService:AuthService

    constructor (url:string, authService:AuthService) {
        this.url = url
        this.authService = authService
    }

    public async execute (name:string, data:any) {
        const header = await this.getAuthHeader()
        const result = await axios.post(this.url + '/function/' + name, data, header)
        return result.data
    }

    private async getAuthHeader () {
        try {
            const token  = await this.authService.getValidToken()
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + token.accessToken,
                    'x-authorization-provider': this.authService.authConfig.client_id
                },
                responseType: "arraybuffer" as ResponseType
            }
            return config
        } catch (error) {
            return { headers: {} }
        }
    }
}