import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { Auth } from '@/services/authService';
import { filter, switchMap, take } from 'rxjs/operators';

import LandingPage from '../views/LandingPage.vue';
import EndSessionPage from '../views/oidc/EndSessionPage.vue';
import CallbackPage from '../views/oidc/CallbackPage.vue';
import ErrorPage from '../views/oidc/ErrorPage.vue';
import HomePage from '../views/HomePage.vue';
import AdminDashboardPage from '../views/AdminDashboardPage.vue';

import ListPage from '../views/ListPage.vue';
import DetailPage from '../views/DetailPage.vue';

import { configService } from '@/services/configService';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    name: 'Dashboard',
    meta: {
      noMenu: true,
    },
  },
  {
    path: '/landing',
    name: 'Landing',
    component: LandingPage,
    meta: {
      noMenu: true,
      noAuthOnly: true,
    },
  },
  {
    path: '/oidc/callback',
    name: 'AuthCallback',
    component: CallbackPage,
    meta: {
      noMenu: true,
    },
  },
  {
    path: '/oidc/endsession',
    name: 'AuthEndSession',
    component: EndSessionPage,
    meta: {
      noMenu: true,
    },
  },
  {
    path: '/oidc/error',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: {
      noMenu: true,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/adminDashboard',
    name: 'adminDashboard',
    component: AdminDashboardPage,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/models/:model/list',
    component: ListPage,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/models/:model/add',
    component: DetailPage,
    meta: {
      requireAuth: true,
      noButtonTabs: true,
    },
  },
  {
    path: '/models/:model/details/:id',
    component: DetailPage,
    meta: {
      requireAuth: true,
      noButtonTabs: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    await configService.loadConfig();
    if (to.meta.requireAuth === true) {
      Auth.Instance.initComplete$
        .pipe(
          filter((complete) => complete),
          switchMap(() => Auth.Instance.isAuthenticated$),
          take(1)
        )
        .subscribe((isAuthenticated) => {
          if (isAuthenticated === true) {
            Auth.Instance.loadUserInfo();
            next();
          } else {
            next({ path: '/landing' });
          }
        });
    } else {
      next();
    }
  } catch (error) {
    next();
  }

  if (process.env.NODE_ENV === 'development') {
    next();
  }
});

export default router;
