import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_nav = _resolveComponent("ion-nav")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": _ctx.isOpen,
    onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(false))),
    "swipe-to-close": true,
    "show-backdrop": true,
    "presenting-element": _ctx.presentingElement
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_nav, {
        root: _ctx.rootPage,
        id: "modal-nav"
      }, null, 8, ["root"])
    ]),
    _: 1
  }, 8, ["is-open", "presenting-element"]))
}