
import { Auth } from '@/services/authService'

import { IonPage, IonSpinner } from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Auth-EndRedirect',
    async created () {
      try {
        await Auth.Instance.endSessionCallback();
        await this.$router.push('/home')
      } catch (error) {
        this.$router.push('/home')
      } 
    },
    components: {
        IonPage,
        IonSpinner
    }
});
