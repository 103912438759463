import { Module } from 'vuex';
import { VuexPersistence } from 'vuex-persist';
import { State } from '..';
import { propertiesStorage } from '../helpers/propertiesStrorage';
import { Data } from '@/services/dataService';

export const permissionersistencePlugin = new VuexPersistence<State>({
  modules: ['ui'],
  key: 'ui',
  asyncStorage: true,
  storage: new propertiesStorage('ui'),
});

export type PermissionState = {
  employeePermission: EmployeePermission[];
  permissions: PermissionItem;
};

export type EmployeePermission = {
  id: string;
  name: string;
  description: string;
};

export type PermissionItem = {
  element: string;
  elementDescription: string;
  filter: string;
  permission: number;
};

export const modelModule: Module<any, any> = {
  namespaced: true,
  state: {
    employeePermission: [],
    permissions: [],
  },
  getters: {},
  actions: {
    async loadEmployeePermissions({ state, commit, rootState }, id) {
      const data = await Data.Instance.find(
        'employeePermission',
        { 'employeeId._id': { $oid: id } },
        {}
      );
      commit(
        'setEmployeePermissions',
        data.map((e: any) => {
          return {
            id: e.permissionId._id,
            name: e.permissionId.name,
            description: e.permissionId.description,
          };
        })
      );
      const perm = (
        await Data.Instance.find(
          'permissionItem',
          {
            'permissionId._id': {
              $in: state.employeePermission.map((e: any) => {
                return { $oid: e.id };
              }),
            },
          },
          {}
        )
      ).map((e: any) => {
        return {
          element: getModelName(rootState, e.element),
          elementDescription: getModelDescription(rootState, e.element),
          filter: e.filter,
          permission: e.permission,
        };
      });
      const perms: any = [];
      perm.forEach((p: any) => {
        const index = perms.findIndex((e: any) => e.element === p.element);
        if (index === -1) {
          perms.push(p);
        } else {
          const tmp = perms[index];
          if (p.permission > tmp.permission) {
            perms[index].permission = p.permission;
          }
          if (p.filter !== '') {
            if (perms[index].filter === '') {
              perms[index].filter = p.filter;
            } else {
              perms[index].filter =
                '{ $or: [ ' +
                perms[index].filter.trimStart('{ $or: [ ', '').trimEnd(' ]}') +
                ', ' +
                p.filter +
                ' ]}';
            }
          }
        }
      });
      commit('setPermissions', perm);
    },
  },
  mutations: {
    setEmployeePermissions(state, employeePermission: EmployeePermission[]) {
      state.employeePermission.splice(0, state.employeePermission.length);
      employeePermission.forEach((element) => {
        state.employeePermission.push(element);
      });
    },
    setPermissions(state, permissions: PermissionItem[]) {
      state.permissions.splice(0, state.permissions.length);
      permissions.forEach((element) => {
        state.permissions.push(element);
      });
    },
  },
};

const getModelName = (state: any, n: number) => {
  try {
    const permissionItemModel =
      state.models.models[
        state.models.models.findIndex((e: any) => e.name === 'permissionItem')
      ];
    const permisionItemPermissionEnum =
      permissionItemModel.fields[
        permissionItemModel.fields.findIndex((e: any) => e.name === 'element')
      ].enumeration.options;

    return permisionItemPermissionEnum[
      permisionItemPermissionEnum.findIndex((e: any) => e.value === n)
    ].name;
  } catch {
    return '';
  }
};

const getModelDescription = (state: any, n: number) => {
  try {
    const permissionItemModel =
      state.models.models[
        state.models.models.findIndex((e: any) => e.name === 'permissionItem')
      ];
    const permisionItemPermissionEnum =
      permissionItemModel.fields[
        permissionItemModel.fields.findIndex((e: any) => e.name === 'element')
      ].enumeration.options;

    return permisionItemPermissionEnum[
      permisionItemPermissionEnum.findIndex((e: any) => e.value === n)
    ].label[
      permisionItemPermissionEnum[
        permisionItemPermissionEnum.findIndex((e: any) => e.value === n)
      ].label.findIndex((e: any) => e.language === 'de')
    ].text;
  } catch {
    return '';
  }
};

export default modelModule;
