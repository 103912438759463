<template>
  <ion-item :lines="lines" :detail="false">
    <ion-label>
      <span style="font-weight: bold">{{ displayTitle }}</span>
      <span style="color: var(--ion-color-danger)" v-if="required">*</span>
    </ion-label>
    <ion-button style="font-size: 17px" @click="openModal" color="light">{{
      displayText
    }}</ion-button>
    <ion-modal
      :is-open="modalIsOpen"
      @ionModalDidDismiss="closeModal"
      :show-backdrop="true"
      :presenting-element="presentingElementValue"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              v-if="isMobile"
              @click="closeModal"
              fill="clear"
              color="primary"
              >Abbrechen</ion-button
            >
          </ion-buttons>
          <ion-title>{{ displayTitle }}</ion-title>
          <ion-buttons slot="end">
            <ion-button
              v-if="isMobile"
              @click="saveModal"
              fill="clear"
              color="primary"
              >Anwenden</ion-button
            >
            <ion-button v-if="!isMobile" fill="clear" @click="closeModal"
              ><custom-icon slot="icon-only" name="closeOutline"></custom-icon
            ></ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-datetime
          :value="value"
          presentation="date"
          size="cover"
          @ionChange="(ev) => (value = ev.detail.value)"
        >
        </ion-datetime>
      </ion-content>
      <ion-footer v-if="!isMobile">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button fill="clear" @click="closeModal">Abbrechen</ion-button>
            <ion-button color="primary" @click="saveModal" fill="clear"
              >Anwenden</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </ion-modal>
  </ion-item>
</template>

<script>
  import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonFooter,
    IonDatetime,
    IonTitle,
  } from '@ionic/vue';
  import moment from 'moment';

  export default {
    components: {
      IonModal,
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonContent,
      IonItem,
      IonLabel,
      IonFooter,
      IonDatetime,
      IonTitle,
    },
    props: {
      name: {
        type: String,
        default: '',
      },
      modelValue: {
        type: String,
        default: new Date(),
      },
      label: {
        type: Array,
        default: () => [],
      },
      format: {
        type: Date,
        default: 'DD.MM.YYYY',
      },
      presentingElement: {
        type: HTMLElement,
        default: undefined,
      },
      required: {
        type: Boolean,
        default: false,
      },
      lines: {
        type: String,
        default: 'none',
      },
    },
    data() {
      return {
        modalIsOpen: false,
        value: new Date(),
        presentingElementValue: undefined,
      };
    },
    computed: {
      isMobile: function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      displayTitle() {
        let title = this.name;

        if (this.label !== undefined) {
          if (
            this.label.findIndex((e) => e.language === this.language) !== -1
          ) {
            return this.label[
              this.label.findIndex((e) => e.language === this.language)
            ].text;
          }
        }

        return title;
      },
      displayText() {
        try {
          return moment(this.modelValue).format(this.format);
        } catch {
          return '--.--.----';
        }
      },
    },
    async mounted() {
      this.presentingElementValue = this.presentingElement;
      if (this.modelValue !== undefined) {
        this.value = new Date(this.modelValue).toISOString();
      } else {
        this.value = new Date().toISOString();
      }
    },
    methods: {
      async openModal() {
        if (this.modelValue !== undefined) {
          this.value = new Date(this.modelValue).toISOString();
        } else {
          this.value = new Date().toISOString();
        }
        this.modalIsOpen = true;
      },
      async closeModal() {
        this.modalIsOpen = false;
      },
      async saveModal() {
        await this.setValue();
        this.modalIsOpen = false;
      },
      async setValue() {
        await this.$emit('update:modelValue', this.value);
      },
    },
    watch: {
      modelValue(n) {
        if (
          JSON.parse(JSON.stringify(this.value)) !==
          JSON.parse(JSON.stringify(n))
        ) {
          try {
            if (n !== undefined) {
              this.value = moment(new Date(n)).toISOString();
            } else {
              this.value = moment(new Date()).toISOString();
            }
          } catch {
            this.value = moment(new Date()).toISOString();
          }
        }

        if (this.value === undefined) {
          this.value = moment(new Date()).toISOString();
        }
      },
      presentingElement(n) {
        this.presentingElementValue = n;
      },
    },
  };
</script>

<style scoped>
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-toolbar {
    --background: var(--ion-color-light);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-img > img {
    border-radius: 10px;
  }

  ion-datetime {
    --background: transparent;
  }
</style>
