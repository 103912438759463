import { Data } from '@/models/data';
import { Module } from 'vuex';
import { VuexPersistence } from 'vuex-persist';
import { State } from '..';
import { dataStorage } from '../helpers/dataStorage';
import { Data as DataService } from '@/services/dataService';

export const dataPersistencePlugin = new VuexPersistence<State>({
  modules: ['data'],
  key: 'data',
  asyncStorage: true,
  storage: new dataStorage('data'),
});

export type DataState = {
  [name: string]: any[];
};

export type FindOptions = {
  model: string;
  filter: any;
  options: any;
};

const dataModule: Module<any, any> = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async find({ state, commit }, options: FindOptions) {
      const data = await DataService.Instance.find(
        options.model,
        options.filter,
        options.options
      );
      if (
        JSON.stringify(options.filter) === '{}' &&
        JSON.stringify(options.options) === '{}'
      ) {
        commit('updateAll', { model: options.model, data: data });
      } else {
        commit('updateInList', { model: options.model, data: data });
      }
    },
  },
  mutations: {
    updateAll(state, options) {
      state[options.model] = options.data;
      console.log('Update All', state);
    },
  },
};

export default dataModule;
