<template>
  <ion-page ref="page">
    <ion-header :translucent="model !== 'projectTime'">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button
            fill="clear"
            v-if="isiPad && !showMenu"
            icon-only
            shape="round"
            @click="setShowMenu(!showMenu)"
          >
            <svg version="1.1" width="23.0273" height="17.998">
              <g>
                <rect height="17.998" opacity="0" width="23.0273" x="0" y="0" />
                <path
                  fill="var(--ion-color-primary)"
                  d="M3.06641 17.998L19.9609 17.998Q21.5039 17.998 22.2656 17.2412Q23.0273 16.4844 23.0273 14.9707L23.0273 3.04688Q23.0273 1.5332 22.2656 0.776367Q21.5039 0.0195312 19.9609 0.0195312L3.06641 0.0195312Q1.5332 0.0195312 0.766602 0.776367Q0 1.5332 0 3.04688L0 14.9707Q0 16.4844 0.766602 17.2412Q1.5332 17.998 3.06641 17.998ZM3.08594 16.4258Q2.35352 16.4258 1.96289 16.04Q1.57227 15.6543 1.57227 14.8926L1.57227 3.125Q1.57227 2.36328 1.96289 1.97754Q2.35352 1.5918 3.08594 1.5918L19.9414 1.5918Q20.6641 1.5918 21.0596 1.97754Q21.4551 2.36328 21.4551 3.125L21.4551 14.8926Q21.4551 15.6543 21.0596 16.04Q20.6641 16.4258 19.9414 16.4258ZM7.44141 16.7285L8.97461 16.7285L8.97461 1.29883L7.44141 1.29883ZM5.56641 5.21484Q5.78125 5.21484 5.95215 5.04395Q6.12305 4.87305 6.12305 4.66797Q6.12305 4.44336 5.95215 4.28223Q5.78125 4.12109 5.56641 4.12109L3.4668 4.12109Q3.25195 4.12109 3.08594 4.28223Q2.91992 4.44336 2.91992 4.66797Q2.91992 4.87305 3.08594 5.04395Q3.25195 5.21484 3.4668 5.21484ZM5.56641 7.74414Q5.78125 7.74414 5.95215 7.57812Q6.12305 7.41211 6.12305 7.1875Q6.12305 6.97266 5.95215 6.81152Q5.78125 6.65039 5.56641 6.65039L3.4668 6.65039Q3.25195 6.65039 3.08594 6.81152Q2.91992 6.97266 2.91992 7.1875Q2.91992 7.41211 3.08594 7.57812Q3.25195 7.74414 3.4668 7.74414ZM5.56641 10.2637Q5.78125 10.2637 5.95215 10.1025Q6.12305 9.94141 6.12305 9.72656Q6.12305 9.50195 5.95215 9.34082Q5.78125 9.17969 5.56641 9.17969L3.4668 9.17969Q3.25195 9.17969 3.08594 9.34082Q2.91992 9.50195 2.91992 9.72656Q2.91992 9.94141 3.08594 10.1025Q3.25195 10.2637 3.4668 10.2637Z"
                />
              </g>
            </svg>
          </ion-button>
          <account-button
            v-if="isMobile && !isiPad && !edit"
            @click="openAccountModal"
          />
          <ion-button
            @click="selectAllObjects(true)"
            v-if="edit && !selectAll"
            fill="clear"
            >Alle auswählen</ion-button
          >
          <ion-button
            @click="selectAllObjects(false)"
            v-if="edit && selectAll"
            fill="clear"
            >Keine</ion-button
          >
        </ion-buttons>
        <ion-title>{{ listTitle }}</ion-title>
        <ion-buttons slot="end">
          <ion-button
            @click="edit = !edit"
            v-if="false && !edit && isMobile"
            fill="clear"
            ><ion-icon
              slot="icon-only"
              :ios="editIcon"
              :md="editIcon"
            ></ion-icon
          ></ion-button>
          <ion-button @click="openFilter" v-if="!edit && isMobile" fill="clear"
            ><ion-icon
              slot="icon-only"
              :ios="filterIcon"
              :md="filterIcon"
            ></ion-icon
          ></ion-button>
          <ion-button @click="openAdd" v-if="!edit && isMobile" fill="clear"
            ><ion-icon slot="icon-only" :ios="addIcon" :md="addIcon"></ion-icon
          ></ion-button>
          <ion-button @click="edit = !edit" v-if="edit && isMobile" fill="clear"
            >Abbrechen</ion-button
          >
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar class="custom-top-toolbar" v-if="!isMobile">
        <ion-buttons slot="start">
          <ion-button
            @click="displayMode = 'grid'"
            :disabled="displayMode === 'grid'"
            v-if="!edit"
            fill="clear"
            ><ion-icon
              slot="icon-only"
              :ios="gridIcon"
              :md="gridIcon"
            ></ion-icon
          ></ion-button>
          <ion-button
            @click="displayMode = 'list'"
            :disabled="displayMode === 'list'"
            v-if="!edit"
            fill="clear"
            ><ion-icon
              slot="icon-only"
              :ios="listIcon"
              :md="listIcon"
            ></ion-icon
          ></ion-button>
          <ion-button @click="openAdd" v-if="!edit" fill="clear"
            ><ion-icon slot="icon-only" :ios="addIcon" :md="addIcon"></ion-icon
          ></ion-button>
          <ion-button @click="refresh" v-if="!edit" fill="clear"
            ><ion-icon
              slot="icon-only"
              :ios="refreshIcon"
              :md="refreshIcon"
            ></ion-icon
          ></ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button v-if="false" fill="clear"
            ><ion-icon
              slot="icon-only"
              :ios="searchIcon"
              :md="searchIcon"
            ></ion-icon
          ></ion-button>
          <ion-button @click="openFilter" v-if="!edit && !isMobile" fill="clear"
            ><ion-icon
              slot="icon-only"
              :ios="filterIcon"
              :md="filterIcon"
            ></ion-icon
          ></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" :class="{ 'desktop-grid': !isMobile }">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ listTitle }}</ion-title>
        </ion-toolbar>

        <ion-toolbar>
          <ion-searchbar
            :placeholder="listTitle + ' suchen'"
            @ionInput="search"
            :value="searchInList"
          ></ion-searchbar>
        </ion-toolbar>
        <ion-toolbar
          v-if="
            this.modelData === null || this.modelData === undefined
              ? false
              : this.modelData.archive === true
          "
        >
          <ion-segment
            @ionChange="segmentChanged($event)"
            :value="selectedSegment"
            style="width: calc(100% - 24px)"
          >
            <ion-segment-button value="all">
              <ion-label>Alle</ion-label>
            </ion-segment-button>
            <ion-segment-button value="current">
              <ion-label>Aktuell</ion-label>
            </ion-segment-button>
            <ion-segment-button value="archive">
              <ion-label>Archiviert</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
      </ion-header>
      <ion-refresher
        v-if="isMobile"
        slot="fixed"
        @ionRefresh="refresh($event)"
        ref="refresher"
      >
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list ref="list" v-if="isMobile">
        <template v-for="(object, index) in filteredObjects" :key="object._id">
          <div
            v-if="
              filterData.sortBy !== undefined &&
              object[filterData.sortBy.name] !== undefined &&
              object[filterData.sortBy.name] !== '' &&
              (index === 0 ||
                this.formatText(
                  this.modelData,
                  '{' + '{' + filterData.sortBy.name + '}' + '}',
                  object
                ) !==
                  this.formatText(
                    this.modelData,
                    '{' + '{' + filterData.sortBy.name + '}' + '}',
                    filteredObjects[index - 1]
                  ))
            "
            class="list-indexed"
            v-html="
              formatText(
                this.modelData,
                '{' + '{' + filterData.sortBy.name + '}' + '}',
                object
              )
            "
          ></div>
          <ion-item-sliding :disabled="edit">
            <ion-item @click="openDetails(object)" :detail="!edit">
              <ion-checkbox v-if="edit" color="primary"></ion-checkbox>
              <ion-note
                style="text-align: center"
                v-if="
                  this.modelData.fields.findIndex(
                    (e) => e.dataType === 'SequenceNumber'
                  ) !== -1
                "
                slot="start"
                color="danger"
                >NR<br />{{
                  object[
                    this.modelData.fields[
                      this.modelData.fields.findIndex(
                        (e) => e.dataType === 'SequenceNumber'
                      )
                    ].name
                  ]
                }}</ion-note
              >
              <ion-label>
                <h2>
                  <strong
                    :style="
                      object._archive === 1 && selectedSegment === 'all'
                        ? 'color: var(--ion-color-medium-shade);'
                        : ''
                    "
                    v-html="
                      formatText(
                        this.modelData,
                        this.modelData.listItemFormat !== undefined
                          ? this.modelData.listItemFormat.title
                          : '',
                        object
                      )
                    "
                  ></strong>
                </h2>
                <span
                  style="display: block"
                  :style="
                    object._archive === 1 && selectedSegment === 'all'
                      ? 'display: block; color: var(--ion-color-medium-shade);'
                      : 'display: block;'
                  "
                  v-html="
                    formatText(
                      this.modelData,
                      this.modelData.listItemFormat !== undefined
                        ? this.modelData.listItemFormat.description
                        : '',
                      object
                    )
                  "
                ></span>
              </ion-label>
            </ion-item>
            <ion-item-options side="end">
              <ion-item-option
                v-if="
                  object._archive !== 1 &&
                  (this.modelData === null || this.modelData === undefined
                    ? false
                    : this.modelData.archive === true)
                "
                color="warning"
                @click="archiveObject(object)"
              >
                <ion-icon slot="top" :icon="archiveIcon"></ion-icon>
                <span style="font-size: 10px">Archivieren</span>
              </ion-item-option>
              <ion-item-option
                v-if="
                  object._archive === 1 &&
                  (this.modelData === null || this.modelData === undefined
                    ? false
                    : this.modelData.archive === true)
                "
                color="warning"
                @click="restoreObject(object)"
              >
                <ion-icon slot="top" :icon="restoreIcon"></ion-icon>
                <span style="font-size: 10px">Wiederherstellen</span>
              </ion-item-option>
              <ion-item-option
                color="danger"
                v-if="
                  this.modelData === null || this.modelData === undefined
                    ? false
                    : this.isAdministrator
                    ? true
                    : this.modelData.allowDelete !== false
                "
                @click="deleteObject(object)"
              >
                <ion-icon slot="top" :icon="trashIcon"></ion-icon>
                <span style="font-size: 10px">Löschen</span>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </template>
      </ion-list>

      <div
        v-if="
          (filteredObjects === undefined || filteredObjects === null
            ? true
            : filteredObjects.length === 0) && loading === false
        "
        style="
          width: 100%;
          height: 30%;
          top: 30%;
          position: relative;
          text-align: center;
          color: var(--ion-color-medium);
        "
      >
        <custom-icon
          style="
            padding: 16px;
            border-radius: 8px;
            font-size: 120px !important;
            color: var(--ion-color-medium);
          "
          name="listOutline"
        ></custom-icon
        ><br />
        {{
          modelData.archive === true
            ? selectedSegment === 'archive'
              ? 'Keine Daten archiviert'
              : selectedSegment === 'current'
              ? 'Keine aktuellen Daten'
              : 'Keine Daten'
            : 'Keine Daten'
        }}
      </div>

      <!-- GRID -->
      <ion-grid
        class="custom-grid"
        v-if="!isMobile && displayMode === 'grid'"
        style="
          position: relative;
          background-color: var(--ion-background-color);
          height: 100%;
          overflow: auto;
        "
      >
        <ion-row>
          <ion-col
            v-for="object in filteredObjects"
            :key="object._id"
            sizeLg="4"
            sizeMd="6"
            sizeXs="12"
          >
            <ion-card
              style="
                margin-bottom: 8px;
                margin-top: 8px;
                background-color: var(--ion-background-color);
                border: 1px solid var(--ion-color-light);
              "
              class="card"
            >
              <ion-card-content
                @click="openDetails(object)"
                class="card-content"
              >
                <ion-grid
                  v-if="
                    this.modelData.fields.findIndex(
                      (e) => e.dataType === 'SequenceNumber'
                    ) !== -1
                  "
                >
                  <ion-row>
                    <ion-col size="2" style="margin: auto; text-align: center">
                      <ion-label>
                        <ion-note
                          style="text-align: center"
                          v-if="
                            this.modelData.fields.findIndex(
                              (e) => e.dataType === 'SequenceNumber'
                            ) !== -1
                          "
                          slot="start"
                          color="danger"
                          >NR<br />{{
                            object[
                              this.modelData.fields[
                                this.modelData.fields.findIndex(
                                  (e) => e.dataType === 'SequenceNumber'
                                )
                              ].name
                            ]
                          }}</ion-note
                        >
                      </ion-label>
                    </ion-col>
                    <ion-col>
                      <h2>
                        <strong
                          v-html="
                            formatText(
                              this.modelData,
                              this.modelData.listItemFormat !== undefined
                                ? this.modelData.listItemFormat.title
                                : '',
                              object
                            )
                          "
                        ></strong>
                      </h2>
                      <span
                        v-html="
                          formatText(
                            this.modelData,
                            this.modelData.listItemFormat !== undefined
                              ? this.modelData.listItemFormat.description
                              : '',
                            object
                          )
                        "
                      ></span>
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <ion-label
                  v-if="
                    this.modelData.fields.findIndex(
                      (e) => e.dataType === 'SequenceNumber'
                    ) === -1
                  "
                >
                  <h2>
                    <strong
                      v-html="
                        formatText(
                          this.modelData,
                          this.modelData.listItemFormat !== undefined
                            ? this.modelData.listItemFormat.title
                            : '',
                          object
                        )
                      "
                    ></strong>
                  </h2>
                  <span
                    v-html="
                      formatText(
                        this.modelData,
                        this.modelData.listItemFormat !== undefined
                          ? this.modelData.listItemFormat.description
                          : '',
                        object
                      )
                    "
                  ></span>
                </ion-label>
              </ion-card-content>
              <ion-toolbar class="card-footer">
                <ion-buttons slot="start">
                  <ion-button
                    size="small"
                    @click="deleteObject(object)"
                    fill="clear"
                    ><ion-icon
                      slot="icon-only"
                      :ios="trashIcon"
                      :md="trashIcon"
                    ></ion-icon
                  ></ion-button>
                  <p
                    class="created_changed"
                    style="
                      font-size: 12px;
                      color: var(
                        --ion-card-color,
                        var(
                          --ion-item-color,
                          var(--ion-color-step-550, #737373)
                        )
                      );
                      margin-left: 16px;
                    "
                  >
                    {{
                      object._changedAt !== null
                        ? 'Zuletzt aktualisiert am ' + object._changedAt
                        : 'Erstellt am ' + object._createdAt
                    }}
                  </p>
                </ion-buttons>
              </ion-toolbar>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- LIST -->
      <div
        v-if="!isMobile && displayMode === 'list'"
        class="custom-grid"
        style="
          position: relative;
          background-color: var(--ion-background-color);
          height: 100%;
          width: 100%;
          padding: 20px;
          overflow: hidden;
        "
      >
        <table class="ion-table">
          <thead>
            <tr>
              <th style="width: 50px"></th>
              <th v-for="field in modelData.fields" :key="field.name">
                {{ formatLabel(field.label, field.name) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="object in filteredObjects" :key="object._id">
              <td style="width: 50px">
                <ion-button
                  size="small"
                  @click="openDetails(object)"
                  fill="clear"
                  ><ion-icon
                    slot="icon-only"
                    :ios="detailIcon"
                    :md="detailIcon"
                  ></ion-icon
                ></ion-button>
              </td>
              <td
                v-for="field in modelData.fields"
                :key="field.name"
                v-html="formatListColumn(field.name, object)"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ion-content>

    <account-modal
      ref="accountModal"
      :presentingElement="$parent.$refs.ionRouterOutlet"
    />
    <ion-modal
      :swipe-to-close="false"
      :is-open="addModalIsOpen"
      @didDismiss="addModalIsOpen = false"
      :presentingElement="$parent.$refs.ionRouterOutlet"
      ref="addModal"
    >
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button v-if="isMobile" fill="clear" @click="cancleCreateObject"
              >Abbrechen</ion-button
            >
          </ion-buttons>
          <ion-title v-if="!isMobile">{{
            formatLabel(
              modelData.addLabel,
              formatLabel(modelData.detailLabel, modelData.name)
            )
          }}</ion-title>
          <ion-buttons slot="end">
            <ion-button v-if="isMobile" @click="createObject" fill="clear"
              >Erstellen</ion-button
            >
            <ion-button
              v-if="!isMobile"
              fill="clear"
              @click="cancleCreateObject"
              ><ion-icon
                slot="icon-only"
                :ios="closeIcon"
                :md="closeIcon"
              ></ion-icon
            ></ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar v-if="isMobile">
          <h2 style="font-weight: bolder; text-align: center">
            {{
              formatLabel(
                modelData.addLabel,
                formatLabel(modelData.detailLabel, modelData.name)
              )
            }}
          </h2>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-loading
          :is-open="loading"
          cssClass="my-custom-class"
          message="Bitte warten..."
        >
        </ion-loading>
        <FormView
          :style="isMobile ? '' : 'margin-top: 16px'"
          :add="true"
          :id="undefined"
          v-model="tmpAddObject"
          :presentingElement="$refs.addModal.$el"
          :model="$route.params.model"
        />
      </ion-content>
      <ion-footer v-if="!isMobile">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button fill="clear" @click="cancleCreateObject"
              >Abbrechen</ion-button
            >
            <ion-button color="primary" @click="createObject" fill="clear"
              >Erstellen</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </ion-modal>
    <filter-modal
      ref="filterModal"
      :presentingElement="$parent.$refs.ionRouterOutlet"
    />
    <ion-modal
      :swipe-to-close="false"
      :is-open="filterModalIsOpen"
      @didDismiss="filterModalIsOpen = false"
      :initial-breakpoint="0.75"
      :breakpoints="[0, 0.5, 0.75]"
    >
      <ion-header>
        <ion-toolbar>
          <ion-title>Filtern &#38; Sortieren </ion-title>
          <ion-buttons slot="end">
            <ion-button
              icon-only
              shape="round"
              @click="filterModalIsOpen = false"
              ><ion-icon
                slot="icon-only"
                :ios="closeIcon"
                :md="closeIcon"
              ></ion-icon
            ></ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-loading
          :is-open="loading"
          cssClass="my-custom-class"
          message="Bitte warten..."
        >
        </ion-loading>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
  import {
    IonPage,
    IonHeader,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonList,
    IonIcon,
    IonItem,
    IonRefresher,
    IonRefresherContent,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonCheckbox,
    IonSearchbar,
    alertController,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonLabel,
    IonLoading,
    IonModal,
    modalController,
    IonNote,
    isPlatform,
    IonSegment,
    IonSegmentButton,
  } from '@ionic/vue';
  import {
    addOutline,
    createOutline,
    checkmarkOutline,
    trashOutline,
    refreshOutline,
    calendarOutline,
    closeOutline,
    filterOutline,
    searchOutline,
    gridOutline,
    listOutline,
    arrowForward,
    menuSharp,
    archiveOutline,
    arrowUndoOutline,
  } from 'ionicons/icons';
  import AccountButton from '@/components/AccountButton.vue';
  import AccountModal from '@/components/AccountModal.vue';
  import { Data } from '@/services/dataService';
  import { mapState, mapActions, mapMutations } from 'vuex';
  import FormView from '@/components/FormView.vue';
  import moment from 'moment';
  import allIcons from './../composables/icons';
  import FilterModal from '@/components/FilterModal.vue';
  import { Auth } from '@/services/authService';
  import { syncProjectAccountingTime } from '@/helpers/projectAccounting.js';
  import { Function } from '@/services/functionService';

  export default {
    components: {
      IonPage,
      IonHeader,
      IonToolbar,
      IonTitle,
      IonContent,
      IonButtons,
      IonButton,
      IonIcon,
      IonList,
      IonItem,
      IonCheckbox,
      IonRefresher,
      IonRefresherContent,
      IonItemSliding,
      IonItemOptions,
      IonItemOption,
      IonSearchbar,
      IonGrid,
      IonRow,
      IonCol,
      IonCard,
      IonCardContent,
      IonLabel,
      IonLoading,
      IonModal,
      IonFooter,
      IonNote,
      AccountButton,
      AccountModal,
      FormView,
      FilterModal,
      IonSegment,
      IonSegmentButton,
    },
    props: {
      addTitle: {
        type: String,
        default: '',
      },
      detailsTitle: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        model: '',
        addIcon: addOutline,
        closeIcon: closeOutline,
        trashIcon: trashOutline,
        archiveIcon: archiveOutline,
        restoreIcon: arrowUndoOutline,
        editIcon: createOutline,
        refreshIcon: refreshOutline,
        calendarIcon: calendarOutline,
        filterIcon: filterOutline,
        searchIcon: searchOutline,
        gridIcon: gridOutline,
        listIcon: listOutline,
        menuIcon: menuSharp,
        detailIcon: arrowForward,
        checkmarkIcon: checkmarkOutline,
        edit: false,
        selectAll: false,
        showList: false,
        objects: [],
        addModalIsOpen: false,
        filterModalIsOpen: false,
        tmpAddObject: {},
        detailModalIsOpen: false,
        tmpDetailObject: {},
        searchInList: '',
        loading: true,
        displayMode: 'grid',
        sortBy: 'name',
        filter: 'all',
        isFilterOpen: false,
        filterEvent: undefined,
        employee: {},
        HPMOffice: {},
        selectedSegment: 'current',
      };
    },
    computed: {
      ...mapState('models', ['models']),
      ...mapState('ui', ['showMenu']),
      ...mapState('ui', ['filters']),
      ...mapState('user', ['user']),
      filterData() {
        if (
          this.filters.findIndex((e) => e.name === this.modelData.name) === -1
        ) {
          return {};
        } else {
          return this.filters[
            this.filters.findIndex((e) => e.name === this.modelData.name)
          ];
        }
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      modelData: function () {
        const defaults = {};

        if (this.models === undefined || this.models === null) {
          return defaults;
        }

        let index = this.models.findIndex((e) => e.name === this.model);

        if (index === -1) {
          return defaults;
        }

        return this.models[index];
      },
      detailTitle() {
        if (
          this.modelData.detailLabel === undefined ||
          this.modelData.detailLabel === null
        ) {
          return this.modelData.name;
        }

        if (this.modelData.detailLabel.length === 0) {
          return this.modelData.name;
        }

        if (this.modelData.detailLabel.length === 1) {
          return this.modelData.detailLabel[0].text;
        }

        if (
          this.modelData.detailLabel.findIndex(
            (e) => e.language === this.language
          ) !== -1
        ) {
          return this.modelData.detailLabel[
            this.modelData.detailLabel.findIndex(
              (e) => e.language === this.language
            )
          ].text;
        } else {
          if (
            this.modelData.detailLabel.findIndex((e) => e.language === 'en') !==
            -1
          ) {
            return this.modelData.detailLabel[
              this.modelData.detailLabel.findIndex((e) => e.language === 'en')
            ].text;
          } else {
            return this.modelData.name;
          }
        }
      },
      listTitle() {
        if (
          this.modelData.listLabel === undefined ||
          this.modelData.listLabel === null
        ) {
          return this.modelData.name;
        }

        if (this.modelData.listLabel.length === 0) {
          return this.modelData.name;
        }

        if (this.modelData.listLabel.length === 1) {
          return this.modelData.listLabel[0].text;
        }

        if (
          this.modelData.listLabel.findIndex(
            (e) => e.language === this.language
          ) !== -1
        ) {
          return this.modelData.listLabel[
            this.modelData.listLabel.findIndex(
              (e) => e.language === this.language
            )
          ].text;
        } else {
          if (
            this.modelData.listLabel.findIndex((e) => e.language === 'en') !==
            -1
          ) {
            return this.modelData.listLabel[
              this.modelData.listLabel.findIndex((e) => e.language === 'en')
            ].text;
          } else {
            return this.modelData.name;
          }
        }
      },
      prefersDark: function () {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      },
      isMobile: function () {
        return true;
      },
      isiPad: function () {
        return isPlatform('ipad') || isPlatform('desktop');
      },
      filteredObjects: function () {
        var result = [];
        this.objects.forEach((object) => {
          var isValid = false;
          if (
            this.searchInList !== '' &&
            this.searchInList !== undefined &&
            this.searchInList !== null
          ) {
            this.modelData.fields.forEach((field) => {
              if (!isValid) {
                switch (field.dataType) {
                  case 'ObjectReference':
                  case 'FileReference':
                    var model =
                      this.models[
                        this.models.findIndex(
                          (e) => e.name === field.referencedModel
                        )
                      ];

                    if (model !== undefined) {
                      if (model.fields !== undefined) {
                        model.fields.forEach((childField) => {
                          if (childField !== undefined) {
                            var childObject = object[field.name];
                            if (!isValid && childObject !== undefined) {
                              isValid = (
                                childObject[childField.name] === undefined
                                  ? ''
                                  : childObject[childField.name]
                              )
                                .toString()
                                .includes(this.searchInList);
                            }
                          }
                        });
                      }
                    }
                    break;
                  default:
                    if (!isValid) {
                      isValid = (
                        object[field.name] === undefined
                          ? ''
                          : object[field.name]
                      )
                        .toString()
                        .includes(this.searchInList);
                    }
                    break;
                }
              }
            });
          } else {
            isValid = true;
          }

          // Filter By Field
          if (isValid) {
            if (this.filterData.filterField !== undefined) {
              this.filterData.filterField.forEach((filterField) => {
                var includes = false;
                if (
                  filterField.value === undefined ||
                  filterField.value.length === 0
                ) {
                  includes = true;
                } else {
                  if (object[filterField.name] !== undefined) {
                    filterField.value.forEach((v) => {
                      if (object[filterField.name]._id !== undefined) {
                        if (object[filterField.name]._id === v) {
                          includes = true;
                        }
                      } else {
                        if (object[filterField.name] === v) {
                          includes = true;
                        }
                      }
                    });
                  }
                }
                if (!includes) {
                  isValid = false;
                }
              });
            }
          }

          if (isValid) {
            result.push(object);
          }
        });

        // Sort By
        if (this.filterData.sortBy !== undefined) {
          var reference = false;
          var field =
            this.modelData.fields[
              this.modelData.fields.findIndex(
                (e) => e.name === this.filterData.sortBy.name
              )
            ];
          if (field !== undefined) {
            if (field.dataType === 'ObjectReference') {
              reference = true;
            }
          }

          if (reference) {
            result = result.sort((a, b) => {
              if (
                a[this.filterData.sortBy.name].name >
                b[this.filterData.sortBy.name].name
              ) {
                if (this.filterData.sortBy.direction === 'asc') {
                  return 1;
                } else {
                  return -1;
                }
              } else {
                if (this.filterData.sortBy.direction === 'asc') {
                  return -1;
                } else {
                  return 1;
                }
              }
            });
          } else {
            result = result.sort((a, b) => {
              if (
                a[this.filterData.sortBy.name] > b[this.filterData.sortBy.name]
              ) {
                if (this.filterData.sortBy.direction === 'asc') {
                  return 1;
                } else {
                  return -1;
                }
              } else {
                if (this.filterData.sortBy.direction === 'asc') {
                  return -1;
                } else {
                  return 1;
                }
              }
            });
          }
        }

        // All / Current / Archive
        if (this.modelData.archive === true) {
          switch (this.selectedSegment) {
            case 'all':
              return result;
            case 'current':
              return result.filter((e) => e._archive !== 1);
            case 'archive':
              return result.filter((e) => e._archive === 1);
            default:
              return result;
          }
        } else {
          return result;
        }
      },
    },
    async mounted() {
      try {
        this.model = this.$route.params.model;
        await this.loadModels();
        await this.loadData();
        //await this.find({ model: this.model, filter: {}, options: {}})
        //console.log('Current Objects', this.currentObjects)
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async updated() {
      var updates = JSON.parse(localStorage.getItem('updates'));
      if (updates === undefined || updates === null) {
        updates = [];
      }
      var updatesAfter = [];
      updates.forEach((u) => {
        if (this.model === u.model) {
          if (this.objects !== undefined) {
            var index = this.objects.findIndex((e) => e._id === u.data._id);
            if (index === -1) {
              this.objects.push(u.data);
            } else {
              this.objects[index] = u.data;
            }
          }
        } else {
          updatesAfter.push(u);
        }
      });
      localStorage.setItem('updates', JSON.stringify(updatesAfter));
    },
    methods: {
      ...mapMutations('ui', ['setShowMenu']),
      ...mapActions('models', ['loadModels']),
      ...mapActions('data', ['find']),
      ...mapActions('permission', ['loadEmployeePermissions']),
      ...mapState('user', ['user']),
      async modalTop() {
        return await modalController.getTop();
      },
      async refresh(event) {
        await this.loadData();
        if (event.detail.complete !== undefined) {
          event.detail.complete();
        }
      },
      async loadData() {
        this.loading = true;
        try {
          await this.getCurrentEmployee();
          await this.loadEmployeePermissions(this.employee._id);
          if (!this.isAdministrator) {
            var filter = {};

            switch (this.model) {
              case 'projectTime':
                filter = {
                  'employee._id': { $eq: { $oid: this.employee._id } },
                };
                break;
              case 'employee':
                filter = {
                  _id: { $eq: { $oid: this.employee._id } },
                };
                break;
              case 'leaveRequest':
                filter = {
                  'employee._id': { $eq: { $oid: this.employee._id } },
                };
                break;
              case 'receipts':
                filter = {
                  'employee._id': { $eq: { $oid: this.employee._id } },
                };
                break;

              default:
                break;
            }

            this.objects = await Data.Instance.find(this.model, filter, {});
          } else {
            this.objects = await Data.Instance.find(this.model, {}, {});
          }
        } catch {
          this.loading = false;
        }
      },
      async getCurrentEmployee() {
        if (this.user.firstName === undefined) {
          await Auth.Instance.loadUserInfo();
        }

        var e = await Data.Instance.find(
          'employee',
          { name: { $eq: this.user.firstName + ' ' + this.user.lastName } },
          {}
        );

        if (e !== undefined) {
          if (e.length !== 0) {
            this.employee = e[0];
          }
        }
      },
      async search(event) {
        this.searchInList = event.target.value;
      },
      async segmentChanged(e) {
        this.selectedSegment = e.detail.value;
      },
      openAccountModal() {
        this.$refs.accountModal.setOpen(true);
      },
      openAdd() {
        this.tmpAddObject = this.setDefaults();
        this.addModalIsOpen = true;
        this.loading = false;
        //this.$router.push({ path: '/models/' + this.model + '/add/' })
      },
      openDetails(object) {
        if (this.edit) {
          return;
        }
        //const nav = document.querySelector("#list-nav")
        //this.tmpDetailObject = JSON.parse(JSON.stringify(object))
        //nav.push(ListViewDetails)
        //this.detailModalIsOpen = true
        this.$router.push({
          path: '/models/' + this.model + '/details/' + object._id,
        });
      },
      openFilter() {
        this.$refs.filterModal.setOpen(true);
      },
      selectAllObjects(value) {
        this.selectAll = value;
      },
      setDefaults() {
        var tmpObject = {};
        this.modelData.fields.forEach((field) => {
          switch (field.dataType) {
            case 'Date':
            case 'Time':
            case 'DateTime':
              tmpObject[field.name] = new Date().toUTCString();
              break;
            case 'FileReference':
            case 'ObjectReference':
              if (field.referenceType === 'Single') {
                tmpObject[field.name] = {};
                tmpObject[field.name]._id = '';
              } else {
                tmpObject[field.name] = [];
              }
              break;
            case 'Integer':
            case 'Enumeration':
              tmpObject[field.name] = 0;
              break;
            default:
              tmpObject[field.name] = '';
              break;
          }

          if (this.model === 'projectTime' && field.name === 'place') {
            tmpObject[field.name] = this.HPMOffice;
          }

          if (this.model === 'projectTime' && field.name === 'employee') {
            tmpObject[field.name] = this.employee;
          }

          if (this.model === 'leaveRequest' && field.name === 'employee') {
            tmpObject[field.name] = this.employee;
          }

          if (this.model === 'receipts' && field.name === 'employee') {
            tmpObject[field.name] = this.employee;
          }

          if (
            this.model === 'projectTime' &&
            field.name === 'drivingDistance'
          ) {
            tmpObject[field.name] = 0;
          }

          if (this.model === 'buildingDiary' && field.name === 'creator') {
            tmpObject[field.name] = this.employee;
          }

          if (this.model === 'projectAccounting' && field.name === 'creator') {
            tmpObject[field.name] = this.employee;
          }
        });
        return tmpObject;
      },
      async deleteObject(object) {
        const alert = await alertController.create({
          header: 'Löschen',
          message: this.detailTitle + ' wirklich löschen?',
          buttons: [
            {
              text: 'Abbrechen',
              handler: this.cancleSlideItem,
            },
            {
              text: 'Löschen',
              handler: async () => {
                try {
                  const result = await Data.Instance.delete(
                    this.model,
                    { _id: { $eq: { $oid: object._id } } },
                    {}
                  );
                  if (this.model === 'leaveRequest') {
                    const result2 = await Data.Instance.find(
                      'workingTimeCalendar',
                      {
                        $and: [
                          {
                            date: {
                              $lte: {
                                $date: moment(object.date).endOf('day'),
                              },
                              $gte: {
                                $date: moment(object.date).startOf('day'),
                              },
                            },
                          },
                          {
                            dateTo: {
                              $lte: {
                                $date: moment(object.dateTo).endOf('day'),
                              },
                              $gte: {
                                $date: moment(object.dateTo).startOf('day'),
                              },
                            },
                          },
                          {
                            'employee._id': {
                              $eq: { $oid: object.employee._id },
                            },
                          },
                          {
                            type: {
                              $eq: object.type,
                            },
                          },
                        ],
                      },
                      {}
                    );

                    for (let index = 0; index < result2.length; index++) {
                      const id = result2[index]._id;
                      const result3 = await Data.Instance.delete(
                        'workingTimeCalendar',
                        { _id: { $eq: { $oid: id } } },
                        {}
                      );
                    }

                    console.log('Delete from Working Time', result2);
                  }
                  if (result.deletedCount === 1) {
                    this.objects.splice(
                      this.objects.findIndex((e) => e._id === object._id),
                      1
                    );
                  }
                } catch (e) {
                  console.log(e);
                }
              },
            },
          ],
        });
        await alert.present();
      },
      async archiveObject(object) {
        const alert = await alertController.create({
          header: 'Archivieren',
          message: this.detailTitle + ' wirklich archivieren?',
          buttons: [
            {
              text: 'Abbrechen',
              handler: this.cancleSlideItem,
            },
            {
              text: 'Archivieren',
              handler: async () => {
                this.execArchiveObject(object);
              },
            },
          ],
        });
        await alert.present();
      },
      async execArchiveObject(object) {
        try {
          const result = await Data.Instance.archive(this.model, object._id);
          this.objects[
            this.objects.findIndex((e) => e._id === object._id)
          ]._archive = 1;
          this.$refs.list.$el.closeSlidingItems();
        } catch (error) {
          console.log('Error', error);
        }
      },
      async cancleSlideItem() {
        try {
          console.log('Cancle');
          this.$refs.list.$el.closeSlidingItems();
        } catch (error) {
          console.log('Error', error);
        }
      },
      async restoreObject(object) {
        const alert = await alertController.create({
          header: 'Wiederherstellen',
          message: this.detailTitle + ' wirklich wiederherstellen?',
          buttons: [
            {
              text: 'Abbrechen',
              handler: this.cancleSlideItem,
            },
            {
              text: 'Wiederherstellen',
              handler: async () => {
                this.execRestoreObject(object);
              },
            },
          ],
        });
        await alert.present();
      },
      async execRestoreObject(object) {
        try {
          const result = await Data.Instance.restore(this.model, object._id);
          this.objects[
            this.objects.findIndex((e) => e._id === object._id)
          ]._archive = 0;
          this.$refs.list.$el.closeSlidingItems();
        } catch (error) {
          console.log('Error', error);
        }
      },
      async createObject() {
        try {
          this.loading = true;
          let object = await this.formatObject(this.tmpAddObject);
          const result = await Data.Instance.insert(this.model, [object], {});
          let o = {};
          result.insertedObjects.forEach((object) => {
            o = object;
            this.objects.push(object);
          });

          if (this.model === 'projectAccounting') {
            await syncProjectAccountingTime(o);
          }

          if (this.model === 'leaveRequest') {
            await this.sendLeaveRequest(o);
          }

          this.addModalIsOpen = false;
          this.tmpAddObject = {};
          this.loading = false;
          if (this.modelData.childModels !== undefined) {
            if (this.modelData.childModels.length > 0) {
              this.openDetails(result.insertedObjects[0]);
            }
          }
        } catch (error) {
          this.loading = false;
          const alert = await alertController.create({
            cssClass: 'my-custom-class',
            header: this.detailTitle + ' nicht gespeichert',
            message:
              this.detailTitle +
              ' konnte nicht gespeichert werden. ' +
              error.message,
            buttons: ['OK'],
          });
          await alert.present();
        } finally {
          this.loading = false;
        }
      },
      async cancleCreateObject() {
        this.loading = true;
        this.addModalIsOpen = false;
        this.tmpAddObject = {};
        this.loading = false;
      },
      async sendLeaveRequest(o) {
        const result = await Function.Instance.execute('sendLeaveRequest', {
          _id: o._id,
          model: 'leaveRequest',
        });
      },
      async updateObject() {
        this.loading = true;
        let object = await this.formatObject(this.tmpDetailObject);
        const result = await Data.Instance.update(
          this.model,
          { _id: { $eq: { $oid: this.tmpDetailObject._id } } },
          object
        );
        result.updatedObjects.forEach((object) => {
          if (this.objects.findIndex((e) => e._id === object._id) !== -1) {
            this.objects[this.objects.findIndex((e) => e._id === object._id)] =
              object;
          } else {
            this.objects.push(object);
          }
        });
        this.detailModalIsOpen = false;
        this.tmpDetailObject = {};
        this.loading = false;
      },
      async cancleUpdateObject() {
        this.loading = true;
        this.detailModalIsOpen = false;
        this.tmpDetailObject = {};
        this.loading = false;
      },
      async formatObject(o) {
        let object = {};
        for (let prop in o) {
          if (this.modelData.fields.findIndex((e) => e.name === prop) !== -1) {
            const field =
              this.modelData.fields[
                this.modelData.fields.findIndex((e) => e.name === prop)
              ];

            switch (field.dataType) {
              case 'ObjectReference':
              case 'FileReference':
                if (field.referenceType === 'Single') {
                  if (o[prop] !== undefined) {
                    if (o[prop]._id !== undefined && o[prop]._id !== '') {
                      object[prop] = { $oid: o[prop]._id };
                    }
                  }
                } else {
                  if (o[prop] === undefined || o[prop] === null) {
                    o[prop] = [];
                  }
                  var files = o[prop].filter((e) => e._id === null);
                  var filesDelete = o[prop].filter((e) => e.deleted === true);
                  object[prop] = o[prop]
                    .filter((e) => e._id !== null && e.deleted !== true)
                    .map((e) => {
                      return { $oid: e._id };
                    });
                  for (let file in files) {
                    console.log(
                      'Upload File',
                      field.referencedModel,
                      files[file]
                    );
                    var fileResult = await Data.Instance.insert(
                      field.referencedModel,
                      [
                        {
                          filename: files[file].filename,
                          data: files[file].data,
                        },
                      ],
                      {}
                    );
                    fileResult.insertedIds.forEach((id) => {
                      object[prop].push({ $oid: id });
                    });
                  }

                  for (let file in filesDelete) {
                    console.log(
                      'Delete File',
                      field.referencedModel,
                      filesDelete[file]
                    );
                    await Data.Instance.delete(
                      field.referencedModel,
                      { _id: { $eq: { $oid: filesDelete[file]._id } } },
                      {}
                    );
                  }
                }
                break;

              case 'Date':
              case 'DateTime':
              case 'Time':
                object[prop] = { $date: o[prop] };
                break;
              case 'Enumeration':
                object[prop] = parseInt(o[prop].toString());
                break;
              case 'Integer':
                object[prop] = parseInt(o[prop]);
                break;
              case 'Double':
                object[prop] = parseFloat(parseFloat(o[prop]).toFixed(2));
                break;
              case 'SequenceNumber':
                object[prop] = o[prop] !== undefined ? parseInt(o[prop]) : 0;
                break;
              default:
                object[prop] = o[prop];
                break;
            }
          }
        }

        return object;
      },
      formatListColumn(field, object) {
        return this.formatText(this.modelData, '{{' + field + '}}', object);
      },
      formatText(model, format, object) {
        if (format === undefined || object === undefined) {
          return '';
        }
        var text = format.toString();
        this.models[
          this.models.findIndex((e) => e.name === model.name)
        ].fields.forEach((field) => {
          var formattedValue = object[field.name];
          switch (field.dataType) {
            case 'ObjectReference':
              if (object[field.name] !== undefined) {
                formattedValue =
                  object[field.name].name === undefined
                    ? ''
                    : object[field.name].name;
              }
              break;
            case 'FileReference':
              formattedValue = '<div style="width: 100%; height: 40px">';
              if (field.referenceType === 'Single') {
                formattedValue += `<img style="width: 40px; height: 40px; margin-right: 5px" src="${
                  object[field.name].data
                }" />`;
              } else {
                object[field.name].forEach((file) => {
                  formattedValue += `<img style="width: 40px; height: 40px; margin-right: 5px" src="${file.data}" />`;
                });
              }
              formattedValue += '</div>';
              break;
            case 'Date':
              formattedValue = this.formatDate(field, object[field.name]);
              break;
            case 'Time':
              formattedValue = this.formatTime(field, object[field.name]);
              break;
            case 'Enumeration':
              var icon = this.formatEnumerationIcon(field, object[field.name]);
              if (icon !== '') {
                icon = (allIcons[icon] || '')
                  .replaceAll('data:image/svg+xml;utf8,', '')
                  .replaceAll(
                    '<svg ',
                    '<svg style="width: 28px; height: 28px; stroke-width: var(--ionicon-stroke-width, 32px); margin-right: 5px"'
                  )
                  .replaceAll(
                    "class='ionicon",
                    'style="fill: none; stroke-width: var(--ionicon-stroke-width, 32px); stroke: var(--ion-color-primary, #3880ff)" class=\'ionicon'
                  )
                  .replaceAll(
                    'class="customIcon',
                    'style="fill: var(--ion-color-primary, #3880ff)" class="customIcon'
                  );
                formattedValue =
                  '<span style="line-height: 28px; display:inline-flex;">' +
                  icon +
                  ' ' +
                  this.formatEnumeration(
                    object[field.name],
                    field.enumeration.options,
                    field.enumeration.showAsStatus
                  ) +
                  '</span>';
              } else {
                formattedValue = this.formatEnumeration(
                  object[field.name],
                  field.enumeration.options,
                  field.enumeration.showAsStatus
                );
              }

              break;
            default:
              formattedValue =
                '<span style="line-height: 28px;">' +
                (formattedValue === undefined ? '-' : formattedValue) +
                '</span>';
              break;
          }

          if (formattedValue === undefined) {
            formattedValue = '';
          }

          text = text.replaceAll('{{' + field.name + '}}', formattedValue);
        });

        return text;
      },
      formatLabel(labels, defaultValue) {
        if (labels === undefined || labels === null) {
          return defaultValue;
        }

        if (labels.length === 0) {
          return defaultValue;
        }

        if (labels.length === 1) {
          return labels[0].text;
        }

        if (labels.findIndex((e) => e.language === this.language) !== -1) {
          return labels[labels.findIndex((e) => e.language === this.language)]
            .text;
        } else {
          if (labels.findIndex((e) => e.language === 'en') !== -1) {
            return labels[labels.findIndex((e) => e.language === 'en')].text;
          } else {
            return defaultValue;
          }
        }
      },
      formatEnumeration(value, options, showAsStaus) {
        var result = '';
        var color = '';
        if (options.findIndex((e) => e.value === value) !== -1) {
          var option = options[options.findIndex((e) => e.value === value)];
          color =
            option.color === 'var(--ion-color-light)' ||
            option.color === undefined
              ? ''
              : option.color;
          if (option.label !== undefined) {
            if (
              option.label.findIndex((e) => e.language === this.language) !== -1
            ) {
              result =
                option.label[
                  option.label.findIndex((e) => e.language === this.language)
                ].text;
            } else {
              result = option.name;
            }
          } else {
            result = option.name;
          }
        }

        if (showAsStaus) {
          return (
            '<ion-badge style="line-height: 22px; display: inline; background-color: ' +
            color +
            '">' +
            result +
            '</ion-badege>'
          );
        }

        return result;
      },
      formatEnumerationIcon(field, value) {
        var options = field.enumeration.options;
        if (options === null || options === undefined) {
          return '';
        }

        if (
          options.length === 0 ||
          options.length === undefined ||
          options.length === null
        ) {
          return '';
        }

        if (options.findIndex((e) => e.value === value) !== -1) {
          return options[options.findIndex((e) => e.value === value)].icon !==
            undefined &&
            options[options.findIndex((e) => e.value === value)].icon !== null
            ? options[options.findIndex((e) => e.value === value)].icon
            : '';
        }

        return '';
      },
      formatDate(field, date) {
        moment.locale('de');
        return moment(date).format(
          field.format === undefined ? 'DD.MM.YYYY' : field.format
        );
      },
      formatTime(field, date) {
        moment.locale('de');
        return moment(date).format(
          field.format === undefined ? 'HH:mm' : field.format
        );
      },
    },
  };
</script>

<style scoped>
  #container {
    position: absolute;
    left: 0;
    right: 0;
  }

  #container strong {
    font-size: 20px;
    line-height: 26px;
  }

  #container p {
    font-size: 16px;
    line-height: 22px;

    margin: 0;
  }

  #container a {
    text-decoration: none;
  }

  .card-footer:hover {
    --background: var(--ion-color-step-150);
  }

  .card-footer ion-button {
    visibility: hidden;
    width: 0px !important;
  }

  .card-footer:hover ion-button {
    visibility: visible;
    --background-hover: var(--ion-color-step-150);
    width: inherit !important;
  }

  .card-footer:hover .created_changed {
    visibility: hidden;
  }

  .card-footer:hover ion-button:hover {
    --color: black;
  }

  .card:hover {
    cursor: pointer;
  }

  .card:hover ion-button {
    visibility: visible;
    width: inherit !important;
  }

  .card:hover .created_changed {
    visibility: hidden;
  }

  .card:hover .card-footer {
    --background: var(--ion-background-color);
  }

  ion-toolbar {
    --background: var(--ion-background-color, #fff);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-modal > ion-content {
    --background: var(--ion-color-light);
  }

  ion-modal > ion-header > ion-toolbar {
    --background: var(--ion-color-light);
  }

  .header-md::after {
    background: none;
  }

  .desktop-grid .custom-top-toolbar {
    --background: var(--ion-color-light) !important;
  }

  .custom-grid {
    background-color: var(--ion-color-light) !important;
  }

  .ion-table {
    border: 1px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
      );
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }

  .ion-table > tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 100%;
    background-color: var(--ion-background-color, #fff);
  }

  .ion-table > tbody > tr {
    display: block;
    line-height: 1.6em;
    width: 100%;
    border-bottom: 1px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
      );
  }

  .ion-table > tbody > tr > td,
  .ion-table > thead > tr > th {
    padding: 4px;
    width: 200px;
  }

  .ion-table > thead > tr {
    display: block;
    text-align: left;
    line-height: 1.6em;
    background-color: #1e1e1e;
    color: #fff;
    border-bottom: 1px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
      );
  }
  .custom-top-toolbar {
    --background: var(--ion-color-light) !important;
  }

  .list-indexed {
    position: sticky;
    z-index: 100;
    top: -3px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bolder;
    background-color: #e0dee4;
  }

  @media (prefers-color-scheme: dark) {
    .list-indexed {
      background-color: #39383e;
    }
  }
</style>
