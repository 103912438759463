
  import { IonPage, IonButton, IonIcon } from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { warningOutline } from 'ionicons/icons';

  export default defineComponent({
    name: 'Auth-EndRedirect',
    components: {
      IonPage,
      IonButton,
      IonIcon,
    },
    computed: {
      message(): string {
        return (this.$route.query.message as string) || 'Unbekannter Fehler';
      },
      title(): string {
        return (this.$route.query.title as string) || 'Fehler';
      },
    },
    data() {
      return {
        icon: warningOutline,
      };
    },
  });
