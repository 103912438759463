<template>
  <div
    style="
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      height: auto;
      overflow: auto;
    "
  >
    <div @click="addFile" v-if="allowEdit" class="ion-file-add">
      <custom-icon
        style="
          width: calc(100% - 50px);
          height: calc(100% - 50px);
          padding: 25px;
        "
        color="primary"
        size="large"
        name="addOutline"
      ></custom-icon>
    </div>
    <div
      @click="showDetails(index)"
      class="ion-file"
      v-for="(file, index) in currentFiles"
      :key="file._id"
    >
      <custom-icon
        v-if="!imagesOnly"
        style="
          width: calc(100% - 50px);
          height: calc(100% - 50px);
          margin-top: 10px;
        "
        color="medium"
        size="large"
        name="documentOutline"
      ></custom-icon>
      <p
        v-if="!imagesOnly"
        style="font-size: 10px; color: var(--ion-color-medium)"
      >
        {{ file.filename }}
      </p>
      <ion-thumbnail v-if="imagesOnly">
        <img
          :src="
            file.data === undefined
              ? 'http://localhost:8081/file/buildingDiaryPositionImages/' +
                file._id
              : file.data
          "
        />
        <custom-icon
          style="
            width: calc(100% - 50px);
            height: calc(100% - 50px);
            margin-top: 25px;
          "
          v-if="file.data === undefined || file.data === null"
          color="medium"
          size="large"
          name="imageOutline"
        ></custom-icon>
      </ion-thumbnail>
    </div>
    <ion-modal
      :swipe-to-close="true"
      :show-backdrop="true"
      :is-open="showImageDetails"
      @didDismiss="showImageDetails = false"
      :presentingElement="presentingElement"
    >
      <ion-header class="actionsHeader" :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button fill="clear" @click="showImageDetails = false"
              ><custom-icon size="large" name="closeOutline"></custom-icon
            ></ion-button>
          </ion-buttons>
          <ion-title v-html="modalLabel"></ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen scroll-y="false">
        <swiper
          @slideChange="onSlideChange"
          :navigation="!isMobile"
          :modules="modules"
          :centeredSlides="true"
          :initialSlide="currentSlide"
          :thumbs="{ swiper: thumbsSwiper }"
          ref="slides"
          style="height: calc(100% - 162px)"
          :style="
            isiPad || !isMobile
              ? 'height: calc(100% - 212px);'
              : 'height: calc(100% - 162px);'
          "
        >
          <swiper-slide v-for="file in currentFiles" :key="file._id">
            <table v-if="file.data === undefined || file.data === null">
              <tr>
                <td>
                  <custom-icon
                    v-if="file.data === undefined || file.data === null"
                    color="medium"
                    size="large"
                    name="imageOutline"
                  ></custom-icon>
                </td>
              </tr>
              <tr>
                <td>
                  <p
                    v-if="file.data === undefined || file.data === null"
                    style="color: white"
                  >
                    Bild konnte nicht geladen werden
                  </p>
                </td>
              </tr>
            </table>
            <img
              style="
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                object-fit: scale-down;
              "
              :src="
                file.data === undefined
                  ? 'http://localhost:8081/file/buildingDiaryPositionImages/' +
                    file._id
                  : file.data
              "
            />
          </swiper-slide>
        </swiper>
      </ion-content>
      <ion-footer class="actionsFooter" :translucent="true">
        <ion-toolbar style="padding-left: 0px; padding-right: 0px">
          <swiper
            :modules="modulesThumbs"
            @swiper="setThumbsSwiper"
            watch-slides-progress
            :slidesPerView="isMobile ? 8 : 16"
            :freeMode="true"
            :style="isiPad || !isMobile ? 'height: 100px' : 'height: 50px'"
          >
            <swiper-slide v-for="file in currentFiles" :key="file._id">
              <img
                :src="
                  file.data === undefined
                    ? 'http://localhost:8081/file/buildingDiaryPositionImages/' +
                      file._id
                    : file.data
                "
              />
            </swiper-slide>
          </swiper>
        </ion-toolbar>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button fill="clear" @click="deleteFile"
              ><custom-icon size="large" name="trashOutline"></custom-icon
            ></ion-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-button
              fill="clear"
              v-if="false"
              @click="showImageDetails = false"
              ><custom-icon size="large" name="shareOutline"></custom-icon
            ></ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </ion-modal>
  </div>
</template>

<script>
  import {
    isPlatform,
    IonThumbnail,
    IonModal,
    IonContent,
    alertController,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonHeader,
    IonButtons,
    IonButton,
  } from '@ionic/vue';
  import usePhotoGallery from '@/composables/usePhotoGallery';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Navigation, Thumbs, FreeMode } from 'swiper';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import '@ionic/vue/css/ionic-swiper.css';

  export default {
    components: {
      IonThumbnail,
      IonModal,
      IonContent,
      Swiper,
      SwiperSlide,
      IonFooter,
      IonTitle,
      IonToolbar,
      IonHeader,
      IonButton,
      IonButtons,
    },
    props: {
      allowEdit: {
        type: Boolean,
        default: true,
      },
      files: {
        type: Array,
        default: () => [],
      },
      imagesOnly: {
        type: Boolean,
        default: false,
      },
      presentingElement: {
        type: Object,
        default: undefined,
      },
      modalLabel: {
        type: String,
        default: 'Bilder',
      },
    },
    data() {
      return {
        showImageDetails: false,
        currentSlide: 0,
        thumbsSwiper: null,
        modules: [Navigation, Thumbs],
        modulesThumbs: [Thumbs, FreeMode],
      };
    },
    computed: {
      currentFiles() {
        if (
          this.files === null ||
          this.files === undefined ||
          this.files === '' ||
          !Array.isArray(this.files)
        ) {
          return [];
        }
        return this.files.filter((e) => e.deleted !== true);
      },
      isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );
      },
      isiPad: function () {
        return isPlatform('ipad');
      },
    },
    methods: {
      async addFile() {
        await this.takePhoto();
      },
      async deleteFile() {
        this.$emit('deleteFile', this.currentFiles[this.currentSlide]);
      },
      async showDetails(index) {
        try {
          this.thumbsSwiper = null;
          this.showImageDetails = true;
          this.currentSlide = parseInt(index);
        } catch (error) {
          console.log('Error show Details', error);
        }
      },
      async takePhoto() {
        try {
          var photo = await usePhotoGallery();
          var base64 = await this.readAsBase64(photo);
          this.$emit('addFile', 'IMG' + new Date().getTime() + '.png', base64);
        } catch (error) {
          console.log(error);
        }
      },
      setThumbsSwiper(swiper) {
        this.thumbsSwiper = swiper;
      },
      async onSlideChange(e) {
        this.currentSlide = e.activeIndex;
      },
      async readAsBase64(photo) {
        const response = await fetch(photo.webPath);
        const blob = await response.blob();

        return await this.convertBlobToBase64(blob);
      },
      async convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onerror = reject;
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });
      },
    },
  };
</script>

<style scoped>
  .ion-file-add {
    border: 1px dashed var(--ion-color-medium);
    margin: 3px;
    border-radius: 10px;
    height: 80px;
    width: 80px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  .ion-file {
    border: 1px solid var(--ion-color-medium);
    border-radius: 10px;
    margin: 3px;
    height: 80px;
    width: 80px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  ion-thumbnail {
    --border-radius: 10px;
    --size: 80px;
  }

  ion-slides {
    height: 100%;
  }

  .swiper-slide-thumb-active {
    margin-right: 10px;
    margin-left: 10px;
  }

  .actionsFooter {
    border-top: 1px solid var(--ion-color-light-shade);
  }

  .actionsHeader {
    border-bottom: 1px solid var(--ion-color-light-shade);
  }
</style>
