import moment from 'moment';
import { Data } from './../services/dataService';

const getCalendar = async (employeeId, start, end) => {
  let c = await Data.Instance.find(
    'workingTimeCalendar',
    {
      $and: [
        {
          $or: [
            {
              'employee._id': {
                $eq: { $oid: employeeId },
              },
            },
            {
              type: { $eq: 0 },
            },
          ],
        },
        {
          $or: [
            {
              date: { $lte: { $date: end }, $gte: { $date: start } },
            },
            {
              dateTo: { $lte: { $date: end }, $gte: { $date: start } },
            },
          ],
        },
      ],
    },
    {}
  );

  // Remove all Days out of range
  c.filter((e) => moment(e.dateTo).isAfter(end)).forEach((cd) => {
    cd.dateTo = end
      .clone()
      .startOf('day')
      .format('yyyy-MM-DD[T]HH:mm:ss.000[Z]');
  });

  c.filter((e) => moment(e.date).isBefore(start)).forEach((cd) => {
    cd.date = start
      .clone()
      .startOf('day')
      .format('yyyy-MM-DD[T]HH:mm:ss.000[Z]');
  });

  // Split into Single Dates
  let c2 = [];
  c.forEach((wd) => {
    var diff = moment(wd.dateTo).diff(moment(wd.date), 'days');
    if (diff > 0) {
      for (
        var d = moment(wd.date).clone();
        d.isSameOrBefore(moment(wd.dateTo));
        d.add(1, 'days')
      ) {
        var l = JSON.parse(JSON.stringify(wd));
        l.date = d
          .clone()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format('yyyy-MM-DD[T]HH:mm:ss.000[Z]');
        delete l.dateTo;
        c2.push(l);
      }
    } else {
      var k = JSON.parse(JSON.stringify(wd));
      k.date = moment(k.date)
        .clone()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('yyyy-MM-DD[T]HH:mm:ss.000[Z]');
      delete k.dateTo;

      c2.push(k);
    }
  });

  let calendar = [];
  // Add public holidays
  c2.filter((e) => e.type === 0).forEach((c) => {
    calendar.push(c);
  });
  // Add holidays & flex time
  c2.filter((e) => e.type !== 0).forEach((c) => {
    if (
      calendar.findIndex((e) => e.date === c.date && e.type === 0) === -1 &&
      moment(c.date).day() !== 6 &&
      moment(c.date).day() !== 0
    ) {
      calendar.push(c);
    }
  });
  return calendar;
};

const getCalendarAll = async (start, end) => {
  let c = await Data.Instance.find(
    'workingTimeCalendar',
    {
      $or: [
        {
          date: { $lte: { $date: end }, $gte: { $date: start } },
        },
        {
          dateTo: { $lte: { $date: end }, $gte: { $date: start } },
        },
      ],
    },
    {}
  );

  // Remove all Days out of range
  c.filter((e) => moment(e.dateTo).isAfter(end)).forEach((cd) => {
    cd.dateTo = end
      .clone()
      .startOf('day')
      .format('yyyy-MM-DD[T]HH:mm:ss.000[Z]');
  });

  c.filter((e) => moment(e.date).isBefore(start)).forEach((cd) => {
    cd.date = start
      .clone()
      .startOf('day')
      .format('yyyy-MM-DD[T]HH:mm:ss.000[Z]');
  });

  // Split into Single Dates
  let c2 = [];
  c.forEach((wd) => {
    var diff = moment(wd.dateTo).diff(moment(wd.date), 'days');
    if (diff > 0) {
      for (
        var d = moment(wd.date).clone();
        d.isSameOrBefore(moment(wd.dateTo));
        d.add(1, 'days')
      ) {
        var l = JSON.parse(JSON.stringify(wd));
        l.date = d
          .clone()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format('yyyy-MM-DD[T]HH:mm:ss.000[Z]');
        delete l.dateTo;
        c2.push(l);
      }
    } else {
      var k = JSON.parse(JSON.stringify(wd));
      k.date = moment(k.date)
        .clone()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('yyyy-MM-DD[T]HH:mm:ss.000[Z]');
      delete k.dateTo;

      c2.push(k);
    }
  });

  let calendar = [];
  // Add public holidays
  c2.filter((e) => e.type === 0).forEach((c) => {
    calendar.push(c);
  });
  // Add holidays & flex time
  c2.filter((e) => e.type !== 0).forEach((c) => {
    if (
      calendar.findIndex(
        (e) =>
          e.date === c.date &&
          e.type === 0 &&
          (e.employee === undefined || e.employee._id === c.employee._id)
      ) === -1 &&
      moment(c.date).day() !== 6 &&
      moment(c.date).day() !== 0
    ) {
      calendar.push(c);
    }
  });

  return calendar;
};

const getCalendarCurrentYear = async (employeeId) => {
  var currentDate = moment().lang('de');
  var start = currentDate.clone().startOf('year').startOf('day');
  var end = currentDate.clone().endOf('year').endOf('day');
  return await getCalendar(employeeId, start, end);
};

const getCalendarCurrentYearAll = async () => {
  var currentDate = moment().lang('de');
  var start = currentDate.clone().startOf('year').startOf('day');
  var end = currentDate.clone().endOf('year').endOf('day');
  return await getCalendarAll(start, end);
};

export {
  getCalendar,
  getCalendarAll,
  getCalendarCurrentYear,
  getCalendarCurrentYearAll,
};
