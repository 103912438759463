<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar :color="!isMobile ? 'primary' : ''">
        <ion-buttons slot="start">
          <ion-menu-button
            v-if="isiPad"
            @click="setShowMenu(!showMenu)"
          ></ion-menu-button>
          <ion-button
            fill="clear"
            v-if="!isMobile && !isiPad"
            icon-only
            shape="round"
            @click="setShowMenu(!showMenu)"
            ><ion-icon
              slot="icon-only"
              :ios="menuIcon"
              :md="menuIcon"
            ></ion-icon
          ></ion-button>
          <ion-button v-if="isMobile" fill="clear" @click="back"
            ><ion-icon slot="start" :ios="backIcon" :md="backIcon"></ion-icon
          ></ion-button>
        </ion-buttons>
        <ion-title v-if="!this.$route.path.toString().includes('add')">{{
          pageTitle +
          (object === undefined || object === null
            ? ''
            : object._archive === 1
            ? ' (Archiviert)'
            : '')
        }}</ion-title>
        <ion-buttons slot="end">
          <template v-if="isiPad">
            <ion-button
              v-for="action in modelData.actions === undefined
                ? []
                : modelData.actions"
              :key="action.name"
              @click="executeFunction(action.name)"
              ><custom-icon
                v-if="
                  action.icon !== '' &&
                  action.icon !== undefined &&
                  action.icon !== null
                "
                :name="action.icon"
              ></custom-icon>
              {{ formatLabel(action.label, action.name) }}</ion-button
            >
          </template>
          <ion-button
            v-if="
              (!this.$route.path.toString().includes('add') &&
                this.modelData === null) ||
              this.modelData === undefined
                ? false
                : this.modelData.allowUpdate !== false
            "
            :disabled="
              object === undefined || object === null
                ? false
                : object._archive === 1
            "
            fill="clear"
            color="primary"
            @click="save"
            >sichern</ion-button
          >
          <ion-button
            v-if="this.$route.path.toString().includes('add')"
            fill="clear"
            color="primary"
            @click="save"
            >erstellen</ion-button
          >
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar class="custom-top-toolbar" v-if="!isMobile">
        <ion-buttons slot="start">
          <ion-button fill="clear" @click="back"
            ><ion-icon slot="start" :ios="backIcon" :md="backIcon"></ion-icon
          ></ion-button>
          <ion-button
            v-if="!this.$route.path.toString().includes('add')"
            fill="clear"
            @click="save"
            ><ion-icon slot="start" :ios="saveIcon" :md="saveIcon"></ion-icon
          ></ion-button>
          <ion-button
            v-if="this.$route.path.toString().includes('add')"
            fill="clear"
            @click="save"
            ><ion-icon slot="start" :ios="saveIcon" :md="saveIcon"></ion-icon
          ></ion-button>
          <ion-button
            @click="executeFunction(modelData.actions[0].name)"
            v-if="
              (modelData.name === 'buildingDiary' ||
                modelData.name === 'projectAccounting') &&
              !isMobile
            "
            ><ion-icon
              slot="start"
              :ios="exportIcon"
              :md="exportIcon"
            ></ion-icon
          ></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-popover trigger="moreButton" :dismiss-on-select="true">
        <ion-content>
          <ion-list>
            <ion-item>
              <custom-icon slot="start" name="shareOutline"></custom-icon>
              <ion-label>Export</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-popover>

      <ion-grid
        v-if="
          childModels.length > 0 ||
          model === 'projectAccounting' ||
          model === 'employee'
        "
      >
        <ion-row style="margin-inline-end: 16px; margin-inline-start: 16px">
          <ion-col size="12" style="vertical-align: bottom">
            <h3
              v-if="!this.$route.path.toString().includes('add')"
              style="font-weight: bolder"
            >
              Allgemein
            </h3>
            <h2
              v-if="this.$route.path.toString().includes('add')"
              style="font-weight: bolder; text-align: center"
            >
              {{
                formatLabel(
                  modelData.addLabel,
                  formatLabel(modelData.detailLabel, modelData.name)
                )
              }}
            </h2>
          </ion-col>
        </ion-row>
      </ion-grid>

      <FormView
        :style="childModels.length > 0 ? '' : 'margin-top: 16px'"
        :id="$route.params.id"
        :add="this.$route.path.toString().includes('add')"
        v-model="object"
        :model="$route.params.model"
        :parentField="$route.query.parentField"
        :presentingElement="$parent.$refs.ionRouterOutlet"
      />
      <div v-if="model === 'projectAccounting'">
        <ion-grid>
          <ion-row style="margin-inline-end: 16px; margin-inline-start: 16px">
            <ion-col size="10" style="vertical-align: bottom">
              <h3 style="font-weight: bolder">Zusammenfassung</h3>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-list
          v-if="model === 'projectAccounting'"
          :inset="true"
          style="margin-top: 0px"
        >
          <ion-item-group>
            <ion-item lines="inset">
              <ion-grid>
                <template
                  v-for="(p, index) in projectAccountingSumByEmployee"
                  :key="p.employee._id"
                >
                  <ion-row>
                    <ion-col size="12" style="vertical-align: bottom">
                      <p style="margin: 0px">
                        {{ p.employee.name }}
                      </p>
                    </ion-col>
                    <ion-col size="6" style="vertical-align: bottom">
                      <p style="margin: 0px; color: var(--ion-color-medium)">
                        &#10132; Zeit
                      </p></ion-col
                    >
                    <ion-col
                      size="6"
                      style="vertical-align: bottom; text-align: right"
                    >
                      <p style="margin: 0px">
                        <span
                          style="
                            color: var(--ion-color-danger);
                            font-size: 12px;
                          "
                          v-if="calcProjectAccoutingDiff(p) !== 0"
                          >{{
                            (calcProjectAccoutingDiff(p) > 0 ? '+' : '') +
                            calcProjectAccoutingDiff(p)
                          }}
                          {{ showProjectAccountingInTS ? ' TS' : ' h' }}</span
                        >
                        {{ p.duration }}
                        {{ showProjectAccountingInTS ? ' TS' : ' h' }}
                      </p>
                    </ion-col>
                    <ion-col
                      size="6"
                      style="vertical-align: bottom"
                      v-if="showProjectAccountingDrivingDistance"
                    >
                      <p style="margin: 0px; color: var(--ion-color-medium)">
                        &#10132; Fahrtwege
                      </p></ion-col
                    >
                    <ion-col
                      size="6"
                      style="vertical-align: bottom; text-align: right"
                      v-if="showProjectAccountingDrivingDistance"
                    >
                      <p style="margin: 0px">{{ p.drivingDistance }} km</p>
                    </ion-col>
                    <ion-col
                      v-if="projectAccountingSumByEmployee.length - 1 !== index"
                      size="12"
                      style="padding-top: 0px"
                    >
                      <hr style="background-color: var(--ion-color-medium)" />
                    </ion-col>
                  </ion-row>
                </template>
                <ion-row
                  style="margin-inline-end: 0px; margin-inline-start: 0px"
                >
                  <ion-col size="12" style="padding-top: 0px">
                    <hr style="background-color: var(--ion-color-primary)" />
                  </ion-col>
                  <ion-col size="6" style="vertical-align: bottom">
                    <p style="font-weight: bolder; margin: 0px">Summe Zeit</p>
                  </ion-col>
                  <ion-col
                    size="6"
                    style="vertical-align: bottom; text-align: right"
                  >
                    <p style="font-weight: bolder; margin: 0px">
                      <span
                        style="color: var(--ion-color-danger); font-size: 12px"
                        v-if="
                          projectAccountingSum - projectAccountingSumBooked !==
                          0
                        "
                        >{{
                          (projectAccountingSum - projectAccountingSumBooked > 0
                            ? '+'
                            : '') +
                          projectAccountingSum -
                          projectAccountingSumBooked
                        }}
                        {{ showProjectAccountingInTS ? ' TS' : ' h' }}</span
                      >
                      {{ projectAccountingSum }}
                      {{ showProjectAccountingInTS ? ' TS' : ' h' }}
                    </p>
                  </ion-col>
                  <ion-col
                    size="8"
                    style="vertical-align: bottom"
                    v-if="showProjectAccountingDrivingDistance"
                  >
                    <p style="font-weight: bolder; margin: 0px">
                      Summe Fahrtwege
                    </p>
                  </ion-col>
                  <ion-col
                    size="4"
                    style="vertical-align: bottom; text-align: right"
                    v-if="showProjectAccountingDrivingDistance"
                  >
                    <p style="font-weight: bolder; margin: 0px">
                      {{ projectAccountingDrivingDistanceSum }} km
                    </p>
                  </ion-col>
                  <ion-col size="12" style="padding-top: 0px">
                    <hr style="background-color: var(--ion-color-primary)" />
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-item-group>
        </ion-list>
      </div>

      <div v-for="(childModel, index) in childModels" :key="index">
        <ion-grid>
          <ion-row style="margin-inline-end: 16px; margin-inline-start: 16px">
            <ion-col size="10" style="vertical-align: bottom">
              <h3 style="font-weight: bolder">
                {{ formatLabel(childModel.listLabel) }}
              </h3>
            </ion-col>
            <ion-col
              size="2"
              style="text-align: right; display: flex; justify-content: end"
              class="ion-align-self-end"
            >
              <ion-button
                style="padding: 0px; --padding-start: 0px; --padding-end: 0px"
                @click="syncProjectAccounting()"
                v-if="childModel.name === 'projectAccountingTime'"
                fill="clear"
                shape="round"
                class="button-with-no-padding"
                ><ion-icon
                  slot="icon-only"
                  :ios="syncIcon"
                  :md="syncIcon"
                ></ion-icon
              ></ion-button>
              <ion-button
                style="padding: 0px; --padding-start: 0px; --padding-end: 0px"
                @click="openAdd(childModel.name)"
                fill="clear"
                shape="round"
                ><ion-icon
                  slot="icon-only"
                  :ios="addIcon"
                  :md="addIcon"
                ></ion-icon
              ></ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-list
          v-if="!childModelContainsRecords(childModel)"
          :inset="true"
          style="margin-top: 0px"
        >
          <ion-item-group>
            <ion-item lines="none">
              <ion-text style="text-align: center; width: 100%"
                >- Keine Daten -</ion-text
              >
            </ion-item>
          </ion-item-group>
        </ion-list>

        <ion-list
          v-if="childModelContainsRecords(childModel)"
          :inset="true"
          style="margin-top: 0px"
        >
          <ion-item-group>
            <ion-item-sliding
              v-for="(object, i) in childObjectsData(childModel)"
              :key="object._id"
            >
              <ion-item
                @click="openDetails(childModel, object)"
                :detail="true"
                :lines="
                  i === childObjectsDataLength(childModel) - 1
                    ? 'none'
                    : 'inset'
                "
              >
                <ion-note
                  style="
                    color: var(--ion-color-primary);
                    width: 60px;
                    text-align: center;
                  "
                  slot="start"
                  v-if="childModel.name === 'projectAccountingTime'"
                  ><strong
                    >{{
                      showProjectAccountingInTS
                        ? roundByQuarter(object.hours / 8) + ' TS'
                        : object.hours + ' h '
                    }}<br />
                    <span
                      style="font-weight: 300"
                      v-if="showProjectAccountingInTS"
                      >{{ '(' + object.hours + ' h)' }}</span
                    ><br v-if="showProjectAccountingInTS" />
                    {{
                      showProjectAccountingDrivingDistance
                        ? object.drivingDistance + ' KM'
                        : ''
                    }}
                  </strong></ion-note
                >
                <ion-label>
                  <h2>
                    <strong
                      v-html="
                        formatText(
                          childModel,
                          childModelData(childModel).listItemFormat !==
                            undefined
                            ? childModelData(childModel).listItemFormat.title
                            : '',
                          object
                        )
                      "
                    ></strong>
                  </h2>
                  <span
                    style="display: grid"
                    v-html="
                      formatText(
                        childModel,
                        childModelData(childModel).listItemFormat !== undefined
                          ? childModelData(childModel).listItemFormat
                              .description
                          : '',
                        object
                      )
                    "
                  ></span>
                </ion-label>
              </ion-item>
              <ion-item-options side="end">
                <ion-item-option
                  color="danger"
                  @click="deleteObject(childModel, object)"
                >
                  <custom-icon slot="top" name="trashOutline"></custom-icon>
                  <span style="font-size: 10px">Löschen</span>
                </ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
          </ion-item-group>
        </ion-list>
      </div>

      <div v-if="model === 'employee'">
        <ion-grid>
          <ion-row style="margin-inline-end: 16px; margin-inline-start: 16px">
            <ion-col size="10" style="vertical-align: bottom">
              <h3 style="font-weight: bolder">Zusammenfassung (Jahr)</h3>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-list
          v-if="model === 'employee'"
          :inset="true"
          style="margin-top: 0px"
        >
          <ion-item-group>
            <ion-item lines="inset">
              <ion-grid>
                <ion-row
                  style="margin-inline-end: 0px; margin-inline-start: 0px"
                >
                  <ion-col size="6" style="vertical-align: bottom">
                    <p style="margin: 0px">Urlaub</p>
                  </ion-col>
                  <ion-col
                    size="6"
                    style="vertical-align: bottom; text-align: right"
                  >
                    <p style="margin: 0px">{{ employeeHolidays }} T</p>
                  </ion-col>
                  <ion-col size="6" style="vertical-align: bottom">
                    <p style="margin: 0px">Gleitzeit</p>
                  </ion-col>
                  <ion-col
                    size="6"
                    style="vertical-align: bottom; text-align: right"
                  >
                    <p style="margin: 0px">{{ employeeFlexDays }} T</p>
                  </ion-col>
                  <ion-col size="12" style="padding-top: 0px">
                    <hr style="background-color: var(--ion-color-primary)" />
                  </ion-col>
                  <ion-col size="6" style="vertical-align: bottom">
                    <p style="font-weight: bolder; margin: 0px">Summe</p>
                  </ion-col>
                  <ion-col
                    size="6"
                    style="vertical-align: bottom; text-align: right"
                  >
                    <p style="font-weight: bolder; margin: 0px">
                      {{ employeeHolidays + employeeFlexDays }} T
                    </p>
                  </ion-col>
                  <ion-col size="12" style="padding-top: 0px">
                    <hr style="background-color: var(--ion-color-primary)" />
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-item-group>
        </ion-list>
        <ion-grid v-if="model === 'employee'">
          <ion-row style="margin-inline-end: 16px; margin-inline-start: 16px">
            <ion-col size="10" style="vertical-align: bottom">
              <h3 style="font-weight: bolder">Urlaub & Gleitzeit (Jahr)</h3>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-list
          v-if="model === 'employee'"
          :inset="true"
          style="margin-top: 0px"
        >
          <ion-item-group>
            <ion-item
              lines="inset"
              v-for="p in calcWorkingTimeCalendar"
              :key="p._id"
            >
              <ion-note
                style="
                  color: var(--ion-color-primary);
                  width: 60px;
                  text-align: center;
                "
                slot="start"
                ><strong>{{ p.days + ' T ' }}<br /> </strong
              ></ion-note>
              <ion-label>
                <h2>
                  <strong
                    >{{
                      formatDate(p.date) === formatDate(p.dateTo)
                        ? formatDate(p.date)
                        : formatDate(p.date) + ' - ' + formatDate(p.dateTo)
                    }}<br /><ion-badge style="margin: 2px">{{
                      p.type === 1 ? 'Urlaub' : 'Gleitzeit'
                    }}</ion-badge></strong
                  ><br />
                  {{ p.description }}
                </h2></ion-label
              >
            </ion-item>
          </ion-item-group>
        </ion-list>
      </div>

      <ion-modal
        ref="addModal"
        :swipe-to-close="false"
        :is-open="addModalIsOpen"
        @didDismiss="addModalIsOpen = false"
        @didPresent="initAddModal"
        :presentingElement="$parent.$refs.ionRouterOutlet"
      >
        <ion-header :translucent="true">
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button
                v-if="isMobile"
                fill="clear"
                @click="cancleCreateChildObject"
                >Abbrechen</ion-button
              >
            </ion-buttons>
            <ion-title v-if="!isMobile">{{
              models[models.findIndex((e) => e.name === addModalModel)] ===
              undefined
                ? ''
                : formatLabel(
                    models[models.findIndex((e) => e.name === addModalModel)]
                      .addLabel,
                    formatLabel(
                      models[models.findIndex((e) => e.name === addModalModel)]
                        .detailLabel,
                      addModalModel
                    )
                  )
            }}</ion-title>
            <ion-buttons slot="end">
              <ion-button
                v-if="isMobile"
                @click="createChildObject"
                fill="clear"
                >Erstellen</ion-button
              >
              <ion-button
                v-if="!isMobile"
                fill="clear"
                @click="cancleCreateChildObject"
                ><custom-icon slot="icon-only" name="closeOutline"></custom-icon
              ></ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-toolbar v-if="isMobile">
            <h2 v-if="isMobile" style="font-weight: bolder; text-align: center">
              {{
                models[models.findIndex((e) => e.name === addModalModel)] ===
                undefined
                  ? ''
                  : formatLabel(
                      models[models.findIndex((e) => e.name === addModalModel)]
                        .addLabel,
                      formatLabel(
                        models[
                          models.findIndex((e) => e.name === addModalModel)
                        ].detailLabel,
                        addModalModel
                      )
                    )
              }}
            </h2>
          </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
          <FormView
            :presentingElement="$refs.addModal.$el"
            ref="addFormView"
            :parentField="addModalParentField"
            :add="true"
            :id="undefined"
            v-model="tmpAddObject"
            :model="addModalModel"
          />
        </ion-content>
        <ion-footer v-if="!isMobile">
          <ion-toolbar>
            <ion-buttons slot="end">
              <ion-button fill="clear" @click="cancleCreateChildObject"
                >Abbrechen</ion-button
              >
              <ion-button
                color="primary"
                @click="createChildObject"
                fill="clear"
                >Erstellen</ion-button
              >
            </ion-buttons>
          </ion-toolbar>
        </ion-footer>
      </ion-modal>
    </ion-content>
    <ion-footer
      class="actionsFooter"
      v-if="(modelData.actions || []).length > 0 && isMobile && !isiPad"
      :translucent="true"
    >
      <ion-toolbar style="text-align: center">
        <template v-if="!isiPad">
          <ion-button
            v-for="action in modelData.actions || []"
            :key="action.name"
            @click="executeFunction(action.name)"
            fill="clear"
            size="small"
            style="font-size: 17px"
            ><custom-icon
              v-if="
                action.icon !== '' &&
                action.icon !== undefined &&
                action.icon !== null
              "
              :name="action.icon"
            ></custom-icon>
            {{ formatLabel(action.label, action.name) }}</ion-button
          >
        </template>
      </ion-toolbar>
    </ion-footer>
    <ion-loading
      :is-open="loading"
      cssClass="my-custom-class"
      message="Bitte warten..."
    >
    </ion-loading>
    <ion-badge v-if="false" />
  </ion-page>
</template>

<script>
  import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonContent,
    IonIcon,
    IonList,
    IonItem,
    IonItemOptions,
    IonItemOption,
    IonItemSliding,
    IonModal,
    IonPopover,
    IonLoading,
    IonItemGroup,
    IonGrid,
    IonRow,
    IonCol,
    IonFooter,
    IonText,
    IonLabel,
    modalController,
    alertController,
    isPlatform,
    IonBadge,
    IonNote,
  } from '@ionic/vue';
  import {
    chevronBackOutline,
    calendarOutline,
    addOutline,
    arrowBack,
    saveOutline,
    downloadOutline,
    menuSharp,
    syncOutline,
  } from 'ionicons/icons';
  import { Data } from '@/services/dataService';
  import { Function } from '@/services/functionService';
  import { mapState, mapActions, mapMutations } from 'vuex';
  import FormView from '@/components/FormView.vue';
  import moment from 'moment';
  import * as allIcons from 'ionicons/icons';
  import { PdfView } from '@baas-capacitor/pdf-view';
  import { Directory, Filesystem } from '@capacitor/filesystem';
  import { syncProjectAccountingTime } from '@/helpers/projectAccounting.js';
  import { getCalendarCurrentYear } from '@/helpers/calendar.js';
  import { popScopeId } from '@vue/runtime-core';
  import CustomIcon from '../components/CustomIcon.vue';

  export default {
    components: {
      IonPage,
      IonHeader,
      IonToolbar,
      IonTitle,
      IonButtons,
      IonButton,
      IonMenuButton,
      IonContent,
      IonIcon,
      IonList,
      IonItem,
      IonLoading,
      IonItemGroup,
      IonGrid,
      IonRow,
      IonCol,
      IonPopover,
      IonFooter,
      IonText,
      IonLabel,
      IonItemOptions,
      IonItemOption,
      IonItemSliding,
      IonModal,
      FormView,
      IonBadge,
      IonNote,
    },
    data() {
      return {
        loading: true,
        backIcon: chevronBackOutline,
        addIcon: addOutline,
        saveIcon: saveOutline,
        calendarIcon: calendarOutline,
        exportIcon: downloadOutline,
        menuIcon: menuSharp,
        syncIcon: syncOutline,
        addModalIsOpen: false,
        addModalModel: '',
        object: undefined,
        oldObject: undefined,
        childObjects: [],
        title: '',
        editField: '',
        editType: '',
        tmpAddObject: undefined,
        addModalParentField: '',
        model: '',
        projectAccounting: [],
        workingTimeCalendar: [],
      };
    },
    computed: {
      ...mapState('models', ['models']),
      ...mapState('ui', ['showMenu']),
      ...mapState('user', ['user']),
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      modelData() {
        const defaults = {};

        if (this.models === undefined || this.models === null) {
          return defaults;
        }

        let index = this.models.findIndex((e) => e.name === this.model);

        if (index === -1) {
          return defaults;
        }

        if (this.model === 'leaveRequest') {
          var m = JSON.parse(JSON.stringify(this.models[index]));

          if (this.object !== undefined && this.object !== null) {
            // bereits abgelehnt oder genehmigt
            if (this.object.status !== 0) {
              m.actions = [];
            }

            // kein Administrator
            if (!this.isAdministrator) {
              m.actions = [];
            }
          }

          return m;
        }

        return this.models[index];
      },
      listTitle() {
        if (
          this.modelData.listLabel === undefined ||
          this.modelData.listLabel === null
        ) {
          return this.modelData.name;
        }

        if (this.modelData.listLabel.length === 0) {
          return this.modelData.name;
        }

        if (this.modelData.listLabel.length === 1) {
          return this.modelData.listLabel[0].text;
        }

        if (
          this.modelData.listLabel.findIndex(
            (e) => e.language === this.language
          ) !== -1
        ) {
          return this.modelData.listLabel[
            this.modelData.listLabel.findIndex(
              (e) => e.language === this.language
            )
          ].text;
        } else {
          if (
            this.modelData.listLabel.findIndex((e) => e.language === 'en') !==
            -1
          ) {
            return this.modelData.listLabel[
              this.modelData.listLabel.findIndex((e) => e.language === 'en')
            ].text;
          } else {
            return this.modelData.name;
          }
        }
      },
      detailTitle() {
        if (
          this.modelData.detailLabel === undefined ||
          this.modelData.detailLabel === null
        ) {
          return this.modelData.name;
        }

        if (this.modelData.detailLabel.length === 0) {
          return this.modelData.name;
        }

        if (this.modelData.detailLabel.length === 1) {
          return this.modelData.detailLabel[0].text;
        }

        if (
          this.modelData.detailLabel.findIndex(
            (e) => e.language === this.language
          ) !== -1
        ) {
          return this.modelData.detailLabel[
            this.modelData.detailLabel.findIndex(
              (e) => e.language === this.language
            )
          ].text;
        } else {
          if (
            this.modelData.detailLabel.findIndex((e) => e.language === 'en') !==
            -1
          ) {
            return this.modelData.detailLabel[
              this.modelData.detailLabel.findIndex((e) => e.language === 'en')
            ].text;
          } else {
            return this.modelData.name;
          }
        }
      },
      childModels() {
        var childModels = [];
        if (this.modelData.childModels !== undefined) {
          this.modelData.childModels.forEach((model) => {
            childModels.push(model);
          });
        }
        return childModels;
      },
      prefersDark() {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      },
      isMobile() {
        return true;
      },
      isiPad: function () {
        return isPlatform('ipad') || isPlatform('desktop');
      },
      changed() {
        return JSON.stringify(this.oldObject) !== JSON.stringify(this.object);
      },
      pageTitle() {
        var title = '';
        try {
          title = this.detailTitle;
          if (
            this.$route.query.parentField !== undefined &&
            this.modelData.fields !== undefined
          ) {
            var parentField =
              this.modelData.fields[
                this.modelData.fields.findIndex(
                  (e) => e.name == this.$route.query.parentField
                )
              ];
            var model =
              parentField === undefined ? '' : parentField.referencedModel;
            if (model !== '') {
              console.log('Page Title', model);
              var parentModel =
                this.models[this.models.findIndex((e) => e.name === model)];
              if (
                parentModel.detailLabel !== undefined &&
                parentModel.detailLabel !== null
              ) {
                if (parentField.detailLabel.length === 0) {
                  title = parentModel.name + ' / ' + title;
                }

                if (parentModel.detailLabel.length === 1) {
                  title = parentModel.detailLabel[0].text + ' / ' + title;
                } else {
                  if (
                    parentModel.detailLabel.findIndex(
                      (e) => e.language === this.language
                    ) !== -1
                  ) {
                    title =
                      parentModel.detailLabel[
                        parentModel.detailLabel.findIndex(
                          (e) => e.language === this.language
                        )
                      ].text +
                      ' / ' +
                      title;
                  } else {
                    if (
                      parentModel.detailLabel.findIndex(
                        (e) => e.language === 'en'
                      ) !== -1
                    ) {
                      title =
                        parentModel.detailLabel[
                          parentModel.detailLabel.findIndex(
                            (e) => e.language === 'en'
                          )
                        ].text +
                        ' / ' +
                        title;
                    } else {
                      title = parentModel.name + ' / ' + title;
                    }
                  }
                }
              } else {
                title = model + ' / ' + title;
              }
            }
          }
        } catch (error) {
          title = this.detailTitle;
        }
        return title;
      },
      projectAccountingData() {
        if (this.model !== 'projectAccounting') {
          return [];
        }

        let data = this.childObjectsData(
          this.childModels[
            this.childModels.findIndex(
              (e) => e.name === 'projectAccountingTime'
            )
          ]
        );

        if (data === undefined) {
          return [];
        } else {
          return data;
        }
      },
      projectAccountingSum() {
        var duration = 0;

        this.projectAccountingData.forEach((p) => {
          duration = this.showProjectAccountingInTS
            ? duration + this.roundByQuarter(p.hours / 8)
            : duration + p.hours;
        });

        return duration;
      },
      projectAccountingSumBooked() {
        var duration = 0;

        if (this.projectAccounting === undefined) {
          return 0;
        }

        this.projectAccounting.forEach((p) => {
          duration = this.showProjectAccountingInTS
            ? duration +
              this.roundByQuarter(this.formatDuration(p.timeFrom, p.timeTo) / 8)
            : duration + this.formatDuration(p.timeFrom, p.timeTo);
        });

        return duration;
      },
      projectAccountingDrivingDistanceSum() {
        var distance = 0;

        this.projectAccountingData.forEach((p) => {
          distance =
            distance +
            (p.drivingDistance === undefined ||
            p.drivingDistance === null ||
            isNaN(p.drivingDistance)
              ? 0
              : p.drivingDistance);
        });

        return distance;
      },
      projectAccountingSumByEmployee() {
        var pData = [];
        var duration = 0;
        var drivingDistance = 0;

        this.projectAccountingData.forEach((p) => {
          duration = this.showProjectAccountingInTS
            ? this.roundByQuarter(p.hours / 8)
            : p.hours;
          drivingDistance =
            p.drivingDistance === undefined ||
            p.drivingDistance === null ||
            isNaN(p.drivingDistance)
              ? 0
              : p.drivingDistance;
          if (
            pData.findIndex((e) => e.employee._id === p.employee._id) === -1
          ) {
            pData.push({
              employee: p.employee,
              duration: duration,
              drivingDistance: drivingDistance,
            });
          } else {
            pData[
              pData.findIndex((e) => e.employee._id === p.employee._id)
            ].duration =
              pData[pData.findIndex((e) => e.employee._id === p.employee._id)]
                .duration + duration;
            pData[
              pData.findIndex((e) => e.employee._id === p.employee._id)
            ].drivingDistance =
              pData[pData.findIndex((e) => e.employee._id === p.employee._id)]
                .drivingDistance + drivingDistance;
          }
        });

        return pData;
      },
      projectAccountingSumByEmployeeBooked() {
        var pData = [];
        var duration = 0;
        var drivingDistance = 0;

        if (this.projectAccounting === undefined) {
          return [];
        }

        this.projectAccounting.forEach((p) => {
          duration = this.formatDuration(p.timeFrom, p.timeTo);
          duration = this.showProjectAccountingInTS
            ? this.roundByQuarter(this.formatDuration(p.timeFrom, p.timeTo) / 8)
            : this.formatDuration(p.timeFrom, p.timeTo);
          drivingDistance =
            p.drivingDistance === undefined ||
            p.drivingDistance === null ||
            isNaN(p.drivingDistance)
              ? 0
              : p.drivingDistance;
          if (
            pData.findIndex((e) => e.employee._id === p.employee._id) === -1
          ) {
            pData.push({
              employee: p.employee,
              duration: duration,
              drivingDistance: drivingDistance,
            });
          } else {
            pData[
              pData.findIndex((e) => e.employee._id === p.employee._id)
            ].duration =
              pData[pData.findIndex((e) => e.employee._id === p.employee._id)]
                .duration + duration;
            pData[
              pData.findIndex((e) => e.employee._id === p.employee._id)
            ].drivingDistance =
              pData[pData.findIndex((e) => e.employee._id === p.employee._id)]
                .drivingDistance + drivingDistance;
          }
        });

        return pData;
      },
      showProjectAccountingDrivingDistance() {
        if (this.object === undefined) {
          return false;
        }

        if (this.object.projectId === undefined) {
          return false;
        }

        if (
          this.object.projectId.projectAccountingDrivingDistance === 0 ||
          this.object.projectId.projectAccountingDrivingDistance === undefined
        ) {
          return true;
        }

        return false;
      },
      showProjectAccountingInTS() {
        if (this.object === undefined) {
          return false;
        }

        if (this.object.projectId === undefined) {
          return false;
        }

        if (
          this.object.projectId.projectAccountingType === 0 ||
          this.object.projectId.projectAccountingType === undefined
        ) {
          return true;
        }

        return false;
      },
      employeeHolidays() {
        if (
          this.workingTimeCalendar === undefined ||
          this.workingTimeCalendar === null
        ) {
          return 0;
        }

        return this.workingTimeCalendar.filter((e) => e.type === 1).length;
      },
      employeeFlexDays() {
        if (
          this.workingTimeCalendar === undefined ||
          this.workingTimeCalendar === null
        ) {
          return 0;
        }

        return this.workingTimeCalendar.filter((e) => e.type === 2).length;
      },
      employeeHolidaysSum() {
        if (this.object === undefined || this.object === null) {
          return 0;
        }

        if (
          this.object.employee === undefined ||
          this.object.employee === null
        ) {
          return 0;
        }

        return this.object.employee.holidays;
      },
      calcWorkingTimeCalendar() {
        let wd = [];

        if (
          this.workingTimeCalendar === undefined ||
          this.workingTimeCalendar === null
        ) {
          return [];
        }

        this.workingTimeCalendar
          .filter((e) => e.type !== 0)
          .forEach((element) => {
            var d = JSON.parse(JSON.stringify(element));
            d.dateTo = d.date;
            d.days = 1;
            if (wd.findIndex((e) => e._id === d._id) === -1) {
              wd.push(d);
            } else {
              wd[wd.findIndex((e) => e._id === d._id)].dateTo = d.dateTo;
              wd[wd.findIndex((e) => e._id === d._id)].days =
                wd[wd.findIndex((e) => e._id === d._id)].days + 1;
            }
          });

        return wd;
      },
    },
    async mounted() {
      await this.loadData();
    },
    async updated() {
      var updates = JSON.parse(localStorage.getItem('updates'));
      if (updates === undefined || updates === null) {
        updates = [];
      }

      if (this.modelData.name === 'projectAccounting') {
        var updatesAfter = [];
        updates.forEach((u) => {
          let index = this.childModels.findIndex((e) => e.name === u.model);
          if (index !== -1) {
            if (
              this.childObjects[
                this.childObjects.findIndex((e) => e.name === u.model)
              ].data.findIndex((e) => e._id === u.data._id) !== -1
            ) {
              this.childObjects[
                this.childObjects.findIndex((e) => e.name === u.model)
              ].data[
                this.childObjects[
                  this.childObjects.findIndex((e) => e.name === u.model)
                ].data.findIndex((e) => e._id === u.data._id)
              ] = u.data;
            } else {
              this.childObjects[
                this.childObjects.findIndex((e) => e.name === u.model)
              ].data.push(u.data);
            }
          } else if (u.model === 'projectTime') {
            let index = this.projectAccounting.findIndex(
              (e) => e._id === u.data._id
            );
            if (index !== -1) {
              this.projectAccounting[index] = u.data;
            } else {
              this.projectAccounting.push(u.data);
            }
          } else {
            updatesAfter.push(u);
          }
        });
        localStorage.setItem('updates', JSON.stringify(updatesAfter));
      }
    },
    methods: {
      ...mapActions('models', ['loadModels']),
      ...mapMutations('ui', ['setShowMenu']),
      async loadData() {
        this.loading = true;
        try {
          this.editField = '';
          this.model = this.$route.params.model;
          await this.loadModels();
          if (!this.$route.path.toString().includes('add')) {
            this.object = (
              await Data.Instance.find(
                this.model,
                { _id: { $eq: { $oid: this.$route.params.id } } },
                {}
              )
            )[0];
            this.oldObject = JSON.parse(JSON.stringify(this.object));
            for (const index in this.childModels) {
              var childModel = this.childModels[index];
              var model =
                this.models[
                  this.models.findIndex((e) => e.name === childModel.name)
                ];
              var filter = {};
              filter[childModel.referenceFiled + '._id'] = {
                $eq: { $oid: this.object._id },
              };
              var data = await Data.Instance.find(model.name, filter, {});
              if (
                this.childObjects.findIndex((e) => e.name === model.name) !== -1
              ) {
                this.childObjects[
                  this.childObjects.findIndex((e) => e.name === model.name)
                ] = data;
              } else {
                this.childObjects.push({ name: model.name, data: data });
              }
            }
          }

          // Project Accounting Special Code
          var currentDate = moment().lang('de');
          var start = currentDate.clone().startOf('year');
          var end = currentDate.clone().endOf('year');

          if (this.model === 'projectAccounting') {
            start = moment(this.object.dateFrom).startOf('day');
            end = moment(this.object.dateTo).endOf('day');
            this.projectAccounting = await Data.Instance.find(
              'projectTime',
              {
                $and: [
                  {
                    'projectId._id': {
                      $eq: { $oid: this.object.projectId._id },
                    },
                  },
                  {
                    date: { $gte: { $date: start }, $lt: { $date: end } },
                  },
                ],
              },
              {}
            );
          }

          // Employee Special Code
          if (this.model === 'employee') {
            this.workingTimeCalendar = await getCalendarCurrentYear(
              this.object._id
            );
            console.log('Working Time Calendar', this.workingTimeCalendar);
          }

          // Leave Request Special Code
          if (this.model === 'leaveRequest') {
            this.workingTimeCalendar = await getCalendarCurrentYear(
              this.object.employee._id
            );
            console.log('Working Time Calendar', this.workingTimeCalendar);
          }
        } catch (error) {
          this.loading = false;
          const alert = await alertController.create({
            cssClass: 'my-custom-class',
            header: 'Daten nicht verfügbar',
            message: 'Daten konnte nicht geladen werden. ' + error.message,
            buttons: ['OK'],
          });
          await alert.present();
        }

        this.loading = false;
      },
      async back() {
        this.$router.back();
      },
      async save() {
        this.loading = true;
        try {
          let object = await this.formatObject(
            this.modelData.name,
            this.object
          );
          console.log('Save Object', this.object, object);
          const result = await Data.Instance.update(
            this.model,
            { _id: { $eq: { $oid: this.object._id } } },
            object
          );
          this.object = result.updatedObjects[0];
          var updates = JSON.parse(localStorage.getItem('updates'));
          if (updates === undefined || updates === null) {
            updates = [];
          }
          updates.push({
            model: this.modelData.name,
            data: JSON.parse(JSON.stringify(this.object)),
          });
          localStorage.setItem('updates', JSON.stringify(updates));
          this.$router.back();
        } catch (error) {
          this.loading = false;
          const alert = await alertController.create({
            cssClass: 'my-custom-class',
            header: this.detailTitle + ' nicht gespeichert',
            message:
              this.detailTitle +
              ' konnte nicht gespeichert werden. ' +
              error.message,
            buttons: ['OK'],
          });
          await alert.present();
        } finally {
          this.loading = false;
        }
      },
      async openAdd(model) {
        this.addModalModel = model;
        await this.initAddModal();
        this.addModalIsOpen = true;
      },
      async initAddModal() {
        var object = await this.setDefaults(this.addModalModel);
        var index = this.modelData.childModels.findIndex(
          (e) => e.name === this.addModalModel
        );
        var refField = this.modelData.childModels[index].referenceFiled;
        this.addModalParentField = refField;
        object[refField] = JSON.parse(JSON.stringify(this.object));
        this.tmpAddObject = object;
      },
      async setDefaults(model) {
        if (this.models.findIndex((e) => e.name === model) === -1) {
          return {};
        }
        var modelData =
          this.models[this.models.findIndex((e) => e.name === model)];
        var tmpObject = {};
        modelData.fields.forEach((field) => {
          switch (field.dataType) {
            case 'Date':
            case 'Time':
            case 'DateTime':
              tmpObject[field.name] = new Date().toUTCString();
              break;
            case 'FileReference':
            case 'ObjectReference':
              if (field.referenceType === 'Single') {
                tmpObject[field.name] = {};
                tmpObject[field.name]._id = '';
              } else {
                tmpObject[field.name] = [];
              }
              break;
            case 'Integer':
            case 'Enumeration':
              tmpObject[field.name] = 0;
              break;
            case 'Double':
              tmpObject[field.name] = 0.0;
              break;
            default:
              tmpObject[field.name] = '';
              break;
          }
        });
        return tmpObject;
      },
      openDetails(model, object) {
        console.log(model);
        this.$router.push({
          path: '/models/' + model.name + '/details/' + object._id,
          query: { parentField: model.referenceFiled },
        });
      },
      async deleteObject(model, object) {
        const alert = await alertController.create({
          header: 'Löschen',
          message:
            this.formatLabel(model.detailLabel, model.name) +
            ' wirklich löschen?',
          buttons: [
            {
              text: 'Abbrechen',
              handler: async () => {
                console.log('Cancle');
              },
            },
            {
              text: 'Löschen',
              handler: async () => {
                this.loading = true;
                const result = await Data.Instance.delete(
                  model.name,
                  { _id: { $eq: { $oid: object._id } } },
                  {}
                );
                if (result.deletedCount === 1) {
                  this.childObjects[
                    this.childObjects.findIndex((e) => e.name === model.name)
                  ].data.splice(
                    this.childObjects[
                      this.childObjects.findIndex((e) => e.name === model.name)
                    ].data.findIndex((e) => e._id === object._id),
                    1
                  );
                }
                this.loading = false;
              },
            },
          ],
        });
        await alert.present();
      },
      async executeFunction(name) {
        this.loading = true;
        try {
          const result = await Function.Instance.execute(name, {
            _id: this.object._id,
            model: this.model,
          });

          if (!name.includes('export')) {
            console.log('Load Data');
            await this.loadData();
            var updates = JSON.parse(localStorage.getItem('updates'));
            if (updates === undefined || updates === null) {
              updates = [];
            }
            updates.push({
              model: this.modelData.name,
              data: JSON.parse(JSON.stringify(this.object)),
            });
            localStorage.setItem('updates', JSON.stringify(updates));
            this.$router.back();
          }

          if (name.includes('export')) {
            var fileURL = window.URL.createObjectURL(
              new Blob([result], { type: 'application/pdf' })
            );

            var filename = '';

            if (name === 'exportProjectAccounting') {
              filename =
                moment(this.object.date).format('yMMDD') +
                '_Projektabrechnung_' +
                this.object.projectId.name
                  .replaceAll(' ', '_')
                  .replaceAll('-', '_')
                  .replaceAll('/', '_') +
                '_' +
                moment(this.object.dateFrom).format('DDMMYYY') +
                '_' +
                moment(this.object.dateTo).format('DDMMYYY') +
                '.pdf';
            } else {
              filename =
                moment(this.object.date).format('yMMDD') +
                '_Bautagebuch_' +
                this.object.projectId.name
                  .replaceAll(' ', '_')
                  .replaceAll('-', '_')
                  .replaceAll('/', '_') +
                '_' +
                this.object.sequence +
                '.pdf';
            }

            if (isPlatform('capacitor')) {
              const convertToBase64 = (blob) =>
                new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onerror = reject;
                  reader.onload = () => {
                    resolve(reader.result);
                  };
                  reader.readAsDataURL(blob);
                });
              const base64Data = await convertToBase64(
                await (await fetch(fileURL)).blob()
              );
              const saveFile = await Filesystem.writeFile({
                path: filename,
                data: base64Data,
                directory: Directory.Documents,
              });
              PdfView.open({ path: saveFile.uri, accentColor: 'afca0b' });
            } else {
              var fileLink = document.createElement('a');
              fileLink.href = fileURL;

              fileLink.setAttribute('download', filename);
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
          let action = this.modelData.actions.find((e) => e.name === name);
          console.log('Action', action);
          const alert = await alertController.create({
            cssClass: 'my-custom-class',
            header: this.formatLabel(action.label, name) + '',
            message:
              'Funktion ' +
              this.formatLabel(action.label, name) +
              ' konnte nicht erfolgreich durchgeführt werden. ' +
              error.message,
            buttons: ['OK'],
          });
          await alert.present();
        }
      },
      async cancleCreateChildObject() {
        this.addModalIsOpen = false;
        this.addModalModel = '';
        this.tmpAddObject = {};
      },
      async createChildObject() {
        try {
          this.loading = true;
          let object = await this.formatObject(
            this.addModalModel,
            this.tmpAddObject
          );
          const result = await Data.Instance.insert(this.addModalModel, [
            object,
          ]);
          result.insertedObjects.forEach((object) => {
            if (
              this.childObjects[
                this.childObjects.findIndex(
                  (e) => e.name === this.addModalModel
                )
              ].data === undefined
            ) {
              this.childObjects[
                this.childObjects.findIndex(
                  (e) => e.name === this.addModalModel
                )
              ].data = [];
            }

            if (
              this.childObjects[
                this.childObjects.findIndex(
                  (e) => e.name === this.addModalModel
                )
              ].data.length > 0
            ) {
              this.childObjects[
                this.childObjects.findIndex(
                  (e) => e.name === this.addModalModel
                )
              ].data.unshift(object);
            } else {
              this.childObjects[
                this.childObjects.findIndex(
                  (e) => e.name === this.addModalModel
                )
              ].data.push(object);
            }
          });
          this.addModalIsOpen = false;
          this.loading = false;
          this.addModalModel = '';
        } catch (error) {
          this.loading = false;
          const alert = await alertController.create({
            cssClass: 'my-custom-class',
            header: this.detailTitle + ' nicht gespeichert',
            message:
              this.detailTitle +
              ' konnte nicht gespeichert werden. ' +
              error.message,
            buttons: ['OK'],
          });
          await alert.present();
        }
      },
      async modalTop() {
        return await modalController.getTop();
      },
      childModelContainsRecords(model) {
        if (this.childObjects.findIndex((e) => e.name === model.name) !== -1) {
          if (
            this.childObjects[
              this.childObjects.findIndex((e) => e.name === model.name)
            ].data.length > 0
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      childModelData(model) {
        console.log(this.models);
        if (this.models.findIndex((e) => e.name === model.name) !== -1) {
          return this.models[
            this.models.findIndex((e) => e.name === model.name)
          ];
        } else {
          return {};
        }
      },
      childObjectsDataLength(model) {
        if (this.childObjects.findIndex((e) => e.name === model.name) !== -1) {
          var length =
            this.childObjects[
              this.childObjects.findIndex((e) => e.name === model.name)
            ].data.length;
          console.log('Child Object Length ' + model.name, length);
          return length;
        } else {
          return 0;
        }
      },
      childObjectsData(model) {
        if (this.childObjects.findIndex((e) => e.name === model.name) !== -1) {
          return this.childObjects[
            this.childObjects.findIndex((e) => e.name === model.name)
          ].data;
        } else {
          return [];
        }
      },
      async formatObject(model, o) {
        const modelData =
          this.models[this.models.findIndex((e) => e.name === model)];
        if (modelData === undefined) {
          return {};
        }
        let object = {};
        for (let prop in o) {
          if (modelData.fields.findIndex((e) => e.name === prop) !== -1) {
            const field =
              modelData.fields[
                modelData.fields.findIndex((e) => e.name === prop)
              ];
            switch (field.dataType) {
              case 'ObjectReference':
              case 'FileReference':
                if (field.referenceType === 'Single') {
                  if (o[prop] !== undefined) {
                    if (o[prop]._id !== undefined) {
                      object[prop] = { $oid: o[prop]._id };
                    }
                  }
                } else {
                  if (o[prop] === undefined || o[prop] === null) {
                    o[prop] = [];
                  }
                  var files = o[prop].filter((e) => e._id === null);
                  var filesDelete = o[prop].filter((e) => e.deleted === true);
                  object[prop] = o[prop]
                    .filter((e) => e._id !== null && e.deleted !== true)
                    .map((e) => {
                      return { $oid: e._id };
                    });
                  for (let file in files) {
                    console.log(
                      'Upload File',
                      field.referencedModel,
                      files[file]
                    );
                    var fileResult = await Data.Instance.insert(
                      field.referencedModel,
                      [
                        {
                          filename: files[file].filename,
                          data: files[file].data,
                        },
                      ],
                      {}
                    );
                    fileResult.insertedIds.forEach((id) => {
                      object[prop].push({ $oid: id });
                    });
                  }

                  for (let file in filesDelete) {
                    console.log(
                      'Delete File',
                      field.referencedModel,
                      filesDelete[file]
                    );
                    await Data.Instance.delete(
                      field.referencedModel,
                      { _id: { $eq: { $oid: filesDelete[file]._id } } },
                      {}
                    );
                  }
                }
                break;
              case 'Date':
              case 'DateTime':
              case 'Time':
                object[prop] = { $date: o[prop] };
                break;
              case 'Enumeration':
                object[prop] = parseInt(o[prop].toString());
                break;
              case 'Integer':
                object[prop] = parseInt(o[prop]);
                break;
              case 'Double':
                object[prop] = parseFloat(parseFloat(o[prop]).toFixed(2));
                break;
              case 'SequenceNumber':
                break;
              default:
                object[prop] = o[prop];
                break;
            }
          }
        }

        return object;
      },
      async syncProjectAccounting() {
        const alert = await alertController.create({
          header: 'Synchronisieren',
          message:
            'Erfasste Zeit & Fahrtwege aus Zeiterfassung synchronisieren?',
          buttons: [
            {
              text: 'Abbrechen',
              handler: async () => {
                console.log('Cancle');
              },
            },
            {
              text: 'Synchronisieren',
              handler: this.syncProjectAccountingData,
            },
          ],
        });
        await alert.present();
      },
      async syncProjectAccountingData() {
        this.loading = true;
        let result = await syncProjectAccountingTime(this.object);
        this.projectAccounting = JSON.parse(JSON.stringify(result.projectTime));
        let p = JSON.parse(JSON.stringify(result.projectAccountingTime));

        // Update UI
        this.childObjects[
          this.childObjects.findIndex((e) => e.name === 'projectAccountingTime')
        ].data = [];

        p.forEach((object) => {
          if (
            this.childObjects[
              this.childObjects.findIndex(
                (e) => e.name === 'projectAccountingTime'
              )
            ].data.length > 0
          ) {
            this.childObjects[
              this.childObjects.findIndex(
                (e) => e.name === 'projectAccountingTime'
              )
            ].data.unshift(object);
          } else {
            this.childObjects[
              this.childObjects.findIndex(
                (e) => e.name === 'projectAccountingTime'
              )
            ].data.push(object);
          }
        });

        this.loading = false;
      },
      calcProjectAccoutingDiff(projectAccoutingTime) {
        return (
          projectAccoutingTime.duration -
          (this.projectAccountingSumByEmployeeBooked.findIndex(
            (e) => e.employee._id === projectAccoutingTime.employee._id
          ) !== -1
            ? this.projectAccountingSumByEmployeeBooked[
                this.projectAccountingSumByEmployeeBooked.findIndex(
                  (e) => e.employee._id === projectAccoutingTime.employee._id
                )
              ].duration
            : 0)
        );
      },
      formatText(model, format, object) {
        if (format === undefined || object === undefined) {
          return '';
        }
        var text = format.toString();
        this.models[
          this.models.findIndex((e) => e.name === model.name)
        ].fields.forEach((field) => {
          var formattedValue = object[field.name];
          switch (field.dataType) {
            case 'ObjectReference':
              formattedValue =
                object[field.name] !== undefined ? object[field.name].name : '';
              break;
            case 'FileReference':
              formattedValue = '<div style="width: 100%; height: 40px">';
              if (field.referenceType === 'Single') {
                formattedValue += `<img style="width: 40px; height: 40px; margin-right: 5px" src="${
                  object[field.name].data
                }" />`;
              } else {
                object[field.name].forEach((file) => {
                  console.log(file);
                  formattedValue += `<img style="width: 40px; height: 40px; margin-right: 5px" src="${file.data}" />`;
                });
              }
              formattedValue += '</div>';
              break;
            case 'Date':
              formattedValue = this.formatDate(object[field.name]);
              break;
            case 'Enumeration':
              var icon = this.formatEnumerationIcon(field, object[field.name]);
              if (icon !== '') {
                icon = allIcons[icon]
                  .replaceAll('data:image/svg+xml;utf8,', '')
                  .replaceAll(
                    '<svg ',
                    '<svg style="width: 28px; height: 28px; stroke-width: var(--ionicon-stroke-width, 32px)"'
                  )
                  .replaceAll(
                    'class=',
                    'style="fill: none; stroke-width: var(--ionicon-stroke-width, 32px); stroke: var(--ion-color-primary, #3880ff)" class='
                  );
                formattedValue = icon;
              } else {
                formattedValue = this.formatEnumeration(
                  object[field.name],
                  field.enumeration.options
                );
              }

              break;
            default:
              break;
          }
          text = text.replaceAll('{{' + field.name + '}}', formattedValue);
        });

        return text;
      },
      formatDuration(start, end) {
        var _start = moment(start)
          .set('year', 0)
          .set('month', 0)
          .set('date', 0)
          .seconds(0)
          .millisecond(0);
        var _end = moment(end)
          .set('year', 0)
          .set('month', 0)
          .set('date', 0)
          .seconds(0)
          .millisecond(0);
        var duration = moment.duration(_end.diff(_start));
        return parseFloat((duration._milliseconds / 3600000).toFixed(2));
      },
      workdayCount(start, end) {
        var s = moment(start).clone().startOf('day');
        var e = moment(end).clone().endOf('day');
        var count = 0;
        for (var d = moment(s); d.isBefore(e); d.add(1, 'days')) {
          if (d.day() !== 6 && d.day() !== 0 && !this.isPublicHolliday(d)) {
            count++;
          }
        }

        return count;
      },
      isPublicHolliday(checkDay) {
        let day = checkDay.clone().startOf('day');
        return (
          this.workingTimeCalendar.findIndex(
            (e) =>
              e.type === 0 &&
              moment(e.date).startOf('day').isSameOrAfter(day) &&
              moment(e.dateTo).startOf('day').isSameOrBefore(day)
          ) !== -1
        );
      },
      formatLabel(labels, defaultValue) {
        if (labels === undefined || labels === null) {
          return defaultValue;
        }

        if (labels.length === 0) {
          return defaultValue;
        }

        if (labels.length === 1) {
          return labels[0].text;
        }

        if (labels.findIndex((e) => e.language === this.language) !== -1) {
          return labels[labels.findIndex((e) => e.language === this.language)]
            .text;
        } else {
          if (labels.findIndex((e) => e.language === 'en') !== -1) {
            return labels[labels.findIndex((e) => e.language === 'en')].text;
          } else {
            return defaultValue;
          }
        }
      },
      formatEnumeration(value, options) {
        if (options.findIndex((e) => e.value === value) !== -1) {
          var option = options[options.findIndex((e) => e.value === value)];

          if (option.label !== undefined) {
            if (
              option.label.findIndex((e) => e.language === this.language) !== -1
            ) {
              return option.label[
                option.label.findIndex((e) => e.language === this.language)
              ].text;
            } else {
              return option.name;
            }
          } else {
            return option.name;
          }
        }

        return '';
      },
      formatEnumerationIcon(field, value) {
        var options = field.enumeration.options;
        if (options === null || options === undefined) {
          return '';
        }

        if (
          options.length === 0 ||
          options.length === undefined ||
          options.length === null
        ) {
          return '';
        }

        if (options.findIndex((e) => e.value === value) !== -1) {
          return options[options.findIndex((e) => e.value === value)].icon !==
            undefined &&
            options[options.findIndex((e) => e.value === value)].icon !== null
            ? options[options.findIndex((e) => e.value === value)].icon
            : '';
        }

        return '';
      },
      formatDate(date) {
        return moment(date).toDate().toLocaleDateString();
      },
      formatTime(date) {
        return moment(date).toDate().toLocaleTimeString();
      },
      formatTimeSimple(date) {
        return moment(date).format('HH:mm');
      },
      roundByQuarter(value) {
        var inv = 1.0 / 0.25;
        var result = Math.round(value * inv) / inv;

        return value > 0.0 && result === 0 ? 0.25 : result;
      },
    },
  };
</script>

<style scoped>
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-toolbar {
    --background: var(--ion-color-light);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  .datetime-calendar {
    background-color: white;
  }

  .actionsFooter {
    border-top: 1px solid var(--ion-color-light-shade);
  }

  @media (prefers-color-scheme: dark) {
    ion-content {
      --background: var(--ion-background-color);
      --ion-item-background: #1c1c1e;
    }

    ion-toolbar {
      --background: var(--ion-background-color);
      --border-color: transparent;
      --box-shadow: 0px;
    }

    .datetime-calendar {
      background-color: white;
    }

    .actionsFooter {
      border-top: 1px solid var(--ion-color-light-shade);
    }
  }

  .button-with-no-padding {
    --padding-start: 0px;
    --padding-end: 2px;
  }
</style>
