import { Drivers, Storage } from '@ionic/storage';
import { AsyncStorage } from "vuex-persist";
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

export class dataStorage implements AsyncStorage {
    _config?: { name: string; } | undefined;
    storage?:Storage
    name: string
    splitKeys: boolean

    constructor (name:string, splitKeys?:boolean) {
        this.name = name
        this.splitKeys = splitKeys === undefined ? false : splitKeys
    }

    public async init () {
        const storage = new Storage({
            name: this.name,
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        });
        return await storage.create();
    }

    async getItem<T> (key: string): Promise<T> {
        if (this.storage === undefined) {
             this.storage = await this.init()
        }
       
        const result = await this.storage.get(key)
        return JSON.parse(result)
    }
    async setItem<T> (key: string, data: T): Promise<T> {
        if (this.storage === undefined) {
             this.storage = await this.init()
        }
        await this.storage.set(key, JSON.stringify(data))
        return data
    }
    async removeItem (key: string): Promise<void> {
        if (this.storage === undefined) {
             this.storage = await this.init()
        }

        await this.storage.remove(key)
    }
    async clear (): Promise<void> {
        if (this.storage === undefined) {
             this.storage = await this.init()
        }

        await this.storage.clear()
    }

    length (): Promise<number> {
        throw new Error("Method not implemented.");
    }
    key (keyIndex: number): Promise<string> {
        throw new Error("Method not implemented." + keyIndex);
    }
    

}