
  import { defineComponent, ref } from 'vue';
  import { IonModal, IonNav } from '@ionic/vue';
  import { logOutOutline, settingsOutline } from 'ionicons/icons';
  import { Auth } from '@/services/authService';
  import { AuthActions } from 'baas-ionic-appauth';
  import { mapActions, mapState } from 'vuex';
  import { User } from '../models/user';
  import AccountModalOverview from '@/components/AccountModalOverview.vue';

  export default defineComponent({
    name: 'AccountModal',
    components: {
      IonModal,
      IonNav,
    },
    data() {
      return {
        isOpen: false,
        showSettings: false,
        logOutIcon: logOutOutline,
        rootPage: AccountModalOverview,
        settingsIcon: settingsOutline,
      };
    },
    props: {
      presentingElement: {
        type: Element,
      },
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('ui', ['isOnline']),
      name(): string {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.firstName !== undefined && this.user.lastName !== null) {
          return this.user.firstName + ' ' + this.user.lastName;
        }

        return '';
      },
      username(): string {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.username !== undefined && this.user.username !== null) {
          return this.user.username;
        }

        return '';
      },
      firstLetterUsername(): string {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.username !== undefined && this.user.username !== null) {
          return this.user.username.substring(0, 1).toUpperCase();
        }

        return '';
      },
    },
    created() {
      Auth.Instance.user$.subscribe((u) => {
        if (u === undefined) {
          return;
        }
        let username: string = u.username || '';
        let firstName: string = u.profile.firstName || '';
        let lastName: string = u.profile.lastName || '';
        let isAdministrator: boolean = u.is_administrator || false;
        const roles: string[] = u.roles || [];
        const id: string = u.sub || '';
        const user: User = {
          _id: id,
          username: username,
          firstName: firstName,
          lastName: lastName,
          isAdministrator: isAdministrator,
          roles: roles,
        };
        this.setUser(user);
      });

      Auth.Instance.events$.subscribe((action) => {
        //this.event = JSON.stringify(action);
        if (action.action === AuthActions.SignOutSuccess) {
          this.$router.push('/landing');
        }
      });
    },
    methods: {
      ...mapActions('user', ['setUser']),
      async setOpen(state: boolean) {
        if (state && this.isOnline) {
          await Auth.Instance.loadUserInfo();
        }
        this.isOpen = state;
      },
      async signOut() {
        await Auth.Instance.signOut();
      },
      showDetail(title: string) {
        const nav = document.querySelector('ion-nav');
        if (nav === null) {
          return;
        }
        nav.insert(0, '<h1>Test</h1>');
        nav.push('nav-detail', { title });
      },
    },
  });
