<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button
            fill="clear"
            v-if="isiPad && !showMenu"
            icon-only
            shape="round"
            @click="setShowMenu(!showMenu)"
          >
            <svg version="1.1" width="23.0273" height="17.998">
              <g>
                <rect height="17.998" opacity="0" width="23.0273" x="0" y="0" />
                <path
                  fill="var(--ion-color-primary)"
                  d="M3.06641 17.998L19.9609 17.998Q21.5039 17.998 22.2656 17.2412Q23.0273 16.4844 23.0273 14.9707L23.0273 3.04688Q23.0273 1.5332 22.2656 0.776367Q21.5039 0.0195312 19.9609 0.0195312L3.06641 0.0195312Q1.5332 0.0195312 0.766602 0.776367Q0 1.5332 0 3.04688L0 14.9707Q0 16.4844 0.766602 17.2412Q1.5332 17.998 3.06641 17.998ZM3.08594 16.4258Q2.35352 16.4258 1.96289 16.04Q1.57227 15.6543 1.57227 14.8926L1.57227 3.125Q1.57227 2.36328 1.96289 1.97754Q2.35352 1.5918 3.08594 1.5918L19.9414 1.5918Q20.6641 1.5918 21.0596 1.97754Q21.4551 2.36328 21.4551 3.125L21.4551 14.8926Q21.4551 15.6543 21.0596 16.04Q20.6641 16.4258 19.9414 16.4258ZM7.44141 16.7285L8.97461 16.7285L8.97461 1.29883L7.44141 1.29883ZM5.56641 5.21484Q5.78125 5.21484 5.95215 5.04395Q6.12305 4.87305 6.12305 4.66797Q6.12305 4.44336 5.95215 4.28223Q5.78125 4.12109 5.56641 4.12109L3.4668 4.12109Q3.25195 4.12109 3.08594 4.28223Q2.91992 4.44336 2.91992 4.66797Q2.91992 4.87305 3.08594 5.04395Q3.25195 5.21484 3.4668 5.21484ZM5.56641 7.74414Q5.78125 7.74414 5.95215 7.57812Q6.12305 7.41211 6.12305 7.1875Q6.12305 6.97266 5.95215 6.81152Q5.78125 6.65039 5.56641 6.65039L3.4668 6.65039Q3.25195 6.65039 3.08594 6.81152Q2.91992 6.97266 2.91992 7.1875Q2.91992 7.41211 3.08594 7.57812Q3.25195 7.74414 3.4668 7.74414ZM5.56641 10.2637Q5.78125 10.2637 5.95215 10.1025Q6.12305 9.94141 6.12305 9.72656Q6.12305 9.50195 5.95215 9.34082Q5.78125 9.17969 5.56641 9.17969L3.4668 9.17969Q3.25195 9.17969 3.08594 9.34082Q2.91992 9.50195 2.91992 9.72656Q2.91992 9.94141 3.08594 10.1025Q3.25195 10.2637 3.4668 10.2637Z"
                />
              </g>
            </svg>
          </ion-button>
          <account-button
            v-if="isMobile && !isiPad"
            @click="openAccountModal"
          />
        </ion-buttons>
        <ion-title>Admin Dashboard</ion-title>
        <ion-buttons slot="end"> </ion-buttons>
      </ion-toolbar>
      <ion-toolbar class="custom-top-toolbar">
        <ion-segment
          @ionChange="segmentChanged($event)"
          :value="selectedSegment"
        >
          <ion-segment-button value="T">
            <ion-label>Tag</ion-label>
          </ion-segment-button>
          <ion-segment-button value="W">
            <ion-label>Woche</ion-label>
          </ion-segment-button>
          <ion-segment-button value="M">
            <ion-label>Monat</ion-label>
          </ion-segment-button>
          <ion-segment-button value="J">
            <ion-label>Jahr</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
      <ion-toolbar style="text-align: center">
        <ion-button
          @click="prev"
          size="small"
          style="--background: transparent; --background-activated: transparent"
        >
          <custom-icon slot="icon-only" name="chevronBackOutline"></custom-icon>
        </ion-button>

        <ion-button
          size="small"
          style="--background: transparent; --background-activated: transparent"
          @click="openSelectDateRange(true)"
        >
          <span slot="start"
            >{{ selectedRangeTitle }} <br v-if="selectedSegment === 'W'" />
            <span v-if="selectedSegment === 'W'"
              >({{ startDate.locale('de').format('DD.MM.YYYY') }} -
              {{ endDate.locale('de').format('DD.MM.YYYY') }})</span
            ></span
          >
        </ion-button>
        <ion-button
          @click="next"
          size="small"
          style="--background: transparent; --background-activated: transparent"
        >
          <custom-icon
            slot="icon-only"
            name="chevronForwardOutline"
          ></custom-icon>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" id="HomePageContent">
      <ion-refresher
        v-if="isMobile"
        slot="fixed"
        @ionRefresh="refresh($event)"
        ref="refresher"
      >
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Stunden / Überstunden</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content style="max-height: 600px">
                <p
                  v-if="hoursByEmployee === undefined"
                  style="line-height: 600px; text-align: center"
                >
                  Keine Daten
                </p>
                <Bar
                  class="chartAdminDashboard"
                  :chart-data="hoursByEmployee"
                  :chart-options="{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    plugins: {
                      legend: { display: false },
                    },
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                  }"
                />
                <br />
                <p>
                  <span
                    v-for="l in hoursByEmployee.datasets"
                    :key="l.label"
                    style="font-size: 12px"
                  >
                    <span :style="'background-color:' + l.backgroundColor"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                    >
                    &nbsp;&nbsp;{{ l.label }}&nbsp;&nbsp;
                  </span>
                </p>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Überstunden Gesamt</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <ion-list lines="none" style="background: transparent">
                  <ion-item
                    style="--background: transparent"
                    v-for="overTime in overTimeTotal"
                    :key="overTime.name"
                  >
                    <ion-label
                      >{{ overTime.name }} {{ overTime.current }}h (geplant
                      {{ overTime.planned }}h)</ion-label
                    >
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Stunden nach Projekt</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <p
                  v-if="hoursByEmployee === undefined"
                  style="line-height: 600px; text-align: center"
                >
                  Keine Daten
                </p>
                <Bar
                  class="chartAdminDashboard"
                  :chart-data="hoursByProjectByEmployee"
                  :chart-options="{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    plugins: {
                      legend: { display: false },
                    },
                    scales: { x: { stacked: true }, y: { stacked: true } },
                  }"
                />
                <br />
                <p>
                  <span
                    v-for="l in hoursByProjectByEmployee.datasets"
                    :key="l.label"
                    style="font-size: 12px"
                  >
                    <span :style="'background-color:' + l.backgroundColor"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                    >
                    &nbsp;&nbsp;{{ l.label }}&nbsp;&nbsp;
                  </span>
                </p>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Stunden pro Projekt</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <p
                  v-if="projectTimeTotal === undefined"
                  style="line-height: 600px; text-align: center"
                >
                  Keine Daten
                </p>
                <Bar
                  class="chartAdminDashboardTotal"
                  :chart-data="projectTimeTotalCurrent"
                  :chart-options="{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    plugins: {
                      legend: { display: false },
                    },
                    scales: { x: { stacked: true }, y: { stacked: true } },
                  }"
                />
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Gesamtstunden pro Projekt</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <p
                  v-if="projectTimeTotal === undefined"
                  style="line-height: 600px; text-align: center"
                >
                  Keine Daten
                </p>
                <Bar
                  class="chartAdminDashboardTotal"
                  :chart-data="projectTimeTotal"
                  :chart-options="{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    plugins: {
                      legend: { display: false },
                    },
                    scales: { x: { stacked: true }, y: { stacked: true } },
                  }"
                />
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <p
              style="
                color: var(--ion-color-medium);
                padding: 0px 15px;
                margin: 0px;
                font-size: 12px;
              "
            >
              Die Berechnung auf diesem Dashboard erfolgen immer bis zum
              aktuellen Tag. Ausgenommen Gesamtstunden pro Projekt und
              Überstunden Gesamt.
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <account-modal
      ref="accountModal"
      :presentingElement="$parent.$refs.ionRouterOutlet"
    />
    <ion-loading
      :is-open="loading"
      cssClass="my-custom-class"
      message="Bitte warten..."
    >
    </ion-loading>
    <ion-modal
      :style="
        selectedSegment === 'T'
          ? '--width: 350px; --height: 438px'
          : '--width: 350px; --height: 288px'
      "
      :is-open="selectedRangePickerisOpen"
      @didDismiss="openSelectDateRange(false)"
      :swipe-to-close="true"
      :show-backdrop="true"
    >
      <ion-content>
        <ion-toolbar style="--background: var(--ion-color-light)">
          <ion-title>{{
            selectedSegment === 'T'
              ? 'Tag auswählen'
              : selectedSegment === 'W'
              ? 'Woche auswählen'
              : selectedSegment === 'M'
              ? 'Monat auswählen'
              : 'Jahr auswählen'
          }}</ion-title>
        </ion-toolbar>
        <ion-datetime
          v-if="selectedSegment !== 'W'"
          ref="datePicker"
          @ionChange="(ev) => (datePickerValue = ev.detail.value)"
          :first-day-of-week="1"
          :value="startDate.format('YYYY-MM-DDTHH:mm:ss')"
          :show-default-buttons="false"
          :presentation="
            selectedSegment === 'T' || selectedSegment === 'W'
              ? 'date'
              : selectedSegment === 'M'
              ? 'month-year'
              : selectedSegment === 'J'
              ? 'year'
              : ''
          "
          locale="de-DE"
        >
        </ion-datetime>
        <ion-picker-internal
          v-if="selectedSegment === 'W'"
          style="--background: var(--ion-color-light)"
        >
          <ion-picker-column-internal
            class="month-column ion-color ion-color-primary ios"
            tabindex="0"
            color="primary"
            ref="datePickerKW"
            :items="calendarWeeks"
            :value="startDate.isoWeek()"
          >
          </ion-picker-column-internal>
          <ion-picker-column-internal
            class="month-column ion-color ion-color-primary ios"
            tabindex="0"
            color="primary"
            :items="years"
            ref="datePickerYear"
            :value="startDate.year()"
          >
          </ion-picker-column-internal>
        </ion-picker-internal>
        <ion-toolbar style="--background: var(--ion-color-light)">
          <ion-buttons slot="start">
            <ion-button color="danger" @click="cancleSelectDateRange()"
              >Abbrechen</ion-button
            >
          </ion-buttons>

          <ion-buttons slot="end">
            <ion-button color="primary" @click="confirmSelectDateRange()"
              >Auswählen</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
  import {
    isPlatform,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonSegment,
    IonSegmentButton,
    IonLoading,
    IonList,
    IonLabel,
    IonItem,
    IonModal,
    IonDatetime,
  } from '@ionic/vue';
  import AccountButton from '@/components/AccountButton.vue';
  import AccountModal from '@/components/AccountModal.vue';
  import { menuSharp } from 'ionicons/icons';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { Doughnut, Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    Plugin,
    BarElement,
    LinearScale,
  } from 'chart.js';
  import moment from 'moment';
  import { Data } from '@/services/dataService';
  import { Auth } from '@/services/authService';
  import { getCalendarCurrentYearAll } from '@/helpers/calendar.js';

  export default {
    components: {
      IonPage,
      IonHeader,
      IonToolbar,
      IonButton,
      IonTitle,
      IonContent,
      IonButtons,
      IonGrid,
      IonRow,
      IonCol,
      AccountButton,
      AccountModal,
      IonCard,
      IonCardHeader,
      IonCardSubtitle,
      IonCardContent,
      IonSegment,
      IonSegmentButton,
      IonLoading,
      IonList,
      IonLabel,
      IonItem,
      Bar,
      IonModal,
      IonDatetime,
    },
    data() {
      return {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
        datePickerValue: null,
        menuIcon: menuSharp,
        widthhoursByProject: 400,
        selectedSegment: 'T',
        employee: {},
        employees: [],
        projectTime: [],
        triggerUpdate: 0,
        loading: true,
        workingTimeCalendar: [],
        selectedRangePickerisOpen: false,
        palette: [
          'var(--ion-color-medium)',
          '#afca0b',
          '#53B94E',
          '#00A173',
          '#008482',
          '#006578',
          '#2F4858',

          '#83A000',
          '#3A9640',
          '#008662',
          '#007372',
          '#005D6E',
          '#2a414f',

          '#587800',
          '#217433',
          '#006C51',
          '#006262',
          '#005564',
          '#263a46',

          '#335200',
          '#0A5426',
          '#005441',
          '#005153',
          '#084D5B',
          '#21323e',

          '#b7cf23',
          '#64c060',
          '#1aaa81',
          '#1a908f',
          '#1a7486',
          '#445a69',

          '#c5d94f',
          '#83cd80',
          '#48bb9a',
          '#48a6a5',
          '#48909e',
          '#697b87',

          '#c7da54',
          '#87ce83',
          '#4dbd9d',
          '#4da9a8',
          '#4d93a1',
          '#6d7f8a',
        ],
      };
    },
    computed: {
      ...mapState('ui', ['showMenu']),
      ...mapState('user', ['user']),
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      prefersDark: function () {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      },
      isMobile: function () {
        return !isPlatform('desktop');
      },
      isiPad: function () {
        return isPlatform('ipad') || isPlatform('desktop');
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      selectedRangeTitle() {
        switch (this.selectedSegment) {
          case 'T':
            return this.startDate.format('DD.MM.YYYY');
          case 'W':
            return (
              'KW ' +
              this.startDate.isoWeek() +
              ', ' +
              this.startDate.format('YYYY')
            );
          case 'M':
            return this.startDate.lang('de').format('MMMM YYYY');
          case 'J':
            return this.startDate.format('YYYY');
          default:
            return '';
        }
      },
      segmentTitle() {
        switch (this.selectedSegment) {
          case 'T':
            var weekDay = '';
            console.log('Weekday ', moment().day());
            switch (moment().day()) {
              case 1:
                weekDay = 'Montag';
                break;
              case 2:
                weekDay = 'Dienstag';
                break;
              case 3:
                weekDay = 'Mittwoch';
                break;
              case 4:
                weekDay = 'Donnerstag';
                break;
              case 5:
                weekDay = 'Freitag';
                break;
              case 6:
                weekDay = 'Samsatag';
                break;
              case 0:
                weekDay = 'Sonntag';
                break;

              default:
                break;
            }
            return (
              'heute, ' +
              weekDay +
              ' den ' +
              moment().lang('de').format('DD. MMMM 2022')
            );
          case 'W':
            var currentDate = moment().lang('de');
            var weekStart = currentDate.clone().startOf('week');
            var weekEnd = currentDate.clone().endOf('week');
            return (
              'die aktuelle Kalenderwoche ' +
              moment(moment().toDate(), 'MM-DD-YYYY').isoWeek() +
              ' (' +
              weekStart.format('DD.MM.YYYY') +
              ' - ' +
              weekEnd.format('DD.MM.YYYY') +
              ')'
            );
          case 'M':
            return (
              ' den aktuellen Monat ' + moment().lang('de').format('MMMM yyyy')
            );
          case 'J':
            return ' das aktuelle Jahr ' + moment().lang('de').format('yyyy');
          default:
            return '';
        }
      },
      filterdProjectTime() {
        var data = [];
        var currentDate = moment().lang('de');
        this.projectTime.forEach((time) => {
          if (moment(time.date).isBetween(this.startDate, this.endDate)) {
            data.push(time);
          }
        });

        return data;
      },
      currentProjectTime() {
        var data = [];
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('year').startOf('day');
        var end = currentDate.clone().endOf('day');

        this.projectTime.forEach((time) => {
          if (moment(time.date).isBetween(start, currentDate)) {
            data.push(time);
          }
        });

        return data;
      },
      plannedProjectTime() {
        var data = [];
        var currentDate = moment().lang('de').endOf('day');

        this.projectTime.forEach((time) => {
          if (moment(time.date).isAfter(currentDate)) {
            data.push(time);
          }
        });

        return data;
      },
      hoursByEmployee() {
        var labels = [];
        var hours = [];
        var overTimes = [];
        var holidays = [];
        var flexDays = [];
        var publicHolidays = [];
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('day');
        var end = currentDate.clone().endOf('day');
        switch (this.selectedSegment) {
          case 'T':
            start = currentDate.clone().startOf('day');
            break;
          case 'W':
            start = currentDate.clone().startOf('week');
            break;
          case 'M':
            start = currentDate.clone().startOf('month');
            break;
          case 'J':
            start = currentDate.clone().startOf('year');
            break;
          default:
            break;
        }
        var days = this.workdayCount(start, end);

        this.employees.forEach((e) => {
          var date = moment().lang('de');

          var hoursPerDay = e.weeklyWorkingHours / 5;
          labels.push(e.name);
          var duration = null;
          this.filterdProjectTime
            .filter((p) =>
              p.employee === undefined ? false : p.employee._id === e._id
            )
            .forEach((time) => {
              var date = moment(time.date);
              var start = moment(time.timeFrom)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              var end = moment(time.timeTo)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              if (duration === null) {
                duration = moment.duration(end.diff(start));
              } else {
                duration.add(moment.duration(end.diff(start)));
              }
            });
          var d = duration === null ? 0 : duration._milliseconds / 3600000;
          var holiday =
            (this.workingTimeCalendar.filter(
              (p) =>
                p.type === 1 &&
                p.employee._id === e._id &&
                moment(p.date).isSameOrAfter(start) &&
                moment(p.date).isSameOrBefore(currentDate)
            ).length *
              e.weeklyWorkingHours) /
            5;
          var flexDay =
            (this.workingTimeCalendar.filter(
              (p) =>
                p.type === 2 &&
                p.employee._id === e._id &&
                moment(p.date).isSameOrAfter(start) &&
                moment(p.date).isSameOrBefore(currentDate)
            ).length *
              e.weeklyWorkingHours) /
            5;
          var publicHoliday =
            (this.workingTimeCalendar.filter(
              (p) =>
                p.type === 0 &&
                moment(p.date).isSameOrAfter(start) &&
                moment(p.date).isSameOrBefore(currentDate)
            ).length *
              e.weeklyWorkingHours) /
            5;

          if (this.selectedSegment !== 'J') {
            if (d > hoursPerDay * days) {
              hours.push(hoursPerDay * days);
              overTimes.push(d - hoursPerDay * days);
              holidays.push(holiday);
              flexDays.push(flexDay);
              publicHolidays.push(publicHoliday);
            } else {
              hours.push(d);
              overTimes.push(0);
              holidays.push(holiday);
              flexDays.push(flexDay);
              publicHolidays.push(publicHoliday);
            }
          } else {
            if (d > hoursPerDay * days) {
              hours.push(hoursPerDay * days);
              overTimes.push(d - hoursPerDay * days + e.overTimeLastYear);
              holidays.push(holiday);
              flexDays.push(flexDay);
              publicHolidays.push(publicHoliday);
            } else {
              hours.push(d);
              overTimes.push(e.overTimeLastYear);
              holidays.push(holiday);
              flexDays.push(flexDay);
              publicHolidays.push(publicHoliday);
            }
          }
        });

        return {
          labels: labels,
          datasets: [
            {
              label: 'Stunden',
              backgroundColor: this.palette[1],
              data: hours,
              borderWidth: 0,
            },
            {
              label: 'Überstunden',
              backgroundColor: this.palette[2],
              data: overTimes,
              borderWidth: 0,
            },
            {
              label: 'Urlaub',
              backgroundColor: this.palette[3],
              data: holidays,
              borderWidth: 0,
            },
            {
              label: 'Gleitzeit',
              backgroundColor: this.palette[4],
              data: flexDays,
              borderWidth: 0,
            },
            {
              label: 'Feiertage',
              backgroundColor: this.palette[5],
              data: publicHolidays,
              borderWidth: 0,
            },
          ],
        };
      },
      overTimeTotal() {
        var data = [];

        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('year').startOf('day');
        var end = currentDate.clone().endOf('day');

        this.employees.forEach((e) => {
          var hoursPerDay = e.weeklyWorkingHours / 5;

          // Current
          var duration = null;
          this.currentProjectTime
            .filter((p) =>
              p.employee === undefined ? false : p.employee._id === e._id
            )
            .forEach((time) => {
              var date = moment(time.date);
              var start = moment(time.timeFrom)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              var end = moment(time.timeTo)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              if (duration === null) {
                duration = moment.duration(end.diff(start));
              } else {
                duration.add(moment.duration(end.diff(start)));
              }
            });
          var d = duration === null ? 0 : duration._milliseconds / 3600000;
          console.log('Employee ' + e.name, this.currentProjectTime);
          var days = this.workdayCount(start, end);

          var holidays = this.workingTimeCalendar
            .filter((p) => p.type !== 0)
            .filter((p) => p.employee._id === e._id)
            .filter(
              (p) =>
                moment(p.date).isSameOrAfter(start) &&
                moment(p.date).isSameOrAfter(end)
            ).length;

          days = days - holidays;

          // Planned
          duration = null;
          var daysCount = [];
          this.plannedProjectTime
            .filter((p) => p.employee._id === e._id)
            .forEach((time) => {
              var date = moment(time.date);
              var start = moment(time.timeFrom)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              var end = moment(time.timeTo)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              if (duration === null) {
                duration = moment.duration(end.diff(start));
              } else {
                duration.add(moment.duration(end.diff(start)));
              }
              var day = start.clone().startOf('day');
              if (daysCount.findIndex((d) => d === day) === -1) {
                daysCount.push(day);
              }
            });
          var d2 = duration === null ? 0 : duration._milliseconds / 3600000;
          var days2 = daysCount.length;

          data.push({
            name: e.name,
            current:
              d > hoursPerDay * days
                ? d - hoursPerDay * days + (e.overTimeLastYear || 0)
                : e.overTimeLastYear || 0,
            planned: d2 > hoursPerDay * days2 ? d2 - hoursPerDay * days2 : 0,
          });
        });

        return data;
      },
      hoursByProjectByEmployee() {
        if (this.employees === undefined) {
          return undefined;
        }
        var labels = [];
        var datasets = [];
        var projects = this.filterdProjectTime.map((e) =>
          e.projectId === undefined ? 'Sonstiges' : e.projectId.name
        );
        projects.push('Sonstiges');
        projects = [...new Set(projects)];

        var count = 1;
        projects.forEach((p) => {
          var data = [];
          this.employees.forEach((e) => {
            let h = 0;
            var duration = null;
            this.filterdProjectTime
              .filter(
                (f) =>
                  (f.employee === undefined
                    ? false
                    : f.employee._id === e._id) &&
                  (f.projectId === undefined
                    ? 'Sonstiges'
                    : f.projectId.name) === p
              )
              .forEach((time) => {
                var date = moment(time.date);
                var start = moment(time.timeFrom)
                  .set('year', moment(date).year())
                  .set('month', moment(date).month())
                  .set('date', moment(date).day())
                  .seconds(0)
                  .millisecond(0);
                var end = moment(time.timeTo)
                  .set('year', moment(date).year())
                  .set('month', moment(date).month())
                  .set('date', moment(date).day())
                  .seconds(0)
                  .millisecond(0);
                if (duration === null) {
                  duration = moment.duration(end.diff(start));
                } else {
                  duration.add(moment.duration(end.diff(start)));
                }
              });
            h = duration === null ? 0 : duration._milliseconds / 3600000;
            data.push(h);
          });
          datasets.push({
            label: p,
            backgroundColor:
              p !== 'Sonstiges' ? this.palette[count] : this.palette[0],
            data: data,
            borderWidth: 0,
          });
          if (p !== 'Sonstiges') {
            count++;
          }
        });

        return {
          labels: this.employees.map((e) => e.name),
          datasets: datasets,
        };
      },
      projectTimeTotal() {
        var projects = this.projectTime
          .filter((e) => e.projectId !== undefined)
          .map((e) => e.projectId.name);
        var datasets = [];
        projects = [...new Set(projects)].sort();
        let data = [];
        projects.forEach((p) => {
          var duration = null;
          let h = 0;
          this.projectTime
            .filter(
              (f) =>
                (f.projectId === undefined ? 'Sonstiges' : f.projectId.name) ===
                p
            )
            .forEach((time) => {
              var date = moment(time.date);
              var start = moment(time.timeFrom)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              var end = moment(time.timeTo)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              if (duration === null) {
                duration = moment.duration(end.diff(start));
              } else {
                duration.add(moment.duration(end.diff(start)));
              }
            });
          h = duration === null ? 0 : duration._milliseconds / 3600000;
          data.push(h);
        });
        datasets.push({
          label: 'Stunden',
          backgroundColor: this.palette[1],
          data: data,
          borderWidth: 0,
        });

        return {
          labels: projects,
          datasets: datasets,
        };
      },
      projectTimeTotalCurrent() {
        var projects = this.filterdProjectTime
          .filter((e) => e.projectId !== undefined)
          .map((e) => e.projectId.name);
        var datasets = [];
        projects = [...new Set(projects)].sort();
        let data = [];
        projects.forEach((p) => {
          var duration = null;
          let h = 0;
          this.filterdProjectTime
            .filter(
              (f) =>
                (f.projectId === undefined ? 'Sonstiges' : f.projectId.name) ===
                p
            )
            .forEach((time) => {
              var date = moment(time.date);
              var start = moment(time.timeFrom)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              var end = moment(time.timeTo)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              if (duration === null) {
                duration = moment.duration(end.diff(start));
              } else {
                duration.add(moment.duration(end.diff(start)));
              }
            });
          h = duration === null ? 0 : duration._milliseconds / 3600000;
          data.push(h);
        });
        datasets.push({
          label: 'Stunden',
          backgroundColor: this.palette[1],
          data: data,
          borderWidth: 0,
        });

        return {
          labels: projects,
          datasets: datasets,
        };
      },
      years() {
        let y = [];

        for (
          let index = moment().year();
          index > moment().year() - 101;
          index--
        ) {
          y.push({
            text: index.toString(),
            value: index,
          });
        }

        return y;
      },
      calendarWeeks() {
        let w = [];

        for (let index = 1; index <= 52; index++) {
          w.push({
            text:
              'KW ' + (index < 10 ? '0' + index.toString() : index.toString()),
            value: index,
          });
        }

        return w;
      },
    },
    created() {
      ChartJS.register(
        Title,
        Tooltip,
        Legend,
        ArcElement,
        CategoryScale,
        BarElement,
        LinearScale
      );
    },
    async mounted() {
      await this.loadData();
      window.addEventListener('resize', this.resize);
      this.widthhoursByProject =
        document.getElementById('HomePageContent').clientWidth;
    },
    async updated() {
      this.widthhoursByProject =
        document.getElementById('HomePageContent').clientWidth;
    },
    methods: {
      ...mapMutations('ui', ['setShowMenu']),
      ...mapActions('models', ['loadModels']),
      ...mapActions('permission', ['loadEmployeePermissions']),
      async refresh(event) {
        await this.loadData();
        if (event.detail.complete !== undefined) {
          event.detail.complete();
        }
      },
      async loadData() {
        this.loading = true;
        try {
          await this.loadModels();
          await this.getCurrentEmployee();
          await this.loadEmployeePermissions(this.employee._id);
          await this.getAllEmployees();
          await this.getProjectTime();
          await this.getWorkingTimeCalendar();
        } catch (error) {
          console.error(error);
        }

        this.loading = false;
      },
      openAccountModal() {
        this.$refs.accountModal.setOpen(true);
      },
      async segmentChanged(e) {
        this.selectedSegment = e.detail.value;

        switch (e.detail.value) {
          case 'T':
            this.startDate = moment().locale('de').clone().startOf('day');
            this.endDate = this.startDate.clone().endOf('day');
            break;
          case 'W':
            this.startDate = moment().locale('de').startOf('week');
            this.endDate = this.startDate.clone().endOf('week');
            break;
          case 'M':
            this.startDate = moment().locale('de').startOf('month');
            this.endDate = this.startDate.clone().endOf('month');
            break;
          case 'J':
            this.startDate = moment().locale('de').startOf('year');
            this.endDate = this.startDate.clone().endOf('year');
            break;
          default:
            break;
        }
        console.log('Update Range', this.startDate, this.endDate);

        this.triggerUpdate = this.triggerUpdate + 1;
      },
      async getAllEmployees() {
        if (this.user.firstName === undefined) {
          await Auth.Instance.loadUserInfo();
        }

        this.employees = await Data.Instance.find('employee', {}, {});
      },
      async getCurrentEmployee() {
        if (this.user.firstName === undefined) {
          await Auth.Instance.loadUserInfo();
        }

        var index = this.employees.findIndex(
          (e) => e.name === this.user.firstName + ' ' + this.user.lastName
        );

        if (index !== -1) {
          this.employee = this.employees[index];
        }
      },
      async getProjectTime() {
        var e = await Data.Instance.find('projectTime', {}, {});

        if (e !== undefined) {
          this.projectTime = e;
        }
      },
      async getWorkingTimeCalendar() {
        this.workingTimeCalendar = await getCalendarCurrentYearAll();
        console.log('Working Time Calendar', this.workingTimeCalendar);
      },
      workdayCount(start, end) {
        var s = moment(start).clone().startOf('day');
        var e = moment(end).clone().endOf('day');
        var count = 0;

        for (var d = moment(s); d.isBefore(e); d.add(1, 'days')) {
          let isPublicHoliday = this.isPublicHolliday(d);
          if (d.day() !== 6 && d.day() !== 0 && !isPublicHoliday) {
            count++;
          }
        }

        return count;
      },
      isPublicHolliday(checkDay) {
        let day = checkDay.clone().startOf('day');
        return (
          this.workingTimeCalendar.findIndex(
            (e) => e.type === 0 && moment(e.date).startOf('day').isSame(day)
          ) !== -1
        );
      },
      prev() {
        switch (this.selectedSegment) {
          case 'T':
            this.startDate = this.startDate
              .clone()
              .add(-1, 'days')
              .startOf('day');
            this.endDate = this.startDate.clone().endOf('day');
            break;
          case 'W':
            this.startDate = this.startDate
              .clone()
              .add(-1, 'week')
              .startOf('week');
            this.endDate = this.startDate.clone().endOf('week');
            break;
          case 'M':
            this.startDate = this.startDate
              .clone()
              .add(-1, 'month')
              .startOf('month');
            this.endDate = this.startDate.clone().endOf('month');
            break;
          case 'J':
            this.startDate = this.startDate
              .clone()
              .add(-1, 'year')
              .startOf('year');
            this.endDate = this.endDate.clone().add(1, 'year').endOf('year');
            break;
          default:
            break;
        }
        console.log('Update Range', this.startDate, this.endDate);
      },
      next() {
        switch (this.selectedSegment) {
          case 'T':
            this.startDate = this.startDate
              .clone()
              .add(1, 'days')
              .startOf('day');
            this.endDate = this.startDate.clone().endOf('day');
            break;
          case 'W':
            this.startDate = this.startDate
              .clone()
              .add(1, 'week')
              .startOf('week');
            this.endDate = this.startDate.clone().endOf('week');
            break;
          case 'M':
            this.startDate = this.startDate
              .clone()
              .add(1, 'month')
              .startOf('month');
            this.endDate = this.startDate.clone().endOf('month');
            break;
          case 'J':
            this.startDate = this.startDate
              .clone()
              .add(1, 'year')
              .startOf('year');
            this.endDate = this.endDate.clone().endOf('year');
            break;
          default:
            break;
        }
        console.log('Update Range', this.startDate, this.endDate);
      },
      openSelectDateRange(open) {
        this.selectedRangePickerisOpen = open;
      },
      cancleSelectDateRange() {
        this.selectedRangePickerisOpen = false;
      },
      confirmSelectDateRange() {
        let date = this.startDate.clone();
        if (this.selectedSegment === 'W') {
          date = moment()
            .locale('de')
            .year(this.$refs.datePickerYear.value)
            .weeks(this.$refs.datePickerKW.value)
            .startOf('week');
        } else {
          date = moment(this.datePickerValue);
        }

        switch (this.selectedSegment) {
          case 'T':
            this.startDate = date.clone().startOf('day');
            this.endDate = this.startDate.clone().endOf('day');
            break;
          case 'W':
            this.startDate = date.clone().startOf('week');
            this.endDate = this.startDate.clone().endOf('week');
            break;
          case 'M':
            this.startDate = date.clone().startOf('month');
            this.endDate = this.startDate.clone().endOf('month');
            break;
          case 'J':
            this.startDate = date.clone().startOf('year');
            this.endDate = this.endDate.clone().endOf('year');
            break;
          default:
            break;
        }
        console.log('Update Range', this.startDate, this.endDate);

        this.selectedRangePickerisOpen = false;
      },
    },
  };
</script>

<style>
  #container {
    text-align: center;
    align-items: center;
    height: 100%;
    vertical-align: middle;
    margin: auto;
  }

  #container strong {
    font-size: 20px;
    line-height: 26px;
  }

  #container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }

  #container a {
    text-decoration: none;
  }

  .md .custom-top-toolbar {
    --background: var(--ion-color-light) !important;
  }

  .chartAdminDashboard > canvas {
    height: 400px !important;
    max-height: 400px !important;
  }

  .chartAdminDashboardTotal > canvas {
    height: 600px !important;
    max-height: 600px !important;
  }
</style>
