<template>
  <div>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button fill="clear" @click="closeModal">Fertig</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-list>
        <ion-item
          lines="full"
          style="width: 100%; text-align: center; margin: auto"
        >
          <ion-label>
            <AccountButton
              style="margin: auto"
              :text="firstLetterUsername"
              size="large"
            />
            <br />
            <p style="color: var(--ion-color-dark)">
              <strong>{{ name }}</strong
              ><br />
              <strong>{{ lastName }}</strong
              ><br />
              {{ username }}<br /><br v-if="isAdministrator" /><span
                v-if="isAdministrator"
                style="color: var(--ion-color-danger)"
                >Administrator</span
              ><br v-if="isAdministrator" /><br />
              <i v-if="!isOnline">{{ formattedIsOfflineSince }}</i>
              <i v-if="isOnline">online</i><br />&nbsp;
            </p>
          </ion-label>
        </ion-item>
        <ion-item @click="navigateTo('permissions')" detail>
          <ion-icon
            slot="start"
            :ios="lockClosedIcon"
            :md="lockClosedIcon"
          ></ion-icon>
          <ion-label> Berechtigungen </ion-label>
        </ion-item>
        <ion-item @click="navigateTo('settings')" detail>
          <ion-icon
            slot="start"
            :ios="settingsIcon"
            :md="settingsIcon"
          ></ion-icon>
          <ion-label> Einstellungen </ion-label>
        </ion-item>
        <ion-item @click="signOut">
          <ion-icon slot="start" :ios="logOutIcon" :md="logOutIcon"></ion-icon>
          <ion-label> Abmelden </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    modalController,
  } from '@ionic/vue';
  import {
    logOutOutline,
    settingsOutline,
    lockClosedOutline,
  } from 'ionicons/icons';
  import AccountButton from '@/components/AccountButton.vue';
  import { Auth } from '@/services/authService';
  import { AuthActions } from 'baas-ionic-appauth';
  import { mapState } from 'vuex';
  import { User } from '../models/user';
  import AccountModalSettings from '@/components/AccountModalSettings.vue';
  import AccountModalPermissions from '@/components/AccountModalPermissions.vue';
  import moment from 'moment';

  export default defineComponent({
    name: 'AccountModal',
    components: {
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonContent,
      AccountButton,
      IonIcon,
      IonList,
      IonItem,
      IonLabel,
    },
    data() {
      return {
        isOpen: false,
        showSettings: false,
        logOutIcon: logOutOutline,
        settingsIcon: settingsOutline,
        lockClosedIcon: lockClosedOutline,
      };
    },
    props: {
      presentingElement: {
        type: Element,
      },
    },
    computed: {
      ...mapState('ui', ['isOnline']),
      ...mapState('ui', ['isOfflineSince']),
      ...mapState('user', ['user']),
      formattedIsOfflineSince() {
        const today = new Date();
        const yesterday = new Date(
          new Date().setDate(new Date().getDate() - 1)
        );

        if (today.toDateString() === this.isOfflineSince.toDateString()) {
          return (
            'zul. online heute um ' +
            moment(this.isOfflineSince).format('HH:mm')
          );
        } else if (
          yesterday.toDateString() === this.isOfflineSince.toDateString()
        ) {
          return (
            'zul. online gestern um ' +
            moment(this.isOfflineSince).format('HH:mm')
          );
        } else {
          return (
            'zul. online am ' +
            moment(this.isOfflineSince).format('DD.MM.yyyy') +
            ' um ' +
            moment(this.isOfflineSince).format('HH:mm')
          );
        }
      },
      username() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.username !== undefined && this.user.username !== null) {
          return this.user.username;
        }

        return '';
      },
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      name() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.firstName !== undefined && this.user.lastName !== null) {
          return this.user.firstName;
        }

        return '';
      },

      lastName() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.firstName !== undefined && this.user.lastName !== null) {
          return this.user.lastName;
        }

        return '';
      },
      firstLetterUsername() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.firstName !== undefined && this.user.lastName !== null) {
          return (
            this.user.firstName.substring(0, 1).toUpperCase() +
            this.user.lastName.substring(0, 1).toUpperCase()
          );
        } else if (
          this.user.username !== undefined &&
          this.user.username !== null
        ) {
          return this.user.username.substring(0, 1).toUpperCase();
        }

        return '';
      },
    },
    methods: {
      async signOut() {
        await this.closeModal();
        await Auth.Instance.signOut();
      },
      async closeModal() {
        await modalController.dismiss();
      },
      navigateTo(page) {
        const nav = document.querySelector('#modal-nav');

        if (nav === null) {
          return;
        }

        switch (page) {
          case 'settings':
            nav.push(AccountModalSettings);
            break;
          case 'permissions':
            nav.push(AccountModalPermissions);
            break;
          default:
            break;
        }
      },
    },
  });
</script>

<style scoped>
  ion-toolbar {
    --background: var(--ion-background-color, #fff);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-list {
    --ion-item-background: transparent;
  }
</style>
