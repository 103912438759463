<template>
  <ion-app class="app">
    <ion-split-pane
      :when="isMenuOpen"
      ref="splitpanel"
      v-if="!useTabs && !noMenu"
      content-id="main-content"
    >
      <ion-menu ref="menu" content-id="main-content" type="overlay">
        <ion-header v-if="isiPad" :translucent="true">
          <ion-toolbar color="light">
            <ion-buttons slot="start">
              <ion-button
                @click="setShowMenu(!showMenu)"
                v-if="isiPad"
                fill="clear"
              >
                <svg version="1.1" width="23.0273" height="17.998">
                  <g>
                    <rect
                      height="17.998"
                      opacity="0"
                      width="23.0273"
                      x="0"
                      y="0"
                    />
                    <path
                      fill="var(--ion-color-primary)"
                      d="M3.06641 17.998L19.9609 17.998Q21.5039 17.998 22.2656 17.2412Q23.0273 16.4844 23.0273 14.9707L23.0273 3.04688Q23.0273 1.5332 22.2656 0.776367Q21.5039 0.0195312 19.9609 0.0195312L3.06641 0.0195312Q1.5332 0.0195312 0.766602 0.776367Q0 1.5332 0 3.04688L0 14.9707Q0 16.4844 0.766602 17.2412Q1.5332 17.998 3.06641 17.998ZM3.08594 16.4258Q2.35352 16.4258 1.96289 16.04Q1.57227 15.6543 1.57227 14.8926L1.57227 3.125Q1.57227 2.36328 1.96289 1.97754Q2.35352 1.5918 3.08594 1.5918L19.9414 1.5918Q20.6641 1.5918 21.0596 1.97754Q21.4551 2.36328 21.4551 3.125L21.4551 14.8926Q21.4551 15.6543 21.0596 16.04Q20.6641 16.4258 19.9414 16.4258ZM7.44141 16.7285L8.97461 16.7285L8.97461 1.29883L7.44141 1.29883ZM5.56641 5.21484Q5.78125 5.21484 5.95215 5.04395Q6.12305 4.87305 6.12305 4.66797Q6.12305 4.44336 5.95215 4.28223Q5.78125 4.12109 5.56641 4.12109L3.4668 4.12109Q3.25195 4.12109 3.08594 4.28223Q2.91992 4.44336 2.91992 4.66797Q2.91992 4.87305 3.08594 5.04395Q3.25195 5.21484 3.4668 5.21484ZM5.56641 7.74414Q5.78125 7.74414 5.95215 7.57812Q6.12305 7.41211 6.12305 7.1875Q6.12305 6.97266 5.95215 6.81152Q5.78125 6.65039 5.56641 6.65039L3.4668 6.65039Q3.25195 6.65039 3.08594 6.81152Q2.91992 6.97266 2.91992 7.1875Q2.91992 7.41211 3.08594 7.57812Q3.25195 7.74414 3.4668 7.74414ZM5.56641 10.2637Q5.78125 10.2637 5.95215 10.1025Q6.12305 9.94141 6.12305 9.72656Q6.12305 9.50195 5.95215 9.34082Q5.78125 9.17969 5.56641 9.17969L3.4668 9.17969Q3.25195 9.17969 3.08594 9.34082Q2.91992 9.50195 2.91992 9.72656Q2.91992 9.94141 3.08594 10.1025Q3.25195 10.2637 3.4668 10.2637Z"
                    />
                  </g>
                </svg>
              </ion-button>
            </ion-buttons>
            <ion-title>BauVersum</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
          <ion-header collapse="condense">
            <ion-toolbar>
              <ion-title size="large">BauVersum</ion-title>
            </ion-toolbar>
          </ion-header>
          <ion-list
            v-if="isiPad"
            :inset="false"
            style="margin-top: 0px; padding-bottom: 0px"
          >
            <ion-item @click="openAccountModal" lines="none" detail="false">
              <div slot="start" style="margin-top: 10px; margin-bottom: 10px">
                <account-button size="middle" />
              </div>
              <ion-label>
                <strong>{{ firstName }}<br />{{ lastName }}</strong
                ><br />
                <i v-if="!isOnline">{{ formattedIsOfflineSince }}</i>
                <i v-if="isOnline">online</i>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-list
            style="background-color: transparent; cursor: pointer"
            :inset="isiPad ? true : false"
          >
            <ion-item
              v-for="(p, i) in appPages"
              :key="i"
              @click="selectedIndex = i"
              router-direction="root"
              :router-link="p.url"
              lines="none"
              detail="false"
              :class="{
                'menu-item': true,
                selected: selectedIndex === i,
                'ion-activatable': true,
                'ripple-parent': true,
              }"
            >
              <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-list>
          <p
            v-if="currentModels.filter((e) => e.isMore === true).length > 0"
            style="padding-left: 30px; font-weight: bolder"
          >
            Mehr
          </p>
          <ion-list
            style="background-color: transparent; cursor: pointer"
            :inset="isiPad ? true : false"
          >
            <ion-item
              v-for="(p, i) in currentModels.filter((e) => e.isMore === true)"
              :key="i"
              @click="selectedIndex = appPages.length + i"
              router-direction="root"
              :router-link="'/models/' + p.name + '/list'"
              lines="none"
              detail="false"
              :class="{
                'menu-item': true,
                selected: selectedIndex === appPages.length + i,
                'ion-activatable': true,
                'ripple-parent': true,
              }"
            >
              <custom-icon slot="start" :name="p.icon"></custom-icon>
              <ion-label>{{ getListTitle(p) }}</ion-label>
            </ion-item>
            <ion-item
              v-if="isAdministrator"
              @click="selectedIndex = 999"
              router-direction="root"
              :router-link="'/adminDashboard'"
              lines="none"
              detail="false"
              :class="{
                'menu-item': true,
                selected: selectedIndex === 999,
                'ion-activatable': true,
                'ripple-parent': true,
              }"
            >
              <custom-icon slot="start" name="barChartOutline"></custom-icon>
              <ion-label>Admin Dashboard</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>

    <ion-tabs v-if="useTabs && !noMenu">
      <ion-router-outlet ref="tabsOutput"></ion-router-outlet>
      <ion-tab-bar v-if="!noButtonTabs" slot="bottom">
        <ion-tab-button
          @click="
            moreModalIsOpen = false;
            editTabsAndMoreModalIsOpen = false;
          "
          v-for="(p, i) in appPages.slice(0, 5)"
          :key="i"
          :tab="'tab-' + i"
          :href="p.url"
          :selected="isSelectedIndex === i"
        >
          <ion-icon :ios="p.iosIcon" :md="p.mdIcon" />
          <ion-label>{{ p.title }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          @click="moreModalIsOpen = !moreModalIsOpen"
          :selected="isSelectedIndex === 99"
          v-if="currentModels.filter((e) => e.isMore === true).length > 0"
        >
          <ion-icon :ios="moreIcon" :md="moreIcon" />
          <ion-label>Mehr</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <ion-modal
      :swipe-to-close="true"
      :is-open="moreModalIsOpen"
      :breakpoints="[0, 0.5, 1]"
      :initialBreakpoint="0.5"
      @didDismiss="moreModalIsOpen = false"
      :style="
        is_iOS
          ? 'bottom: 84px; border-bottom: 0.55px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.2))));'
          : 'bottom: 52px'
      "
    >
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button v-if="false" @click="openEditTabsAndMore" fill="clear"
              >Neu Anordnen</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content :fullscreen="true">
        <ion-grid>
          <ion-row>
            <ion-col
              @click="openListPageFromMoreButton(model)"
              v-for="model in currentModels.filter((e) => e.isMore === true)"
              :key="model.name"
              size="3"
              style="text-align: center"
            >
              <custom-icon
                style="
                  padding: 16px;
                  background-color: var(--ion-color-primary);
                  border-radius: 8px;
                  color: var(--ion-background-color, #fff);
                "
                size="large"
                :name="model.icon"
              />
              <p style="font-size: 10px; text-align: center">
                {{ getListTitle(model) }}
              </p>
            </ion-col>
            <ion-col
              @click="openListPageFromMoreButton('adminDashboard')"
              size="3"
              style="text-align: center"
              v-if="isAdministrator"
            >
              <custom-icon
                style="
                  padding: 16px;
                  background-color: var(--ion-color-primary);
                  border-radius: 8px;
                  color: var(--ion-background-color, #fff);
                "
                size="large"
                name="barChartOutline"
              />
              <p style="font-size: 10px; text-align: center">Admin Dashboard</p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-modal>
    <ion-modal
      :swipe-to-close="false"
      :breakpoints="[0, 1]"
      :initialBreakpoint="1"
      :is-open="editTabsAndMoreModalIsOpen"
      @didDismiss="editTabsAndMoreModalIsOpen = false"
    >
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              size="small"
              @click="editTabsAndMoreModalIsOpen = false"
              fill="clear"
              >Abbrechen</ion-button
            >
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button
              size="small"
              @click="editTabsAndMoreModalIsOpen = false"
              fill="clear"
              >Sichern</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar>
          <ion-title>Navigation bearbeiten</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content :fullscreen="true">
        <ion-reorder-group
          @ionItemReorder="onItemReorder($event)"
          disabled="false"
        >
          <ion-item
            v-for="model in currentModels.filter((e) =>
              e === undefined ? false : e.isMore === false
            )"
            :key="model.name"
          >
            <ion-icon
              size="small"
              style="
                padding: 6px;
                background-color: var(--ion-color-primary);
                border-radius: 3px;
                color: var(--ion-background-color, #fff);
              "
              slot="start"
              :ios="bugOutline"
              :md="bugOutline"
            />
            <ion-label>
              {{ getListTitle(model) }}
            </ion-label>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
          <ion-list-header>Mehr</ion-list-header>
          <ion-item
            v-for="model in currentModels.filter((e) =>
              e === undefined ? false : e.isMore === true
            )"
            :key="model.name"
          >
            <ion-icon
              size="small"
              style="
                padding: 6px;
                background-color: var(--ion-color-primary);
                border-radius: 3px;
                color: var(--ion-background-color, #fff);
              "
              slot="start"
              :ios="bugOutline"
              :md="bugOutline"
            />
            <ion-label>
              {{ getListTitle(model) }}
            </ion-label>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </ion-content>
    </ion-modal>
    <account-modal
      ref="accountModal"
      :presentingElement="$refs.ionRouterOutlet"
    />
    <ion-router-outlet v-if="noMenu"></ion-router-outlet>
  </ion-app>
</template>

<script>
  import {
    isPlatform,
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonTabs,
    IonTabBar,
    IonButton,
    IonTabButton,
    IonModal,
    IonButtons,
    IonHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonReorderGroup,
    IonReorder,
    IonToolbar,
    IonTitle,
    toastController,
  } from '@ionic/vue';
  import {
    homeOutline,
    ellipsisHorizontalOutline,
    bugOutline,
    bookOutline,
    paperPlaneOutline,
    shapesOutline,
    timeOutline,
    checkmarkCircle,
    alertCircle,
    businessOutline,
  } from 'ionicons/icons';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { Network } from '@capacitor/network';
  import AccountButton from '@/components/AccountButton.vue';
  import AccountModal from '@/components/AccountModal.vue';
  import moment from 'moment';

  export default {
    name: 'App',
    components: {
      IonApp,
      IonContent,
      IonHeader,
      IonIcon,
      IonItem,
      IonLabel,
      IonList,
      IonListHeader,
      IonMenu,
      //IonMenuToggle,
      IonRouterOutlet,
      IonSplitPane,
      IonTabs,
      IonTabBar,
      IonTabButton,
      IonModal,
      IonButtons,
      IonGrid,
      IonRow,
      IonCol,
      IonReorderGroup,
      IonReorder,
      IonButton,
      IonToolbar,
      IonTitle,
      AccountButton,
      AccountModal,
    },
    data() {
      return {
        selectedIndex: 0,
        moreModalIsOpen: false,
        editTabsAndMoreModalIsOpen: false,
        moreIcon: ellipsisHorizontalOutline,
        bugOutline: bugOutline,
        businessOutline: businessOutline,
        refreshing: false,
        registration: null,
        updateExists: false,
      };
    },
    computed: {
      ...mapState('ui', ['isOnline']),
      ...mapState('user', ['user']),
      ...mapState('ui', ['showMenu']),
      ...mapState('models', ['models']),
      ...mapState('ui', ['isOfflineSince']),
      ...mapState('permission', ['employeePermission', 'permissions']),
      isBackofficeUser() {
        var isBackoffice = false;

        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.roles === null || this.user.roles === undefined) {
          return false;
        }

        if (this.user.roles.includes('Backoffice')) {
          return true;
        }

        return isBackoffice;
      },
      appPages() {
        let apps = [
          {
            title: 'Dashboard',
            url: '/home',
            iosIcon: homeOutline,
            mdIcon: homeOutline,
          },
        ];

        if (this.isAdministrator) {
          return [
            {
              title: 'Dashboard',
              url: '/home',
              iosIcon: homeOutline,
              mdIcon: homeOutline,
            },
            {
              title: 'Bautagebuch',
              url: '/models/buildingDiary/list',
              iosIcon: bookOutline,
              mdIcon: bookOutline,
            },
            {
              title: 'Zeiterfassung',
              url: '/models/projectTime/list',
              iosIcon: timeOutline,
              mdIcon: timeOutline,
            },
            {
              title: 'Projekte',
              url: '/models/project/list',
              iosIcon: paperPlaneOutline,
              mdIcon: paperPlaneOutline,
            },
          ];
        } else {
          return [
            {
              title: 'Dashboard',
              url: '/home',
              iosIcon: homeOutline,
              mdIcon: homeOutline,
            },
            {
              title: 'Bautagebuch',
              url: '/models/buildingDiary/list',
              iosIcon: bookOutline,
              mdIcon: bookOutline,
            },
            {
              title: 'Zeiterfassung',
              url: '/models/projectTime/list',
              iosIcon: timeOutline,
              mdIcon: timeOutline,
            },
            {
              title: 'Firmen',
              url: '/models/company/list',
              iosIcon: businessOutline,
              mdIcon: businessOutline,
            },
          ];
        }
      },
      formattedIsOfflineSince() {
        const today = new Date();
        const yesterday = new Date(
          new Date().setDate(new Date().getDate() - 1)
        );

        if (today.toDateString() === this.isOfflineSince.toDateString()) {
          return (
            'zul. online heute um ' +
            moment(this.isOfflineSince).format('HH:mm')
          );
        } else if (
          yesterday.toDateString() === this.isOfflineSince.toDateString()
        ) {
          return (
            'zul. online gestern um ' +
            moment(this.isOfflineSince).format('HH:mm')
          );
        } else {
          return (
            'zul. online am ' +
            moment(this.isOfflineSince).format('DD.MM.yyyy') +
            ' um ' +
            moment(this.isOfflineSince).format('HH:mm')
          );
        }
      },
      isMenuOpen() {
        return this.showMenu === undefined ? true : this.showMenu;
      },
      is_iOS() {
        return isPlatform('ios');
      },
      name() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.firstName !== undefined && this.user.lastName !== null) {
          return this.user.firstName + ' ' + this.user.lastName;
        }

        return '';
      },
      firstName() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.firstName !== undefined) {
          return this.user.firstName;
        }

        return '';
      },
      lastName() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.lastName !== undefined) {
          return this.user.lastName;
        }

        return '';
      },
      username() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.username !== undefined && this.user.username !== null) {
          return this.user.username;
        }

        return '';
      },
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      noMenu() {
        return this.$route.meta.noMenu === undefined
          ? false
          : this.$route.meta.noMenu === true
          ? true
          : false;
      },
      noButtonTabs() {
        return this.$route.meta.noButtonTabs === undefined
          ? false
          : this.$route.meta.noButtonTabs === true
          ? true
          : false;
      },
      prefersDark() {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      },
      isiPad: function () {
        return isPlatform('ipad') || isPlatform('desktop');
      },
      useTabs() {
        return window.navigator.userAgent.toLowerCase().includes('windows')
          ? false
          : isPlatform('mobile') && !isPlatform('ipad');
      },
      isSelectedIndex() {
        return this.moreModalIsOpen === false
          ? this.appPages.findIndex(
              (page) =>
                page.url.toLowerCase() === this.$route.path.toLowerCase()
            )
          : 99;
      },
      currentModels() {
        var currentModels = [];
        var count = 0;
        this.models.forEach((model) => {
          count++;
          if (
            this.appPages
              .map((e) => e.url.replace('/models/', '').replace('/list', ''))
              .includes(model.name)
          ) {
            model.isMore = false;
          } else {
            var isChild = false;
            this.models.forEach((m) => {
              if (m.childModels !== undefined) {
                if (m.childModels.map((e) => e.name).includes(model.name)) {
                  isChild = true;
                }
              }
            });
            if (isChild) {
              model.isMore = false;
            } else {
              model.isMore = true;
            }
          }

          if (model.type === 'File' && model.isMore === true) {
            model.isMore = false;
          }

          model.position = count;
          if (
            model.name === 'employee' ||
            model.name === 'workingTimeCalendar' ||
            model.name === 'projectAccounting' ||
            model.name === 'object' ||
            model.name === 'project'
          ) {
            if (this.isAdministrator) {
              currentModels.push(model);
            } else {
              if (this.isBackofficeUser && model.name === 'projectAccounting') {
                currentModels.push(model);
              }
            }
          } else {
            currentModels.push(model);
          }
        });
        return currentModels;
      },
    },
    async mounted() {
      const currentStatus = await Network.getStatus();
      this.setIsOnline(currentStatus.connected);
      await Network.addListener('networkStatusChange', (status) => {
        this.setIsOnline(status.connected);
      });
    },
    async unmounted() {
      await Network.removeAllListeners();
    },
    methods: {
      ...mapMutations('ui', ['setIsOnline']),
      ...mapMutations('ui', ['setShowMenu']),
      openAccountModal() {
        this.$refs.accountModal.setOpen(true);
      },
      openListPageFromMoreButton(model) {
        this.moreModalIsOpen = false;
        if (model === 'adminDashboard') {
          this.$router.push({ path: '/adminDashboard' });
        } else {
          this.$router.push({ path: '/models/' + model.name + '/list' });
        }
      },
      openEditTabsAndMore() {
        this.moreModalIsOpen = false;
        this.editTabsAndMoreModalIsOpen = true;
      },
      onItemReorder({ detail }) {
        this.currentModels.splice(
          detail.to,
          0,
          this.currentModels.splice(detail.from, 1)[0]
        );
        detail.complete(true);
      },
      getListTitle(model) {
        if (model.listLabel === undefined || model.listLabel === null) {
          return model.name;
        }

        if (model.listLabel.length === 0) {
          return model.name;
        }

        if (model.listLabel.length === 1) {
          return model.listLabel[0].text;
        }

        if (
          model.listLabel.findIndex((e) => e.language === this.language) !== -1
        ) {
          return model.listLabel[
            model.listLabel.findIndex((e) => e.language === this.language)
          ].text;
        } else {
          if (model.listLabel.findIndex((e) => e.language === 'en') !== -1) {
            return model.listLabel[
              model.listLabel.findIndex((e) => e.language === 'en')
            ].text;
          } else {
            return model.name;
          }
        }
      },
    },
    watch: {
      async isOnline(isOnline) {
        // Show Toast on Online / Offline Change
        let toast = null;
        if (isOnline) {
          toast = await toastController.create({
            color: 'dark',
            icon: checkmarkCircle,
            cssClass: 'network-status-toast-online',
            message: 'Online',
            duration: 2000,
            showCloseButton: true,
          });
          await toast.present();
        } else {
          toast = await toastController.create({
            color: 'dark',
            icon: alertCircle,
            cssClass: 'network-status-toast-offline',
            message: 'Offline',
            duration: 2000,
            showCloseButton: true,
          });
          await toast.present();
        }
      },
    },
  };
</script>

<style>
  @import './theme/variables.css';
  .md .menu-item {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-right: 20px;
    --background: transparent;
    margin-bottom: 5px;
  }

  .md .menu-item.selected {
    --background: var(--ion-color-primary);
  }

  .md .menu-item.selected > ion-label {
    --color: var(--ion-color-primary-contrast) !important;
  }

  .md .menu-item.selected > .icon {
    color: var(--ion-color-primary-contrast);
  }

  .ios ion-menu {
    --ion-background-color: #f2f2f7;
    --ion-item-background: transparent;
  }

  .ios ion-menu ion-item {
    --border-width: 0 0 0 0;
    --border-radius: 10px;
  }

  ion-menu .list-ios.list-inset ion-item {
    --border-width: 0 0 0 0;
  }

  .ios ion-menu ion-item .icon,
  .ios ion-menu ion-item ion-icon {
    color: var(--ion-color-primary);
  }

  .ios .menu-item.selected {
    --background: #e0dee4;
  }

  @media (prefers-color-scheme: dark) {
    .ios ion-menu {
      --ion-background-color: #1c1c1e !important;
      --ion-item-background: transparent;
    }

    .ios .menu-item.selected {
      --background: #39383e;
    }
  }

  ion-note {
    display: inline-block;
    font-size: 16px;

    color: var(--ion-color-medium-shade);
  }

  ion-item.selected {
    --color: var(--ion-color-primary);
  }

  ion-toolbar {
    --background: var(--ion-background-color, #fff);
    --border-color: transparent;
    --box-shadow: 0px;
  }
  .desktop-menu {
    --background: #1e1e1e;
    --color: #fff;
  }

  .desktop-menu .list-md {
    background: #1e1e1e;
  }

  .desktop-menu ion-item {
    --background: #1e1e1e;
    --color: #fff !important;
  }

  .desktop-menu ion-item .sc-ion-label-md-h {
    --background: #1e1e1e;
    color: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .desktop-menu {
      --background: #1e1e1e;
    }
  }
</style>
