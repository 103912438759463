import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { isPlatform } from '@ionic/vue';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Media } from '@baas-capacitor/media';

export default async () => {
  const photo = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
    source: CameraSource.Prompt,
    allowEditing: true,
    quality: 100,
    promptLabelHeader: 'Fotos',
    promptLabelPhoto: 'Fotos auswählen',
    promptLabelPicture: 'Fotos aufnehmen',
    promptLabelCancel: 'abbrechen',
  });

  if (isPlatform('ios')) {
    const convertToBase64 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    const base64Data = (await convertToBase64(
      await (await fetch(photo.webPath || '')).blob()
    )) as string;
    const fileName = 'bauversum_' + new Date().getTime() + '.jpeg';
    const saveFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents,
    });
    const photoAlbumName = 'BauVersum';
    let photoAlbumId = '';

    let photoAlbums = (await Media.getPhotoAlbums()).albums;
    if (photoAlbums.findIndex((e) => e.name === photoAlbumName) === -1) {
      await Media.createPhotoAlbum({ name: photoAlbumName });
      photoAlbums = (await Media.getPhotoAlbums()).albums;
    }
    photoAlbumId =
      photoAlbums[photoAlbums.findIndex((e) => e.name === photoAlbumName)]
        .identifier || '';
    await Media.savePhoto({ path: saveFile.uri, album: photoAlbumId });
  }

  return photo;
};
