import moment from 'moment';
import { Data } from './../services/dataService';

const loadProjectTime = async (projectAccounting) => {
  let start = moment(projectAccounting.dateFrom).startOf('day');
  let end = moment(projectAccounting.dateTo).endOf('day');

  return await Data.Instance.find(
    'projectTime',
    {
      $and: [
        {
          'projectId._id': { $eq: { $oid: projectAccounting.projectId._id } },
        },
        {
          date: { $gte: { $date: start }, $lt: { $date: end } },
        },
      ],
    },
    {}
  );
};

const syncProjectAccountingTime = async (projectAccounting) => {
  // Delete all Project
  await Data.Instance.delete(
    'projectAccountingTime',
    { projectAccountingId: { $eq: { $oid: projectAccounting._id } } },
    {}
  );

  // Load Project Time
  let projectTime = await loadProjectTime(projectAccounting);

  // Insert Project Accounting Time
  let p = [];
  projectTime.forEach((element) => {
    var start = moment(element.timeFrom)
      .set('year', moment(element.date).year())
      .set('month', moment(element.date).month())
      .set('date', moment(element.date).day())
      .seconds(0)
      .milliseconds(0);
    var end = moment(element.timeTo)
      .set('year', moment(element.date).year())
      .set('month', moment(element.date).month())
      .set('date', moment(element.date).day())
      .seconds(0)
      .milliseconds(0);
    var duration = moment.duration(end.diff(start));
    p.push({
      projectAccountingId: { $oid: projectAccounting._id },
      date: { $date: element.date },
      employee: { $oid: element.employee._id },
      drivingDistance: element.drivingDistance,
      hours: duration.asMilliseconds() / 3600000,
      description: element.description,
    });
  });
  var insertResult = await Data.Instance.insert('projectAccountingTime', p, {});

  return {
    projectTime: projectTime,
    projectAccountingTime: insertResult.insertedObjects,
  };
};

export { syncProjectAccountingTime, loadProjectTime };
