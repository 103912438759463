<template>
  <ion-item :lines="lines" :detail="false">
    <ion-label>
      <span style="font-weight: bold">{{ displayTitle }}</span>
      <span style="color: var(--ion-color-danger)" v-if="required">*</span>
    </ion-label>
    <input
      class="custom-text-input"
      :placeholder="placeholder"
      style="text-align: right"
      @change="setValue"
      :readonly="readonly"
      :value="value"
      :type="type === 'Double' ? 'number' : type"
      :step="type === 'Double' ? '0.25' : undefined"
    />
  </ion-item>
</template>

<script>
  import { IonItem, IonLabel } from '@ionic/vue';

  export default {
    components: {
      IonItem,
      IonLabel,
    },
    props: {
      name: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      modelValue: {
        type: String,
        default: '',
      },
      label: {
        type: Array,
        default: () => [],
      },
      required: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'none',
      },
      lines: {
        type: String,
        default: 'none',
      },
    },
    data() {
      return {
        value: '',
      };
    },
    computed: {
      isMobile: function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      displayTitle() {
        let title = this.name;

        if (this.label !== undefined) {
          if (
            this.label.findIndex((e) => e.language === this.language) !== -1
          ) {
            return this.label[
              this.label.findIndex((e) => e.language === this.language)
            ].text;
          }
        }

        return title;
      },
    },
    async mounted() {
      this.value = this.modelValue;
    },
    methods: {
      async setValue(event) {
        this.value = event.target.value;
        await this.$emit('update:modelValue', event.target.value);
      },
    },
    watch: {
      modelValue(n) {
        this.value = n;
      },
    },
  };
</script>

<style scoped>
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-toolbar {
    --background: var(--ion-color-light);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  .custom-text-input {
    border: 0px solid black;
  }
  .custom-text-input:focus {
    border: 0px solid black;
    outline: none;
  }
</style>
