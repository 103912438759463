<template>
  <ion-item
    :lines="lines"
    :detail="false"
    style="
      --background-focused: transparent;
      --full-highlight-height: 0px;
      --inset-highlight-height: 0px;
    "
  >
    <ion-label>
      <span style="font-weight: bold">{{ displayTitle }}</span>
      <span style="color: var(--ion-color-danger)" v-if="required">*</span>
    </ion-label>
    <input
      v-if="!isMobile"
      type="time"
      class="custom-time-input"
      placeholder="--:--"
      :value="displayText"
      :step="minuteValues !== '0, 15, 30, 45' ? 60 : 900"
      @keyup="changeInput"
      @change="changeInput"
    />
    <ion-button
      v-if="!isMobile"
      @click="openModal"
      style="--background: transparent"
    >
      <custom-icon name="timeOutline"></custom-icon>
    </ion-button>

    <ion-button
      v-if="isMobile"
      @click="openModal"
      style="font-size: 17px"
      color="light"
    >
      {{ displayText }}
    </ion-button>
    <ion-modal
      :is-open="modalIsOpen"
      @didDismiss="closeModal"
      :show-backdrop="true"
      :presenting-element="presentingElementValue"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              v-if="isMobile"
              @click="closeModal"
              fill="clear"
              color="primary"
              >Abbrechen</ion-button
            >
          </ion-buttons>
          <ion-title>{{ displayTitle }}</ion-title>
          <ion-buttons slot="end">
            <ion-button
              v-if="isMobile"
              @click="saveModal"
              fill="clear"
              color="primary"
              >Anwenden</ion-button
            >
            <ion-button v-if="!isMobile" fill="clear" @click="closeModal"
              ><custom-icon slot="icon-only" name="closeOutline"></custom-icon
            ></ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-datetime
          :value="value"
          presentation="time"
          :minuteValues="minuteValues"
          size="cover"
          @ionChange="(ev) => (value = ev.detail.value)"
        >
        </ion-datetime>
      </ion-content>
      <ion-footer v-if="!isMobile">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button fill="clear" @click="closeModal">Abbrechen</ion-button>
            <ion-button color="primary" @click="saveModal" fill="clear"
              >Anwenden</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </ion-modal>
  </ion-item>
</template>

<script>
  import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonFooter,
    IonDatetime,
    IonTitle,
  } from '@ionic/vue';
  import moment from 'moment';

  export default {
    components: {
      IonModal,
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonContent,
      IonItem,
      IonLabel,
      IonFooter,
      IonDatetime,
      IonTitle,
    },
    props: {
      name: {
        type: String,
        default: '',
      },
      modelValue: {
        type: Date,
        default: new Date(),
      },
      label: {
        type: Array,
        default: () => [],
      },
      format: {
        type: String,
        default: 'HH:mm',
      },
      presentingElement: {
        type: HTMLElement,
        default: undefined,
      },
      required: {
        type: Boolean,
        default: false,
      },
      lines: {
        type: String,
        default: 'none',
      },
      minuteValues: {
        type: String,
        default: undefined,
      },
    },
    data() {
      return {
        modalIsOpen: false,
        value: new Date(),
        presentingElementValue: undefined,
      };
    },
    computed: {
      isMobile: function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      displayTitle() {
        let title = this.name;

        if (this.label !== undefined) {
          if (
            this.label.findIndex((e) => e.language === this.language) !== -1
          ) {
            return this.label[
              this.label.findIndex((e) => e.language === this.language)
            ].text;
          }
        }

        return title;
      },
      displayText() {
        try {
          return moment(this.modelValue).format(this.format);
        } catch {
          return '--:--';
        }
      },
    },
    async mounted() {
      this.presentingElementValue = this.presentingElement;
      if (this.modelValue !== undefined) {
        this.value = moment.utc(this.modelValue).local().toISOString(true);
      } else {
        this.value = new Date().toISOString();
      }
    },
    methods: {
      async openModal() {
        if (this.modelValue !== undefined) {
          this.value = moment.utc(this.modelValue).local().toISOString(true);
        } else {
          this.value = moment().toISOString();
        }
        this.modalIsOpen = true;
      },
      async closeModal() {
        this.modalIsOpen = false;
      },
      async saveModal() {
        await this.setValue();
        this.modalIsOpen = false;
      },
      async setValue() {
        await this.$emit('update:modelValue', this.value);
      },
      async changeInput(e) {
        let dateTime = moment()
          .hour(
            e.target.value.split(':')[0] === undefined
              ? 0
              : e.target.value.split(':')[0]
          )
          .minute(
            e.target.value.split(':')[1] === undefined
              ? 0
              : e.target.value.split(':')[1]
          )
          .second(0)
          .milliseconds(0);

        this.value = moment.utc(dateTime).local().toISOString(true);
        await this.setValue();
      },
    },
    watch: {
      modelValue(n) {
        if (
          JSON.parse(JSON.stringify(this.value)) !==
          JSON.parse(JSON.stringify(n))
        ) {
          try {
            if (n !== undefined) {
              this.value = moment.utc(n).local().toISOString(true);
            } else {
              this.value = new Date().toISOString();
            }
          } catch {
            this.value = new Date().toISOString();
          }
        }

        if (this.value === undefined) {
          this.value = new Date().toISOString();
        }
      },
      presentingElement(n) {
        this.presentingElementValue = n;
      },
    },
  };
</script>

<style scoped>
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-toolbar {
    --background: var(--ion-color-light);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-img > img {
    border-radius: 10px;
  }

  ion-datetime {
    --background: transparent;
  }

  .custom-time-input {
    border: 0px solid transparent;
    width: 55px;
    background-color: transparent;
    font-weight: 500;
    text-align: right;
  }

  .custom-time-input:focus {
    outline: none;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    width: 0px;
    display: none;
  }
</style>
