<template>
  <div
    :title="displayName"
    :style="noPadding ? 'padding: 0px' : ''"
    :class="{
      'ion-account-button': true,
      'ion-account-button-large': size === 'large',
      'ion-account-button-middle': size === 'middle',
      'ion-account-button-small': size === 'small',
    }"
  >
    <div class="ion-account-button-inner">{{ firstLetter }}</div>
    <div :class="{ 'ion-account-button-status': true, offline: !isOnline }">
      <ion-icon :md="statusIcon" :ios="statusIcon"></ion-icon>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { IonIcon } from '@ionic/vue';
  import { checkmarkCircle, alertCircle } from 'ionicons/icons';

  export default {
    name: 'AccountButton',
    components: {
      IonIcon,
    },
    props: {
      size: {
        type: String,
        default: '',
      },
      noPadding: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('ui', ['isOnline']),
      statusIcon() {
        return this.isOnline ? checkmarkCircle : alertCircle;
      },
      firstLetter() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.firstName !== undefined && this.user.lastName !== null) {
          return (
            this.user.firstName.substring(0, 1).toUpperCase() +
            this.user.lastName.substring(0, 1).toUpperCase()
          );
        } else if (
          this.user.username !== undefined &&
          this.user.username !== null
        ) {
          return this.user.username.substring(0, 1).toUpperCase();
        }

        return '';
      },
      displayName() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        if (this.user.firstName !== undefined && this.user.lastName !== null) {
          return this.user.firstName + ' ' + this.user.lastName;
        } else if (
          this.user.username !== undefined &&
          this.user.username !== null
        ) {
          return this.user.username;
        }

        return '';
      },
    },
    methods: {},
  };
</script>

<style scoped>
  .ion-account-button {
    width: 48px;
    height: 48px;
    padding: 12px;
    font-size: 12px;
    color: var(--ion-color-primary-contrast);
    cursor: pointer;
  }

  .ion-account-button-small {
    width: 24px;
    height: 24px;
    padding: 0px;
    font-size: 10px;
    color: var(--ion-color-primary-contrast);
  }

  .ion-account-button-large {
    width: 72px;
    height: 72px;
    padding: 0px;
    font-size: 28px;
  }

  .ion-account-button-middle {
    width: 52px;
    height: 52px;
    padding: 0px;
    font-size: 22px;
  }

  .ion-account-button-inner {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 100%;
    background-color: var(--ion-color-primary);
  }

  .ion-account-button-status {
    position: relative;
    top: -10px;
    left: 15px;
    width: 1em;
    height: 1em;
    z-index: 100;
    border-radius: 100%;
    background-color: white;
    color: var(--ion-color-success);
  }

  .ion-account-button-status ion-icon {
    color: var(--ion-color-succes);
  }

  .ion-account-button-large .ion-account-button-status {
    top: -20px;
    left: 40px;
  }

  .ion-account-button-middle .ion-account-button-status {
    top: -18px;
    left: 35px;
  }

  .ion-account-button-status.offline,
  .ion-account-button-status.offline ion-icon {
    color: var(--ion-color-warning);
  }
</style>
