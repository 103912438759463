<template>
  <ion-item :lines="lines" :detail="!readonly" @click="openModal">
    <ion-label>
      <span style="font-weight: bold">{{ displayTitle }}</span>
      <span style="color: var(--ion-color-danger)" v-if="required">*</span>
    </ion-label>
    <custom-icon
      style="margin-right: 5px"
      v-if="displayIcon !== ''"
      :name="displayIcon"
    ></custom-icon>
    <ion-text v-if="!showAsStatus" style="text-align: right">{{
      displayText
    }}</ion-text>
    <ion-badge
      v-if="showAsStatus"
      :style="'text-align: right; background-color: ' + displayColor"
      >{{ displayText }}</ion-badge
    >
    <ion-modal
      :is-open="modalIsOpen"
      @didDismiss="closeModal"
      :show-backdrop="true"
      :presenting-element="presentingElementValue"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              v-if="isMobile"
              @click="closeModal"
              fill="clear"
              color="primary"
              >Abbrechen</ion-button
            >
          </ion-buttons>
          <ion-title>{{ displayTitle }}</ion-title>
          <ion-buttons slot="end">
            <ion-button
              v-if="isMobile"
              @click="saveModal"
              fill="clear"
              color="primary"
              >Anwenden</ion-button
            >
            <ion-button v-if="!isMobile" fill="clear" @click="closeModal"
              ><custom-icon slot="icon-only" name="closeOutline"></custom-icon
            ></ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list :inset="true">
          <ion-item-group>
            <ion-radio-group :value="value">
              <ion-item
                @click="value = option.value"
                v-for="option in options"
                :key="option.value"
              >
                <ion-label>{{
                  option.label !== undefined
                    ? option.label.findIndex((e) => e.language === language) !==
                      -1
                      ? option.label[
                          option.label.findIndex((e) => e.language === language)
                        ].text
                      : option.name
                    : option.name
                }}</ion-label>
                <ion-radio
                  mode="ios"
                  slot="start"
                  :value="option.value"
                ></ion-radio>
                <custom-icon
                  slot="start"
                  style="margin-right: 5px"
                  v-if="option.icon !== undefined"
                  :name="option.icon"
                ></custom-icon>
              </ion-item>
            </ion-radio-group>
          </ion-item-group>
        </ion-list>
      </ion-content>
      <ion-footer v-if="!isMobile">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button fill="clear" @click="closeModal">Abbrechen</ion-button>
            <ion-button color="primary" @click="saveModal" fill="clear"
              >Anwenden</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </ion-modal>
  </ion-item>
</template>

<script>
  import {
    IonText,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItemGroup,
    IonItem,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonFooter,
    IonTitle,
    IonBadge,
  } from '@ionic/vue';

  export default {
    components: {
      IonText,
      IonModal,
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonContent,
      IonList,
      IonItemGroup,
      IonItem,
      IonLabel,
      IonRadioGroup,
      IonRadio,
      IonFooter,
      IonTitle,
      IonBadge,
    },
    props: {
      name: {
        type: String,
        default: '',
      },
      modelValue: {
        type: Number,
        default: 0,
      },
      label: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Array,
        default: () => [],
      },
      presentingElement: {
        type: HTMLElement,
        default: undefined,
      },
      required: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      showAsStatus: {
        type: Boolean,
        default: false,
      },
      lines: {
        type: String,
        default: 'none',
      },
    },
    data() {
      return {
        modalIsOpen: false,
        presentingElementValue: undefined,
        value: 0,
      };
    },
    computed: {
      isMobile: function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      displayTitle() {
        let title = this.name;

        if (this.label !== undefined) {
          if (
            this.label.findIndex((e) => e.language === this.language) !== -1
          ) {
            return this.label[
              this.label.findIndex((e) => e.language === this.language)
            ].text;
          }
        }

        return title;
      },
      displayText() {
        if (this.options.findIndex((e) => e.value === this.modelValue) !== -1) {
          var option =
            this.options[
              this.options.findIndex((e) => e.value === this.modelValue)
            ];

          if (option.label !== undefined) {
            if (
              option.label.findIndex((e) => e.language === this.language) !== -1
            ) {
              return option.label[
                option.label.findIndex((e) => e.language === this.language)
              ].text;
            } else {
              return option.name;
            }
          } else {
            return option.name;
          }
        }

        return '';
      },
      displayColor() {
        if (this.options.findIndex((e) => e.value === this.modelValue) !== -1) {
          var option =
            this.options[
              this.options.findIndex((e) => e.value === this.modelValue)
            ];

          return option.color;
        }
        return 'var(--ion-color-light)';
      },
      displayIcon() {
        var options = this.options;
        if (options === null || options === undefined) {
          return '';
        }

        if (
          options.length === 0 ||
          options.length === undefined ||
          options.length === null
        ) {
          return '';
        }

        if (options.findIndex((e) => e.value === this.modelValue) !== -1) {
          return options[options.findIndex((e) => e.value === this.modelValue)]
            .icon !== undefined &&
            options[options.findIndex((e) => e.value === this.modelValue)]
              .icon !== null
            ? options[options.findIndex((e) => e.value === this.modelValue)]
                .icon
            : '';
        }

        return '';
      },
    },
    async mounted() {
      this.presentingElementValue = this.presentingElement;
      this.value = parseInt(this.modelValue);
    },
    methods: {
      async openModal() {
        if (this.readonly) {
          return;
        }
        this.value = this.modelValue;
        this.modalIsOpen = true;
      },
      async closeModal() {
        this.modalIsOpen = false;
      },
      async saveModal() {
        await this.setValue();
        this.modalIsOpen = false;
      },
      async setValue() {
        await this.$emit('update:modelValue', this.value);
      },
    },
    watch: {
      modelValue(n) {
        if (
          JSON.parse(JSON.stringify(this.value)) !==
          JSON.parse(JSON.stringify(n))
        ) {
          this.value = parseInt(n);
        }
      },
      presentingElement(n) {
        this.presentingElementValue = n;
      },
    },
  };
</script>

<style scoped>
  ion-content {
    --background: var(--ion-color-light);
  }

  ion-toolbar {
    --background: var(--ion-color-light);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-img > img {
    border-radius: 10px;
  }

  ion-item.item-radio-checked {
    --background: var(--ion-color-primary-tint);
  }

  ion-item.item-radio-checked > ion-radio {
    --ion-color-base: var(
      --ion-item-color,
      var(--ion-text-color, #000)
    ) !important;
    --color-checked: var(
      --ion-item-color,
      var(--ion-text-color, #000)
    ) !important;
  }
</style>
