import { Auth } from '@/services/authService';
import { AuthService } from 'baas-ionic-appauth';
import axios from 'axios';

export class Data {
  private static dataService: DataService | undefined;

  private static buildDataInstance() {
    const configString = localStorage.getItem('config');
    const config: { oidc: string; api: string } =
      configString === undefined
        ? { oidc: '', api: '' }
        : JSON.parse(configString || '{ "oidc":"", "api":"" }');
    const url = config.api;
    const dataService = new DataService(url, Auth.Instance);
    return dataService;
  }

  public static get Instance(): DataService {
    if (!this.dataService) {
      this.dataService = this.buildDataInstance();
    }
    return this.dataService;
  }
}

export class DataService {
  private url: string;
  private authService: AuthService;

  constructor(url: string, authService: AuthService) {
    this.url = url;
    this.authService = authService;
  }

  public async getModels(version = 0) {
    const data = {
      version: version,
    };
    const header = await this.getAuthHeader();
    const result = await axios.get(this.url + '/data/model/list', header);
    return result.data;
  }

  public async find(model: string, filter: any, options: any) {
    const data = {
      model: model,
      filter: filter,
      options: options,
    };
    const header = await this.getAuthHeader();
    const result = await axios.post(this.url + '/data/find', data, header);
    return result.data;
  }

  public async count(model: string, filter: any, options: any) {
    const data = {
      model: model,
      filter: filter,
      options: options,
    };
    const header = await this.getAuthHeader();
    const result = await axios.post(this.url + '/data/count', data, header);
    return result.data;
  }

  public async insert(model: string, items: any, options: any) {
    const data = {
      model: model,
      data: items,
      options: options,
    };
    const header = await this.getAuthHeader();
    const result = await axios.post(this.url + '/data/insert', data, header);
    return result.data;
  }

  public async update(model: string, filter: any, update: any, options: any) {
    const data = {
      model: model,
      filter: filter,
      update: update,
      options: options,
    };
    const header = await this.getAuthHeader();
    const result = await axios.post(this.url + '/data/update', data, header);
    return result.data;
  }

  public async delete(model: string, filter: any, options: any) {
    const data = {
      model: model,
      filter: filter,
      options: options,
    };
    const header = await this.getAuthHeader();
    const result = await axios.post(this.url + '/data/delete', data, header);
    return result.data;
  }

  public async archive(model: string, id: any) {
    const data = {
      model: model,
      filter: { _id: { $eq: { $oid: id } } },
      update: { _archive: 1 },
      options: {},
    };
    const header = await this.getAuthHeader();
    const result = await axios.post(this.url + '/data/update', data, header);
    return result.data;
  }

  public async restore(model: string, id: any) {
    const data = {
      model: model,
      filter: { _id: { $eq: { $oid: id } } },
      update: { _archive: 0 },
      options: {},
    };
    const header = await this.getAuthHeader();
    const result = await axios.post(this.url + '/data/update', data, header);
    return result.data;
  }

  private async getAuthHeader() {
    let token: any = undefined;
    try {
      token = await this.authService.getValidToken();
    } catch {
      await this.authService.signIn();
    }

    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token.accessToken,
          'x-authorization-provider': this.authService.authConfig.client_id,
        },
      };
      return config;
    } catch (error) {
      console.log(error);
      return { headers: {} };
    }
  }
}
