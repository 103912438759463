import { Model } from "@/models/model";
import { Module, Plugin } from "vuex";
import { VuexPersistence } from "vuex-persist";
import { State, store } from "..";
import { dataStorage } from "../helpers/dataStorage";
import { Data } from '@/services/dataService';
import  dataModule from "./data";

export const modelsPersistencePlugin = new VuexPersistence<State>({
  modules: ['models'],
  key: 'models',
  asyncStorage: true,
  storage: new dataStorage('models')
})

export type ModelState = {
  loading: boolean
  error: boolean
  errorMessage: string
  models: Model[]
};


const modelModule: Module<any, any> = {
    namespaced: true,
    state: {
        loading: true,
        error: false,
        errorMessage: '',
        models: [],
        version: 0
    },
    getters: {

    }, 
    actions: {
      async loadModels ({ state, commit }) {
        const data = await Data.Instance.getModels(state.version || 0)
        commit('setModel', data)
      }
    },
    mutations: {
        setModel (state, modelData:Model[]) {
            if (state.models === undefined || state.models === null) {
                state.models = []
            }
            state.models.splice(0, state.models.length)
            modelData.forEach(model => {
                state.models.push(model)
            }) 
        }
    }
}

export default modelModule;