import axios from "axios"

export class configService {
    public static async loadConfig () {
        try {
        const url = 'https://app.haller-pm.de'
        const config = {
            api: url + '/api',
            oidc: url + '/auth'
        }
        /*
        const config = atob((await axios.get(url + '/_/config')).data)
        */
        localStorage.setItem('config', JSON.stringify(config))
        } catch (error){
            console.log(error)
        }
    }
}