<template>
  <div style="width: 100%; height: calc(100% - 56px)">
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>{{ listTitle }} <br />Filtern &#38; Sortieren </ion-title>
        <ion-buttons slot="end">
          <ion-button icon-only shape="round" @click="closeModal"
            ><ion-icon
              slot="icon-only"
              :ios="closeIcon"
              :md="closeIcon"
            ></ion-icon
          ></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
      :fullscreen="true"
      style="height: calc(100% - 52px) !important"
    >
      <ion-list>
        <ion-item lines="none" style="width: 100%; margin: auto">
          <ion-label>
            <div>
              <p
                style="
                  margin-top: 0px;
                  margin-bottom: 0px;
                  color: var(--ion-color-medium);
                "
              >
                SORTIEREN NACH
              </p>
              <br />
              <ion-row>
                <ion-chip
                  style="paddin-bottom: 5px"
                  v-for="fieldSort in modelData.fields || []"
                  :key="'sort_' + fieldSort.name"
                  :color="
                    filterData.sortBy === undefined
                      ? ''
                      : filterData.sortBy.name === fieldSort.name
                      ? 'primary'
                      : ''
                  "
                  @click="setSortBy(fieldSort.name)"
                >
                  <ion-label>{{
                    formatLabel(fieldSort.label, fieldSort.name)
                  }}</ion-label>
                  <ion-icon
                    v-if="
                      filterData.sortBy === undefined
                        ? false
                        : filterData.sortBy.direction !== 'desc' &&
                          filterData.sortBy.name === fieldSort.name
                    "
                    :ios="arrowUp"
                    :md="arrowUp"
                  ></ion-icon>
                  <ion-icon
                    v-if="
                      filterData.sortBy === undefined
                        ? false
                        : filterData.sortBy.direction === 'desc' &&
                          filterData.sortBy.name === fieldSort.name
                    "
                    :ios="arrowDown"
                    :md="arrowDown"
                  ></ion-icon>
                </ion-chip>
              </ion-row>
            </div>
          </ion-label>
        </ion-item>
        <ion-item
          v-if="
            (modelData.fields || []).filter(
              (e) =>
                e.dataType === 'ObjectReference' || e.dataType === 'Enumeration'
            ).length > 0
          "
        >
          <ion-label>
            <p style="margin-top: 0px; color: var(--ion-color-medium)">
              FILTERN
            </p>
          </ion-label>
        </ion-item>
        <ion-item
          v-for="fieldFilter in (modelData.fields || []).filter(
            (e) =>
              e.dataType === 'ObjectReference' || e.dataType === 'Enumeration'
          )"
          :key="'filter_' + fieldFilter.name"
          @click="navigateTo('details', fieldFilter)"
          :detail="
            fieldFilter.dataType === 'ObjectReference' ||
            fieldFilter.dataType === 'Enumeration'
          "
        >
          <ion-label>
            {{ formatLabel(fieldFilter.label, fieldFilter.name) }}
          </ion-label>
          <ion-badge
            v-if="
              (filterData.filterField !== undefined
                ? filterData.filterField.findIndex(
                    (e) => e.name === fieldFilter.name
                  ) === -1
                  ? 0
                  : filterData.filterField[
                      filterData.filterField.findIndex(
                        (e) => e.name === fieldFilter.name
                      )
                    ].value === undefined
                  ? 0
                  : filterData.filterField[
                      filterData.filterField.findIndex(
                        (e) => e.name === fieldFilter.name
                      )
                    ].value.length
                : 0) !== 0
            "
            slot="end"
            >{{
              filterData.filterField !== undefined
                ? filterData.filterField.findIndex(
                    (e) => e.name === fieldFilter.name
                  ) === -1
                  ? 0
                  : filterData.filterField[
                      filterData.filterField.findIndex(
                        (e) => e.name === fieldFilter.name
                      )
                    ].value === undefined
                  ? 0
                  : filterData.filterField[
                      filterData.filterField.findIndex(
                        (e) => e.name === fieldFilter.name
                      )
                    ].value.length
                : 0
            }}</ion-badge
          >
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer class="actionsFooter" :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button fill="clear" @click="resetFilter"
            >Zurücksetzen</ion-button
          >
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button fill="clear" @click="closeModal">Fertig</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    modalController,
    IonChip,
    IonFooter,
    IonBadge,
  } from '@ionic/vue';
  import {
    logOutOutline,
    checkmarkOutline,
    closeOutline,
    arrowUpOutline,
    arrowDownOutline,
  } from 'ionicons/icons';
  import FilterModalDetail from '@/components/FilterModalDetail.vue';
  import { mapState, mapMutations } from 'vuex';

  export default defineComponent({
    name: 'AccountModal',
    components: {
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonContent,
      IonIcon,
      IonList,
      IonItem,
      IonLabel,
      IonChip,
      IonFooter,
      IonBadge,
    },
    data() {
      return {
        isOpen: false,
        showSettings: false,
        logOutIcon: logOutOutline,
        closeIcon: closeOutline,
        checkmarkIcon: checkmarkOutline,
        arrowUp: arrowUpOutline,
        arrowDown: arrowDownOutline,
        sortBy: '',
      };
    },
    props: {
      presentingElement: {
        type: Element,
      },
    },
    computed: {
      ...mapState('models', ['models']),
      ...mapState('ui', ['filters']),
      modelData: function () {
        const defaults = {};

        if (this.models === undefined || this.models === null) {
          return defaults;
        }

        let index = this.models.findIndex(
          (e) => e.name === this.$route.params.model
        );

        if (index === -1) {
          return defaults;
        }

        return this.models[index];
      },
      filterFields() {
        return [];
      },
      filterData() {
        if (
          this.filters.findIndex((e) => e.name === this.modelData.name) === -1
        ) {
          return {};
        } else {
          return this.filters[
            this.filters.findIndex((e) => e.name === this.modelData.name)
          ];
        }
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      listTitle() {
        if (
          this.modelData.listLabel === undefined ||
          this.modelData.listLabel === null
        ) {
          return this.modelData.name;
        }

        if (this.modelData.listLabel.length === 0) {
          return this.modelData.name;
        }

        if (this.modelData.listLabel.length === 1) {
          return this.modelData.listLabel[0].text;
        }

        if (
          this.modelData.listLabel.findIndex(
            (e) => e.language === this.language
          ) !== -1
        ) {
          return this.modelData.listLabel[
            this.modelData.listLabel.findIndex(
              (e) => e.language === this.language
            )
          ].text;
        } else {
          if (
            this.modelData.listLabel.findIndex((e) => e.language === 'en') !==
            -1
          ) {
            return this.modelData.listLabel[
              this.modelData.listLabel.findIndex((e) => e.language === 'en')
            ].text;
          } else {
            return this.modelData.name;
          }
        }
      },
    },
    methods: {
      ...mapMutations('ui', ['setFilter']),
      async closeModal() {
        await modalController.dismiss();
      },
      navigateTo(page, options) {
        const nav = document.querySelector('#modal-nav-filter');
        if (page === 'details' && nav !== null) {
          nav.push(FilterModalDetail, { field: options });
        }
      },
      async setSortBy(name) {
        var modelname = this.$route.params.model;
        var filter =
          this.filters[this.filters.findIndex((e) => e.name === modelname)];
        if (filter === undefined) {
          filter = {};
        }
        filter.name = modelname;
        var direction = 'asc';
        if (filter.sortBy !== undefined) {
          if (filter.sortBy.name === name) {
            if (filter.sortBy.direction === 'asc') {
              direction = 'desc';
            }
          }
        }
        filter.sortBy = { name: name, direction: direction };
        await this.setFilter(filter);
      },
      async resetFilter() {
        var modelname = this.$route.params.model;
        var filterIndex = this.filters.findIndex((e) => e.name === modelname);
        if (filterIndex !== -1) {
          await this.setFilter({
            name: modelname,
            sortBy: undefined,
            filterField: [],
          });
        }
      },
      formatLabel(labels, defaultValue) {
        if (labels === undefined || labels === null) {
          return defaultValue;
        }

        if (labels.length === 0) {
          return defaultValue;
        }

        if (labels.length === 1) {
          return labels[0].text;
        }

        if (labels.findIndex((e) => e.language === this.language) !== -1) {
          return labels[labels.findIndex((e) => e.language === this.language)]
            .text;
        } else {
          if (labels.findIndex((e) => e.language === 'en') !== -1) {
            return labels[labels.findIndex((e) => e.language === 'en')].text;
          } else {
            return defaultValue;
          }
        }
      },
    },
  });
</script>

<style scoped>
  ion-toolbar {
    --background: var(--ion-background-color, #fff);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-list {
    --ion-item-background: transparent;
  }

  .sortby {
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: auto;
    scrollbar-width: none;
    padding-bottom: 10px;
  }

  .plt-ios .sortby::-webkit-scrollbar {
    width: 20px;
  }

  .actionsFooter {
    border-top: 1px solid var(--ion-color-light-shade);
  }
</style>
