import { Storage } from '@capacitor/storage';
import { AsyncStorage } from "vuex-persist";

export class propertiesStorage implements AsyncStorage {
    _config?: { name: string; } | undefined;
    name: string;

    constructor (name:string) {
        this.name = name
    }

    async getItem<T> (key: string): Promise<T> {
        const result = await Storage.get({key: key})
        console.log(result)
        return JSON.parse(result.value || '{}')
    }
    async setItem<T> (key: string, data: T): Promise<T> {
        await Storage.set({ key, value: JSON.stringify(data)})
        return data
    }
    async removeItem (key: string): Promise<void> {
        await Storage.remove({ key: key })
    }
    async clear (): Promise<void> {
        await Storage.clear()
    }
    async length (): Promise<number> {
        throw new Error("Method not implemented.");
    }
    key (keyIndex: number): Promise<string> {
        throw new Error("Method not implemented.");
    }
    

}