<template>
  <div>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Einstellungen</ion-title>
        <ion-buttons slot="start">
          <ion-button fill="clear" @click="back"
            ><ion-icon slot="start" :ios="backIcon" :md="backIcon"></ion-icon
          ></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true"> </ion-content>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonIcon,
  } from '@ionic/vue';
  import {
    logOutOutline,
    settingsOutline,
    chevronBackOutline,
  } from 'ionicons/icons';
  import { mapState } from 'vuex';
  import AccountModalOverview from '@/components/AccountModalOverview.vue';

  export default defineComponent({
    name: 'AccountModal',
    components: {
      IonHeader,
      IonToolbar,
      IonTitle,
      IonButtons,
      IonButton,
      IonContent,
      IonIcon,
    },
    data() {
      return {
        isOpen: false,
        showSettings: false,
        logOutIcon: logOutOutline,
        rootPage: AccountModalOverview,
        settingsIcon: settingsOutline,
        backIcon: chevronBackOutline,
      };
    },
    props: {
      presentingElement: {
        type: Element,
      },
    },
    computed: {
      ...mapState('user', ['user']),
    },
    methods: {
      async back() {
        const nav = document.querySelector('#modal-nav');
        await nav.pop();
      },
    },
  });
</script>

<style scoped>
  ion-toolbar {
    --background: var(--ion-background-color, #fff);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-list {
    --ion-item-background: transparent;
  }
</style>
