<template>
  <div style="width: 100%; height: calc(100% - 51px)">
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button fill="clear" @click="back"
            ><ion-icon slot="start" :ios="backIcon" :md="backIcon"></ion-icon
          ></ion-button>
        </ion-buttons>
        <ion-title> {{ formatLabel(field.label, field.name) }} </ion-title>
        <ion-buttons slot="end">
          <ion-button icon-only shape="round" @click="closeModal"
            ><ion-icon
              slot="icon-only"
              :ios="closeIcon"
              :md="closeIcon"
            ></ion-icon
          ></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen style="height: calc(100% - 56px) !important">
      <ion-spinner
        v-if="loading"
        style="margin: auto; text-align: center; width: 100%"
      ></ion-spinner>
      <!-- ENUERATION -->
      <ion-list v-if="field.enumeration !== undefined" :inset="true">
        <ion-item-group>
          <ion-item
            @click="value = option.value"
            v-for="option in field.enumeration.options"
            :key="option.value"
          >
            <ion-label>{{
              option.label !== undefined
                ? option.label.findIndex((e) => e.language === language) !== -1
                  ? option.label[
                      option.label.findIndex((e) => e.language === language)
                    ].text
                  : option.name
                : option.name
            }}</ion-label>
            <ion-checkbox
              @ionChange="setFieldFilter(option.value)"
              :checked="
                filterData.filterField === undefined
                  ? false
                  : filterData.filterField.findIndex(
                      (e) => e.name === field.name
                    ) !== -1
                  ? filterData.filterField[
                      filterData.filterField.findIndex(
                        (e) => e.name === field.name
                      )
                    ].value === undefined
                    ? false
                    : filterData.filterField[
                        filterData.filterField.findIndex(
                          (e) => e.name === field.name
                        )
                      ].value.includes(option.value)
                  : false
              "
              slot="start"
            ></ion-checkbox>
            <custom-icon
              slot="start"
              style="margin-right: 5px"
              v-if="option.icon !== undefined"
              :name="option.icon"
            ></custom-icon>
          </ion-item>
        </ion-item-group>
      </ion-list>
      <!-- Object Reference -->
      <ion-list v-if="field.dataType === 'ObjectReference'" :inset="true">
        <ion-item-group>
          <ion-item
            @click="value = option.value"
            v-for="option in objectReferenceData"
            :key="option._id"
          >
            <ion-label>{{ option.name }}</ion-label>
            <ion-checkbox
              @ionChange="setFieldFilter(option._id)"
              :checked="
                filterData.filterField === undefined
                  ? false
                  : filterData.filterField.findIndex(
                      (e) => e.name === field.name
                    ) !== -1
                  ? filterData.filterField[
                      filterData.filterField.findIndex(
                        (e) => e.name === field.name
                      )
                    ].value === undefined
                    ? false
                    : filterData.filterField[
                        filterData.filterField.findIndex(
                          (e) => e.name === field.name
                        )
                      ].value.includes(option._id)
                  : false
              "
              slot="start"
            ></ion-checkbox>
          </ion-item>
        </ion-item-group> </ion-list
    ></ion-content>
    <ion-footer class="actionsFooter" :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button fill="clear" @click="resetFilter"
            >Zurücksetzen</ion-button
          >
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button fill="clear" @click="closeModal">Fertig</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonIcon,
    modalController,
    IonCheckbox,
    IonFooter,
    IonItem,
    IonList,
  } from '@ionic/vue';
  import {
    logOutOutline,
    checkmarkOutline,
    closeOutline,
    chevronBackOutline,
  } from 'ionicons/icons';
  import { mapState, mapMutations } from 'vuex';
  import { Data } from '@/services/dataService';
  import { Auth } from '@/services/authService';

  export default defineComponent({
    name: 'AccountModal',
    components: {
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonContent,
      IonIcon,
      IonCheckbox,
      IonFooter,
    },
    data() {
      return {
        isOpen: false,
        showSettings: false,
        logOutIcon: logOutOutline,
        closeIcon: closeOutline,
        checkmarkIcon: checkmarkOutline,
        backIcon: chevronBackOutline,
        dataLoaded: false,
        orf: undefined,
        loading: false,
      };
    },
    props: {
      presentingElement: {
        type: Element,
      },
      field: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    computed: {
      ...mapState('models', ['models']),
      ...mapState('ui', ['filters']),
      ...mapState('user', ['user']),
      modelData: function () {
        const defaults = {};

        if (this.models === undefined || this.models === null) {
          return defaults;
        }

        let index = this.models.findIndex(
          (e) => e.name === this.$route.params.model
        );

        if (index === -1) {
          return defaults;
        }

        return this.models[index];
      },
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      filterData() {
        if (
          this.filters.findIndex((e) => e.name === this.modelData.name) === -1
        ) {
          return {};
        } else {
          return this.filters[
            this.filters.findIndex((e) => e.name === this.modelData.name)
          ];
        }
      },
      objectReferenceData() {
        var data = [];
        if (this.orf === undefined) {
          this.loadData();
        }
        data = this.orf;
        return data;
      },
    },
    methods: {
      ...mapMutations('ui', ['setFilter']),
      async closeModal() {
        await modalController.dismiss();
      },
      async back() {
        const nav = document.querySelector('#modal-nav-filter');
        this.objectReferenceData = undefined;
        await nav.pop();
      },
      async loadData() {
        this.loading = true;
        if (this.field.dataType === 'ObjectReference') {
          await this.getCurrentEmployee();
          var filter = {};

          if (!this.isAdministrator && this.modelData.name === 'projectTime') {
            switch (this.field.referencedModel) {
              case 'employee':
                filter = {
                  _id: { $eq: { $oid: this.employee._id } },
                };
                break;
              default:
                break;
            }
          }

          this.orf = await Data.Instance.find(
            this.field.referencedModel,
            filter,
            {}
          );
        }
        this.loading = false;
      },
      async getCurrentEmployee() {
        if (this.user.firstName === undefined) {
          await Auth.Instance.loadUserInfo();
        }

        var e = await Data.Instance.find(
          'employee',
          { name: { $eq: this.user.firstName + ' ' + this.user.lastName } },
          {}
        );

        if (e !== undefined) {
          if (e.length !== 0) {
            this.employee = e[0];
          }
        }
      },
      formatLabel(labels, defaultValue) {
        if (labels === undefined || labels === null) {
          return defaultValue;
        }

        if (labels.length === 0) {
          return defaultValue;
        }

        if (labels.length === 1) {
          return labels[0].text;
        }

        if (labels.findIndex((e) => e.language === this.language) !== -1) {
          return labels[labels.findIndex((e) => e.language === this.language)]
            .text;
        } else {
          if (labels.findIndex((e) => e.language === 'en') !== -1) {
            return labels[labels.findIndex((e) => e.language === 'en')].text;
          } else {
            return defaultValue;
          }
        }
      },
      async resetFilter() {
        var modelname = this.$route.params.model;
        var filterIndex = this.filters.findIndex((e) => e.name === modelname);
        if (filterIndex !== -1) {
          await this.setFilter({
            name: modelname,
            sortBy: undefined,
            filterField: [],
          });
        }
      },
      async setFieldFilter(id) {
        var modelname = this.$route.params.model;
        var filter =
          this.filters[this.filters.findIndex((e) => e.name === modelname)];
        if (filter === undefined) {
          filter = {};
        }
        filter.name = modelname;

        if (filter.filterField === undefined) {
          filter.filterField = [];
        }

        if (
          filter.filterField.findIndex((e) => e.name === this.field.name) === -1
        ) {
          filter.filterField.push({
            name: this.field.name,
            value: [id],
          });
        } else {
          if (
            filter.filterField[
              filter.filterField.findIndex((e) => e.name === this.field.name)
            ].value === undefined
          ) {
            filter.filterField[
              filter.filterField.findIndex((e) => e.name === this.field.name)
            ].value = [id];
          } else {
            if (
              filter.filterField[
                filter.filterField.findIndex((e) => e.name === this.field.name)
              ].value.findIndex((e) => e === id) === -1
            ) {
              filter.filterField[
                filter.filterField.findIndex((e) => e.name === this.field.name)
              ].value.push(id);
            } else {
              filter.filterField[
                filter.filterField.findIndex((e) => e.name === this.field.name)
              ].value.splice(
                filter.filterField[
                  filter.filterField.findIndex(
                    (e) => e.name === this.field.name
                  )
                ].value.findIndex((e) => e === id),
                1
              );
            }
          }
        }

        await this.setFilter(filter);
      },
    },
  });
</script>

<style scoped>
  ion-toolbar {
    --background: var(--ion-background-color, #fff);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-list {
    --ion-item-background: transparent;
  }

  .actionsFooter {
    border-top: 1px solid var(--ion-color-light-shade);
  }
</style>
