import { createStore } from 'vuex';
import userModule, { userPersistencePlugin, UserState } from './modules/user';
import modelsModule, {
  modelsPersistencePlugin,
  ModelState,
} from './modules/models';
import dataModule, { dataPersistencePlugin, DataState } from './modules/data';
import uiModule, { uiPersistencePlugin, UIState } from './modules/ui';
import permissionModule, {
  permissionersistencePlugin,
  PermissionState,
} from './modules/permission';

export interface State {
  user: UserState;
  model: ModelState;
  data: DataState;
  ui: UIState;
  permission: PermissionState;
}

export const store = createStore({
  modules: {
    user: userModule,
    models: modelsModule,
    data: dataModule,
    ui: uiModule,
    permission: permissionModule,
  },
  plugins: [
    userPersistencePlugin.plugin,
    modelsPersistencePlugin.plugin,
    dataPersistencePlugin.plugin,
    uiPersistencePlugin.plugin,
    permissionersistencePlugin.plugin,
  ],
});
