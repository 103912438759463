import { App } from '@capacitor/app';
import { AuthService } from 'baas-ionic-appauth';
import {
  InAppBrowser,
  CapacitorStorage,
} from 'baas-ionic-appauth/lib/capacitor';
import { isPlatform } from '@ionic/vue';
import { Requestor } from '@openid/appauth';
import { XhrSettings } from 'baas-ionic-appauth/lib/cordova';
import axios, { AxiosResponse } from 'axios';
import { User } from '@/models/user';

export class Auth {
  private static authService: AuthService | undefined;
  public static config: { url: string };

  private static buildAuthInstance() {
    const authService = new AuthService(
      new InAppBrowser('afca0b', 'popover'),
      new CapacitorStorage(),
      new AxiosRequestor()
    );
    const configString = localStorage.getItem('config');
    const config: { oidc: string; api: string } =
      configString === undefined
        ? { oidc: '', api: '' }
        : JSON.parse(configString || '{ "oidc":"", "api":"" }');
    const url = config.oidc;
    authService.authConfig = {
      client_id: isPlatform('capacitor') ? 'bauversum_app' : 'bauversum_web',
      server_host: url,
      redirect_url: isPlatform('capacitor')
        ? 'de.benteleit.bauversum://oidc/callback'
        : window.location.origin + '/oidc/callback',
      end_session_redirect_url: isPlatform('capacitor')
        ? 'de.benteleit.bauversum://oidc/endsession'
        : window.location.origin + '/oidc/endsession',
      scopes: 'openid offline_access profile email',
      pkce: true,
    };

    if (isPlatform('capacitor')) {
      App.addListener('appUrlOpen', (data: any) => {
        if (data.url.indexOf(authService.authConfig.redirect_url) === 0) {
          authService.authorizationCallback(data.url);
        } else {
          console.log('-- END SESSION CALLBACK -- ', data);
          authService.endSessionCallback();
        }
      });
    }

    authService.init();
    return authService;
  }

  public static get Instance(): AuthService {
    if (!this.authService) {
      this.authService = this.buildAuthInstance();
      this.authService.user$.subscribe((u) => {
        if (u === undefined) {
          return;
        }
        const username: string = u.username || '';
        const firstName: string = u.profile.firstName || '';
        const lastName: string = u.profile.lastName || '';
        const isAdministrator: boolean = u.is_administrator || false;
        const roles: string[] = u.roles || [];
        const id: string = u.sub || '';
        const user: User = {
          _id: id,
          username: username,
          firstName: firstName,
          lastName: lastName,
          isAdministrator: isAdministrator,
          roles: roles,
        };
        //this.setUser(user)
      });
    }

    return this.authService;
  }
}

export class AxiosRequestor extends Requestor {
  public async xhr<T>(settings: XhrSettings) {
    const instance = axios.create({
      timeout: 2000,
      headers: settings.headers,
    });

    if (!settings.method) settings.method = 'GET';

    switch (settings.method) {
      case 'GET':
        return instance
          .get<T>(settings.url)
          .then((value: AxiosResponse<T>) => value.data);
      case 'POST':
        return instance
          .post<T>(settings.url, settings.data)
          .then((value: AxiosResponse<T>) => value.data);
      case 'PUT':
        return instance
          .put<T>(settings.url, settings.data)
          .then((value: AxiosResponse<T>) => value.data);
      case 'DELETE':
        return instance
          .delete<T>(settings.url)
          .then((value: AxiosResponse<T>) => value.data);
    }
  }
}
