
  import { Auth } from '@/services/authService';
  import { IonContent, IonPage, IonButton } from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { AuthActions } from 'baas-ionic-appauth';

  export default defineComponent({
    name: 'LandingPage',
    components: {
      IonContent,
      IonPage,
      IonButton,
    },
    data() {
      return {
        event: '',
        // sub: Subscription.EMPTY
      };
    },
    created() {
      Auth.Instance.events$.subscribe((action) => {
        this.event = JSON.stringify(action);
        if (action.action === AuthActions.SignInSuccess) {
          this.$router.push('/home');
        }
      });
    },

    methods: {
      signIn() {
        Auth.Instance.signIn();
      },
    },
  });
