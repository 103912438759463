<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button
            fill="clear"
            v-if="isiPad && !showMenu"
            icon-only
            shape="round"
            @click="setShowMenu(!showMenu)"
          >
            <svg version="1.1" width="23.0273" height="17.998">
              <g>
                <rect height="17.998" opacity="0" width="23.0273" x="0" y="0" />
                <path
                  fill="var(--ion-color-primary)"
                  d="M3.06641 17.998L19.9609 17.998Q21.5039 17.998 22.2656 17.2412Q23.0273 16.4844 23.0273 14.9707L23.0273 3.04688Q23.0273 1.5332 22.2656 0.776367Q21.5039 0.0195312 19.9609 0.0195312L3.06641 0.0195312Q1.5332 0.0195312 0.766602 0.776367Q0 1.5332 0 3.04688L0 14.9707Q0 16.4844 0.766602 17.2412Q1.5332 17.998 3.06641 17.998ZM3.08594 16.4258Q2.35352 16.4258 1.96289 16.04Q1.57227 15.6543 1.57227 14.8926L1.57227 3.125Q1.57227 2.36328 1.96289 1.97754Q2.35352 1.5918 3.08594 1.5918L19.9414 1.5918Q20.6641 1.5918 21.0596 1.97754Q21.4551 2.36328 21.4551 3.125L21.4551 14.8926Q21.4551 15.6543 21.0596 16.04Q20.6641 16.4258 19.9414 16.4258ZM7.44141 16.7285L8.97461 16.7285L8.97461 1.29883L7.44141 1.29883ZM5.56641 5.21484Q5.78125 5.21484 5.95215 5.04395Q6.12305 4.87305 6.12305 4.66797Q6.12305 4.44336 5.95215 4.28223Q5.78125 4.12109 5.56641 4.12109L3.4668 4.12109Q3.25195 4.12109 3.08594 4.28223Q2.91992 4.44336 2.91992 4.66797Q2.91992 4.87305 3.08594 5.04395Q3.25195 5.21484 3.4668 5.21484ZM5.56641 7.74414Q5.78125 7.74414 5.95215 7.57812Q6.12305 7.41211 6.12305 7.1875Q6.12305 6.97266 5.95215 6.81152Q5.78125 6.65039 5.56641 6.65039L3.4668 6.65039Q3.25195 6.65039 3.08594 6.81152Q2.91992 6.97266 2.91992 7.1875Q2.91992 7.41211 3.08594 7.57812Q3.25195 7.74414 3.4668 7.74414ZM5.56641 10.2637Q5.78125 10.2637 5.95215 10.1025Q6.12305 9.94141 6.12305 9.72656Q6.12305 9.50195 5.95215 9.34082Q5.78125 9.17969 5.56641 9.17969L3.4668 9.17969Q3.25195 9.17969 3.08594 9.34082Q2.91992 9.50195 2.91992 9.72656Q2.91992 9.94141 3.08594 10.1025Q3.25195 10.2637 3.4668 10.2637Z"
                />
              </g>
            </svg>
          </ion-button>
          <account-button
            v-if="isMobile && !isiPad"
            @click="openAccountModal"
          />
        </ion-buttons>
        <ion-title>Dashboard</ion-title>
        <ion-buttons slot="end"> </ion-buttons>
      </ion-toolbar>
      <ion-toolbar class="custom-top-toolbar">
        <ion-segment
          @ionChange="segmentChanged($event)"
          :value="selectedSegment"
        >
          <ion-segment-button value="T">
            <ion-label>Tag</ion-label>
          </ion-segment-button>
          <ion-segment-button value="W">
            <ion-label>Woche</ion-label>
          </ion-segment-button>
          <ion-segment-button value="M">
            <ion-label>Monat</ion-label>
          </ion-segment-button>
          <ion-segment-button value="J">
            <ion-label>Jahr</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" id="HomePageContent">
      <ion-refresher
        v-if="isMobile"
        slot="fixed"
        @ionRefresh="refresh($event)"
        ref="refresher"
      >
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-grid>
        <ion-row>
          <ion-col>
            <h3 style="padding: 0px 20px">Hallo {{ employee.name }},</h3>
            <p style="padding: 0px 20px; margin-bottom: 0px">
              deine Stundenübersicht für {{ segmentTitle }}.
              <br v-if="publicHolidayTitle !== ''" /><span>{{
                publicHolidayTitle
              }}</span>
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Stunden</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <ion-card-title
                  :style="
                    hours - hoursSoll > 0
                      ? 'color: var(--ion-color-success)'
                      : hours - hoursSoll === 0
                      ? ''
                      : 'color: var(--ion-color-danger)'
                  "
                >
                  {{ isNaN(hours) ? 0 : hours }}</ion-card-title
                >
                <span
                  >{{ isNaN(hours - hoursSoll) ? 0 : hours - hoursSoll }}h</span
                ><span>&nbsp; ({{ workingDays }} T)</span></ion-card-content
              >
            </ion-card>
          </ion-col>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Überstunden</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content
                ><ion-card-title
                  :style="
                    (isNaN(overTime) ? 0 : overTime) > 0
                      ? 'color: var(--ion-color-success)'
                      : (isNaN(overTime) ? 0 : overTime) === 0
                      ? ''
                      : 'color: var(--ion-color-danger)'
                  "
                >
                  {{ isNaN(overTime) ? 0 : overTime }}</ion-card-title
                ><br
              /></ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Urlaub</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <ion-card-title>
                  {{ holidays.length }}
                  {{ holidays.length == 1 ? 'Tag' : 'Tage' }}</ion-card-title
                >
                {{ holidaysCurrentYear.length }} /
                {{
                  employee.holidays +
                  (isNaN(employee.holidaysLastYear)
                    ? 0
                    : employee.holidaysLastYear)
                }}
                Tage</ion-card-content
              >
            </ion-card>
          </ion-col>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Gleitzeit</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <ion-card-title>
                  {{ flexDays.length }}
                  {{ flexDays.length == 1 ? 'Tag' : 'Tage' }}</ion-card-title
                >{{ flexDaysCurrentYear.length }}
                {{
                  flexDaysCurrentYear.length == 1 ? 'Tag' : 'Tage'
                }}</ion-card-content
              >
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Stunden / Überstunden</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content style="max-height: 300px"
                ><Doughnut
                  v-if="selectedSegment === 'T' && hours > 0"
                  :chart-data="hoursByDay"
                  :chart-options="{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'bottom',
                      },
                    },
                  }"
                />
                <p
                  v-if="selectedSegment === 'T'"
                  style="line-height: 300px; text-align: center"
                >
                  Keine Daten
                </p>
                <Bar
                  v-if="selectedSegment !== 'T'"
                  :chart-data="hoursByMultipleDays"
                  :chart-options="{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'bottom',
                      },
                    },
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                  }"
                />
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card
              style="
                margin-inline: 5px !important;
                margin: 10px 10px !important;
              "
            >
              <ion-card-header>
                <ion-card-subtitle>Stunden nach Projekt</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content style="max-height: 300px"
                ><Doughnut
                  v-if="selectedSegment === 'T'"
                  :chart-data="hoursByProjectByDay"
                  :chart-options="{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'bottom',
                      },
                    },
                  }"
                />
                <p
                  v-if="selectedSegment === 'T'"
                  style="line-height: 300px; text-align: center"
                >
                  Keine Daten
                </p>
                <Bar
                  v-if="selectedSegment !== 'T'"
                  :chart-data="hoursByProjectByMultipleDays"
                  :chart-options="{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'bottom',
                      },
                    },
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                  }"
                />
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p
              style="
                color: var(--ion-color-medium);
                padding: 0px 15px;
                margin: 0px;
                font-size: 12px;
              "
            >
              Die Berechnung auf diesem Dashboard erfolgen immer bis zum
              aktuellen Tag und für den aktuell angemeldeten Mitarbeiter.
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <account-modal
      ref="accountModal"
      :presentingElement="$parent.$refs.ionRouterOutlet"
    />
    <ion-loading
      :is-open="loading"
      cssClass="my-custom-class"
      message="Bitte warten..."
    >
    </ion-loading>
  </ion-page>
</template>

<script>
  import {
    isPlatform,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonSegment,
    IonSegmentButton,
    IonLoading,
    IonLabel,
    IonRefresher,
    IonRefresherContent,
  } from '@ionic/vue';
  import AccountButton from '@/components/AccountButton.vue';
  import AccountModal from '@/components/AccountModal.vue';
  import { menuSharp } from 'ionicons/icons';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { Doughnut, Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    Plugin,
    BarElement,
    LinearScale,
  } from 'chart.js';
  import moment from 'moment';
  import { Data } from '@/services/dataService';
  import { Auth } from '@/services/authService';
  import { getCalendarCurrentYear } from '@/helpers/calendar.js';

  export default {
    components: {
      IonPage,
      IonHeader,
      IonToolbar,
      IonButton,
      IonTitle,
      IonContent,
      IonButtons,
      IonGrid,
      IonRow,
      IonCol,
      AccountButton,
      AccountModal,
      IonCard,
      IonCardHeader,
      IonCardTitle,
      IonCardSubtitle,
      IonCardContent,
      Doughnut,
      Bar,
      IonSegment,
      IonSegmentButton,
      IonLoading,
      IonLabel,
      IonRefresher,
      IonRefresherContent,
    },
    data() {
      return {
        menuIcon: menuSharp,
        widthhoursByProject: 400,
        selectedSegment: 'T',
        employee: {},
        projectTime: [],
        triggerUpdate: 0,
        loading: true,
        workingTimeCalendar: [],
        palette: [
          'var(--ion-color-medium)',
          '#afca0b',
          '#53B94E',
          '#00A173',
          '#008482',
          '#006578',
          '#2F4858',

          '#83A000',
          '#3A9640',
          '#008662',
          '#007372',
          '#005D6E',
          '#2a414f',

          '#587800',
          '#217433',
          '#006C51',
          '#006262',
          '#005564',
          '#263a46',

          '#335200',
          '#0A5426',
          '#005441',
          '#005153',
          '#084D5B',
          '#21323e',

          '#b7cf23',
          '#64c060',
          '#1aaa81',
          '#1a908f',
          '#1a7486',
          '#445a69',

          '#c5d94f',
          '#83cd80',
          '#48bb9a',
          '#48a6a5',
          '#48909e',
          '#697b87',

          '#c7da54',
          '#87ce83',
          '#4dbd9d',
          '#4da9a8',
          '#4d93a1',
          '#6d7f8a',
        ],
      };
    },
    computed: {
      ...mapState('ui', ['showMenu']),
      ...mapState('user', ['user']),
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      prefersDark: function () {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      },
      isMobile: function () {
        return !isPlatform('desktop');
      },
      isiPad: function () {
        return isPlatform('ipad') || isPlatform('desktop');
      },
      language() {
        return navigator.language === undefined
          ? 'en'
          : navigator.language.substring(0, 2);
      },
      segmentTitle() {
        switch (this.selectedSegment) {
          case 'T':
            var weekDay = '';
            console.log('Weekday ', moment().day());
            switch (moment().day()) {
              case 1:
                weekDay = 'Montag';
                break;
              case 2:
                weekDay = 'Dienstag';
                break;
              case 3:
                weekDay = 'Mittwoch';
                break;
              case 4:
                weekDay = 'Donnerstag';
                break;
              case 5:
                weekDay = 'Freitag';
                break;
              case 6:
                weekDay = 'Samsatag';
                break;
              case 0:
                weekDay = 'Sonntag';
                break;

              default:
                break;
            }
            return (
              'heute, ' +
              weekDay +
              ' den ' +
              moment().lang('de').format('DD. MMMM 2022')
            );
          case 'W':
            var currentDate = moment().lang('de');
            var weekStart = currentDate.clone().startOf('week');
            var weekEnd = currentDate.clone().endOf('week');
            return (
              'die aktuelle Kalenderwoche ' +
              moment(moment().toDate(), 'MM-DD-YYYY').isoWeek() +
              ' (' +
              weekStart.format('DD.MM.YYYY') +
              ' - ' +
              weekEnd.format('DD.MM.YYYY') +
              ')'
            );
          case 'M':
            return (
              ' den aktuellen Monat ' + moment().lang('de').format('MMMM yyyy')
            );
          case 'J':
            return ' das aktuelle Jahr ' + moment().lang('de').format('yyyy');
          default:
            return '';
        }
      },
      hoursPerDay() {
        return this.employee.weeklyWorkingHours / 5;
      },
      workingDays() {
        console.log('Update Working Days ' + this.triggerUpdate);
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('day');

        switch (this.selectedSegment) {
          case 'T':
            start = currentDate.clone().startOf('day');
            break;
          case 'W':
            start = currentDate.clone().startOf('week');
            break;
          case 'M':
            start = currentDate.clone().startOf('month');
            break;
          case 'J':
            start = currentDate.clone().startOf('year');
            break;
          default:
            break;
        }

        return this.workdayCount(start, currentDate);
      },
      publicHolidays() {
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('day');
        var end = currentDate.clone().endOf('day');

        switch (this.selectedSegment) {
          case 'T':
            start = currentDate.clone().startOf('day');
            break;
          case 'W':
            start = currentDate.clone().startOf('week');
            break;
          case 'M':
            start = currentDate.clone().startOf('month');
            break;
          case 'J':
            start = currentDate.clone().startOf('year');
            break;
          default:
            break;
        }

        var publicHolidays = this.workingTimeCalendar.filter(
          (e) =>
            moment(e.date) > start &&
            moment(e.date) < end &&
            e.type === 0 &&
            moment(e.date).day() !== 0 &&
            moment(e.date).day() !== 6
        );

        return publicHolidays;
      },
      holidays() {
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('day');
        var end = currentDate.clone().endOf('day');

        switch (this.selectedSegment) {
          case 'T':
            start = currentDate.clone().startOf('day');
            break;
          case 'W':
            start = currentDate.clone().startOf('week');
            break;
          case 'M':
            start = currentDate.clone().startOf('month');
            break;
          case 'J':
            start = currentDate.clone().startOf('year');
            break;
          default:
            break;
        }

        var days = this.workingTimeCalendar.filter(
          (e) => moment(e.date) > start && moment(e.date) < end && e.type === 1
        );
        return days;
      },
      holidaysCurrentYear() {
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('year');
        var end = currentDate.clone().endOf('year');

        var days = this.workingTimeCalendar.filter(
          (e) => moment(e.date) > start && moment(e.date) < end && e.type === 1
        );
        return days;
      },
      flexDaysCurrentYear() {
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('year');
        var end = currentDate.clone().endOf('year');

        var days = this.workingTimeCalendar.filter(
          (e) => moment(e.date) > start && moment(e.date) < end && e.type === 2
        );
        return days;
      },
      flexDays() {
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('day');
        var end = currentDate.clone().endOf('day');

        switch (this.selectedSegment) {
          case 'T':
            start = currentDate.clone().startOf('day');
            break;
          case 'W':
            start = currentDate.clone().startOf('week');
            break;
          case 'M':
            start = currentDate.clone().startOf('month');
            break;
          case 'J':
            start = currentDate.clone().startOf('year');
            break;
          default:
            break;
        }

        var days = this.workingTimeCalendar.filter(
          (e) => moment(e.date) > start && moment(e.date) < end && e.type === 2
        );
        return days;
      },
      publicHolidayTitle() {
        var publicHolidays = this.publicHolidays;

        if (publicHolidays.length === 1) {
          return (
            'Der ' +
            moment(publicHolidays[0].date).format('DD.MM.YYYY') +
            ' ist ein gesetzlicher Feiertag (' +
            publicHolidays[0].description +
            ') und ist im ausgewählten Zeitraum enthalten.'
          );
        } else if (publicHolidays.length > 1) {
          return (
            'Im ausgewählten Zeitraum sind ' +
            publicHolidays.length +
            ' gesetzliche Feieratage enthalten.'
          );
        } else {
          return '';
        }
      },
      filterdProjectTime() {
        var data = [];
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('day');
        var end = currentDate.clone().endOf('day');

        switch (this.selectedSegment) {
          case 'T':
            start = currentDate.clone().startOf('day');
            break;
          case 'W':
            start = currentDate.clone().startOf('week');
            break;
          case 'M':
            start = currentDate.clone().startOf('month');
            break;
          case 'J':
            start = currentDate.clone().startOf('year');
            break;
          default:
            break;
        }

        this.projectTime.forEach((time) => {
          if (moment(time.date).isBetween(start, currentDate)) {
            data.push(time);
          }
        });

        return data;
      },
      hours() {
        console.log('Update hours ' + this.triggerUpdate);
        var h = 0;
        this.filterdProjectTime.forEach((time) => {
          var date = moment(time.date);
          var start = moment(time.timeFrom)
            .set('year', moment(date).year())
            .set('month', moment(date).month())
            .set('date', moment(date).day())
            .seconds(0)
            .millisecond(0);
          var end = moment(time.timeTo)
            .set('year', moment(date).year())
            .set('month', moment(date).month())
            .set('date', moment(date).day())
            .seconds(0)
            .millisecond(0);
          var duration = moment.duration(end.diff(start));
          h = h + duration._milliseconds / 3600000;
        });
        h += this.holidays.length * this.hoursPerDay;
        h += this.publicHolidays.length * this.hoursPerDay;
        h += this.flexDays.length * this.hoursPerDay;

        h = parseFloat(h.toFixed(2));
        return h;
      },
      hoursSoll() {
        console.log('Update hours soll ' + this.triggerUpdate);
        var soll = 0;
        var days = this.workingDays;
        soll = days * this.hoursPerDay;
        soll = parseFloat(soll.toFixed(2));
        return soll;
      },
      overTime() {
        console.log('Update over time ' + this.triggerUpdate);
        var overtime =
          this.hours + this.employee.overTimeLastYear - this.hoursSoll;
        overtime = parseFloat(overtime.toFixed(2));
        return overtime;
      },
      hoursByProjectByDay() {
        var labels = ['Sonstiges'];
        var data = [0];

        this.filterdProjectTime.forEach((time) => {
          var date = moment(time.date);
          var start = moment(time.timeFrom)
            .set('year', moment(date).year())
            .set('month', moment(date).month())
            .set('date', moment(date).day())
            .seconds(0)
            .millisecond(0);
          var end = moment(time.timeTo)
            .set('year', moment(date).year())
            .set('month', moment(date).month())
            .set('date', moment(date).day())
            .seconds(0)
            .millisecond(0);
          var duration = null;

          if (duration === null) {
            duration = moment.duration(end.diff(start));
          } else {
            duration.add(moment.duration(end.diff(start)));
          }
          if (time.projectId !== undefined) {
            if (!labels.includes(time.projectId.name)) {
              labels.push(time.projectId.name);
              data.push(duration);
            } else {
              data[0] = duration;
            }
          } else {
            data[0] = duration;
          }
        });
        var formattedData = [];
        data.forEach((d) => {
          formattedData.push(d._milliseconds / 3600000);
        });
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: this.palette,
              data: formattedData,
              borderWidth: 0,
            },
          ],
        };
      },
      hoursByProjectByMultipleDays() {
        var datasets = [];
        var labels = [];

        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('day');
        var end = currentDate.clone().endOf('day');

        var projects = this.filterdProjectTime.map((e) =>
          e.projectId === undefined ? 'Sonstiges' : e.projectId.name
        );
        projects.push('Sonstiges');
        projects = [...new Set(projects)];

        switch (this.selectedSegment) {
          case 'T':
            start = currentDate.clone().startOf('day');
            end = currentDate.clone().endOf('day');
            for (var d = moment(start); d.isBefore(end); d.add(1, 'days')) {
              labels.push(m.format('DD.MM.YYYY'));
            }
            break;
          case 'W':
            start = currentDate.clone().startOf('week');
            end = currentDate.clone().endOf('week');
            for (var m = moment(start); m.isBefore(end); m.add(1, 'days')) {
              labels.push(m.format('DD.MM.YYYY'));
            }
            break;
          case 'M':
            start = currentDate.clone().startOf('month');
            end = currentDate.clone().endOf('month');
            for (var w = moment(start); w.isBefore(end); w.add(1, 'weeks')) {
              labels.push(w.format('[KW] W'));
            }
            break;
          case 'J':
            start = currentDate.clone().startOf('year');
            end = currentDate.clone().endOf('year');
            for (var y = moment(start); y.isBefore(end); y.add(1, 'months')) {
              labels.push(y.format('MM/YYYY'));
            }
            break;
          default:
            break;
        }

        var count = 0;
        projects.forEach((project) => {
          var bg = this.palette[0];
          if (project !== 'Sonstiges') {
            if (this.palette.length - 1 === count) {
              count = 1;
            } else {
              count++;
            }

            bg = this.palette[count];
          }
          datasets.push({
            label: project,
            data: new Array(labels.length).fill(0),
            backgroundColor: bg,
          });
        });

        this.filterdProjectTime.forEach((time) => {
          var date = moment(time.date);
          var start = moment(time.timeFrom)
            .set('year', moment(date).year())
            .set('month', moment(date).month())
            .set('date', moment(date).day())
            .seconds(0)
            .millisecond(0);
          var end = moment(time.timeTo)
            .set('year', moment(date).year())
            .set('month', moment(date).month())
            .set('date', moment(date).day())
            .seconds(0)
            .millisecond(0);
          var duration = (duration = moment.duration(end.diff(start)));
          var index = -1;

          if (this.selectedSegment === 'T' || this.selectedSegment === 'W') {
            index = labels.findIndex((e) => e === date.format('DD.MM.YYYY'));
          } else if (this.selectedSegment === 'M') {
            index = labels.findIndex((e) => e === date.format('[KW] W'));
          } else if (this.selectedSegment === 'J') {
            index = labels.findIndex((e) => e === date.format('MM/YYYY'));
          }

          var datasetIndex =
            time.projectId !== undefined
              ? datasets.findIndex((e) => e.label === time.projectId.name)
              : datasets.findIndex((e) => e.label === 'Sonstiges');
          datasets[datasetIndex].data[index] =
            datasets[datasetIndex].data[index] +
            duration._milliseconds / 3600000;
        });

        return {
          labels: labels,
          datasets: datasets,
        };
      },
      hoursByDay() {
        var labels = [
          'Stunden',
          'Überstunden',
          'Urlaub',
          'Gleitzeit',
          'Feiertage',
        ];
        var data = [0];
        var date = moment().lang('de');
        var duration = null;
        this.filterdProjectTime.forEach((time) => {
          var date = moment(time.date);
          var start = moment(time.timeFrom)
            .set('year', moment(date).year())
            .set('month', moment(date).month())
            .set('date', moment(date).day())
            .seconds(0)
            .millisecond(0);
          var end = moment(time.timeTo)
            .set('year', moment(date).year())
            .set('month', moment(date).month())
            .set('date', moment(date).day())
            .seconds(0)
            .millisecond(0);

          if (duration === null) {
            duration = moment.duration(end.diff(start));
          } else {
            duration.add(moment.duration(end.diff(start)));
          }
        });
        var d = duration === null ? 0 : duration._milliseconds / 3600000;
        var holiday =
          (this.workingTimeCalendar.filter(
            (e) =>
              e.type === 1 &&
              moment(e.date).format('DD.MM.YYYY') === date.format('DD.MM.YYYY')
          ).length *
            this.employee.weeklyWorkingHours) /
          5;
        var flexDay =
          (this.workingTimeCalendar.filter(
            (e) =>
              e.type === 2 &&
              moment(e.date).format('DD.MM.YYYY') === date.format('DD.MM.YYYY')
          ).length *
            this.employee.weeklyWorkingHours) /
          5;
        var publicHoliday =
          (this.workingTimeCalendar.filter(
            (e) =>
              e.type === 0 &&
              moment(e.date).format('DD.MM.YYYY') === date.format('DD.MM.YYYY')
          ).length *
            this.employee.weeklyWorkingHours) /
          5;
        if (d > this.hoursPerDay) {
          data[0] = this.hoursPerDay;
          data[1] = d - this.hoursPerDay;
          data[2] = holiday;
          data[3] = flexDay;
          data[4] = publicHoliday;
        } else {
          data[0] = d;
          data[1] = 0;
          data[2] = holiday;
          data[3] = flexDay;
          data[4] = publicHoliday;
        }
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: [
                this.palette[1],
                this.palette[2],
                this.palette[3],
                this.palette[4],
                this.palette[5],
              ],
              data: data,
              borderWidth: 0,
            },
          ],
        };
      },
      hoursByMultipleDays() {
        var labels = [];
        var startDates = [];
        var endDates = [];
        var hoursSum = this.hoursPerDay;
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('day');
        var end = currentDate.clone().endOf('day');

        switch (this.selectedSegment) {
          case 'W':
            start = currentDate.clone().startOf('week');
            end = currentDate.clone().endOf('week');
            for (var m = moment(start); m.isBefore(end); m.add(1, 'days')) {
              labels.push(m.format('DD.MM.YYYY'));
              startDates.push(m.clone().startOf('day'));
              endDates.push(m.clone().endOf('day'));
            }
            break;
          case 'M':
            start = currentDate.clone().startOf('month');
            end = currentDate.clone().endOf('month');
            for (var w = moment(start); w.isBefore(end); w.add(1, 'weeks')) {
              labels.push(w.format('[KW] W'));
              if (w.clone().startOf('week') < start) {
                startDates.push(w.clone().startOf('month'));
              } else {
                startDates.push(w.clone().startOf('week'));
              }

              endDates.push(w.clone().endOf('week'));
            }
            break;
          case 'J':
            start = currentDate.clone().startOf('year');
            end = currentDate.clone().endOf('year');
            for (var y = moment(start); y.isBefore(end); y.add(1, 'months')) {
              labels.push(y.format('MM/YYYY'));
              startDates.push(y.clone().startOf('month'));
              endDates.push(y.clone().endOf('month'));
            }
            break;
          default:
            break;
        }

        hoursSum = this.hoursPerDay * this.workdayCount(start, end);

        var datasets = [
          {
            label: 'Stunden',
            backgroundColor: this.palette[1],
            data: new Array(labels.length).fill(0),
          },
          {
            label: 'Überstunden',
            backgroundColor: this.palette[2],
            data: new Array(labels.length).fill(0),
          },
          {
            label: 'Urlaub',
            backgroundColor: this.palette[3],
            data: new Array(labels.length).fill(0),
          },
          {
            label: 'Gleitzeit',
            backgroundColor: this.palette[4],
            data: new Array(labels.length).fill(0),
          },
          {
            label: 'Feiertage',
            backgroundColor: this.palette[5],
            data: new Array(labels.length).fill(0),
          },
        ];

        labels.forEach((label, index) => {
          console.log(
            label,
            startDates[index].format('DD.MM.YYYY'),
            endDates[index].format('DD.MM.YYYY')
          );

          var start = startDates[index];
          var end = endDates[index];
          var days = this.workdayCount(start, end);
          var pTime = this.filterdProjectTime.filter((e) =>
            moment(e.date).isBetween(start, end)
          );
          var pTimeSum = 0;
          var holidays = this.holidays.filter((e) =>
            moment(e.date).isBetween(start, end)
          );
          var holidaysSum = holidays.length * this.hoursPerDay;
          var flexDays = this.flexDays.filter((e) =>
            moment(e.date).isBetween(start, end)
          );
          var flexDaysSum = flexDays.length * this.hoursPerDay;
          this.filterdProjectTime
            .filter((e) => moment(e.date).isBetween(start, end))
            .forEach((time) => {
              var date = moment(time.date);
              var start = moment(time.timeFrom)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              var end = moment(time.timeTo)
                .set('year', moment(date).year())
                .set('month', moment(date).month())
                .set('date', moment(date).day())
                .seconds(0)
                .millisecond(0);
              pTimeSum =
                pTimeSum +
                moment.duration(end.diff(start))._milliseconds / 3600000;
            });
          var publicHolidays = this.publicHolidays.filter((e) =>
            moment(e.date).isBetween(start, end)
          );
          var publicHolidaysSum = publicHolidays.length * this.hoursPerDay;
          var overtime = 0;
          var soll = days * this.hoursPerDay;
          if (pTimeSum > soll) {
            overtime = pTimeSum - soll;
            pTimeSum = soll;
          }
          datasets[0].data[index] = pTimeSum;
          datasets[1].data[index] = overtime;
          datasets[2].data[index] = holidaysSum;
          datasets[3].data[index] = flexDaysSum;
          datasets[4].data[index] = publicHolidaysSum;
        });

        return {
          labels: labels,
          datasets: datasets,
        };
      },
    },
    created() {
      ChartJS.register(
        Title,
        Tooltip,
        Legend,
        ArcElement,
        CategoryScale,
        BarElement,
        LinearScale
      );
    },
    async mounted() {
      await this.loadData();
      window.addEventListener('resize', this.resize);
      this.widthhoursByProject =
        document.getElementById('HomePageContent').clientWidth;
    },
    async updated() {
      this.widthhoursByProject =
        document.getElementById('HomePageContent').clientWidth;
    },
    methods: {
      ...mapMutations('ui', ['setShowMenu']),
      ...mapActions('models', ['loadModels']),
      ...mapActions('permission', ['loadEmployeePermissions']),
      async refresh(event) {
        await this.loadData();
        if (event.detail.complete !== undefined) {
          event.detail.complete();
        }
      },
      async loadData() {
        this.loading = true;
        try {
          await this.loadModels();
          await this.getCurrentEmployee();
          await this.loadEmployeePermissions(this.employee._id);
          await this.getProjectTimeEmployee();
          await this.getWorkingTimeCalendarEmployee();
        } catch (error) {
          console.error(error);
        }

        this.loading = false;
      },
      openAccountModal() {
        this.$refs.accountModal.setOpen(true);
      },
      async segmentChanged(e) {
        this.selectedSegment = e.detail.value;
        this.triggerUpdate = this.triggerUpdate + 1;
      },
      async getCurrentEmployee() {
        if (this.user.firstName === undefined) {
          await Auth.Instance.loadUserInfo();
        }

        var e = await Data.Instance.find(
          'employee',
          { name: { $eq: this.user.firstName + ' ' + this.user.lastName } },
          {}
        );

        if (e !== undefined) {
          if (e.length !== 0) {
            this.employee = e[0];
          }
        }
      },
      async getProjectTimeEmployee() {
        if (this.employee._id === undefined) {
          return;
        }

        var e = await Data.Instance.find(
          'projectTime',
          {
            'employee._id': {
              $eq: { $oid: this.employee._id },
            },
          },
          {}
        );

        if (e !== undefined) {
          this.projectTime = e;
        }
      },
      async getWorkingTimeCalendarEmployee() {
        if (this.employee._id === undefined) {
          return;
        }
        var currentDate = moment().lang('de');
        var start = currentDate.clone().startOf('year');
        var end = currentDate.clone().endOf('year');
        this.workingTimeCalendar = await getCalendarCurrentYear(
          this.employee._id
        );
      },
      workdayCount(start, end) {
        var s = moment(start).clone().startOf('day');
        var e = moment(end).clone().endOf('day');
        var count = 0;
        for (var d = moment(s); d.isBefore(e); d.add(1, 'days')) {
          if (d.day() !== 6 && d.day() !== 0) {
            count++;
          }
        }

        return count;
      },
      isPublicHolliday(checkDay) {
        let day = checkDay.clone().startOf('day');
        return (
          this.workingTimeCalendar.findIndex(
            (e) => e.type === 0 && moment(e.date).startOf('day').isSame(day)
          ) !== -1
        );
      },
    },
  };
</script>

<style>
  #container {
    text-align: center;
    align-items: center;
    height: 100%;
    vertical-align: middle;
    margin: auto;
  }

  #container strong {
    font-size: 20px;
    line-height: 26px;
  }

  #container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }

  #container a {
    text-decoration: none;
  }

  .md .custom-top-toolbar {
    --background: var(--ion-color-light) !important;
  }

  canvas {
    max-height: 300px;
  }
</style>
