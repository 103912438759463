<template>
  <div>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Berechtigungen</ion-title>
        <ion-buttons slot="start">
          <ion-button fill="clear" @click="back"
            ><ion-icon slot="start" :ios="backIcon" :md="backIcon"></ion-icon
          ></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-list>
        <p style="padding-left: 16px; font-weight: 600">Rollen</p>
        <ion-item v-if="isAdministrator">
          <ion-label style="color: red">Administrator</ion-label>
        </ion-item>
        <ion-item v-for="role in roles || []" :key="role">
          <ion-label
            >{{ role.name }}<br /><span
              style="color: var(--ion-color-medium)"
              >{{ role.description }}</span
            ></ion-label
          >
        </ion-item>
        <p style="padding-left: 16px; font-weight: 600">Berechtigungen</p>
        <ion-item v-for="perm in calcAllPermissionElements" :key="perm">
          <ion-label
            >{{
              perm.label.findIndex((e) => e.language === 'de') === -1
                ? perm.name
                : perm.label[perm.label.findIndex((e) => e.language === 'de')]
                    .text
            }}<br />&nbsp;<ion-badge
              style="margin-top: 5px"
              color="warning"
              v-if="perm.filter === ''"
              slot="start"
            >
              eingeschränkt</ion-badge
            >&nbsp;<ion-badge
              style="margin-top: 5px"
              slot="start"
              color="medium"
              >{{
                calcPermissions[
                  calcPermissions.findIndex((e) => e.element === perm.name)
                ] !== undefined
                  ? calcPermissionLevelDesc(
                      calcPermissions[
                        calcPermissions.findIndex(
                          (e) => e.element === perm.name
                        )
                      ].permission
                    )
                  : 'Keine Berechtigung'
              }}</ion-badge
            >
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonBadge,
  } from '@ionic/vue';
  import {
    logOutOutline,
    settingsOutline,
    chevronBackOutline,
  } from 'ionicons/icons';
  import { mapState } from 'vuex';
  import AccountModalOverview from '@/components/AccountModalOverview.vue';

  export default defineComponent({
    name: 'AccountModal',
    components: {
      IonHeader,
      IonToolbar,
      IonTitle,
      IonButtons,
      IonButton,
      IonContent,
      IonIcon,
      IonList,
      IonItem,
      IonLabel,
      IonBadge,
    },
    data() {
      return {
        isOpen: false,
        showSettings: false,
        logOutIcon: logOutOutline,
        rootPage: AccountModalOverview,
        settingsIcon: settingsOutline,
        backIcon: chevronBackOutline,
      };
    },
    props: {
      presentingElement: {
        type: Element,
      },
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapState('models', ['models']),
      ...mapState('permission', ['employeePermission', 'permissions']),
      isAdministrator() {
        if (this.user === null || this.user === undefined) {
          return false;
        }

        if (this.user.isAdministrator === true) {
          return true;
        }

        return false;
      },
      roles() {
        if (this.user === null || this.user === undefined) {
          return '';
        }

        return this.employeePermission;
      },
      calcPermissions() {
        return this.permissions;
      },
      calcAllPermissionElements() {
        try {
          const permissionItemModel =
            this.models[
              this.models.findIndex((e) => e.name === 'permissionItem')
            ];
          const permisionItemPermissionEnum =
            permissionItemModel.fields[
              permissionItemModel.fields.findIndex((e) => e.name === 'element')
            ].enumeration.options;
          return permisionItemPermissionEnum;
        } catch {
          return '';
        }
      },
    },
    methods: {
      async back() {
        const nav = document.querySelector('#modal-nav');
        await nav.pop();
      },
      calcPermissionLevelDesc(n) {
        try {
          const permissionItemModel =
            this.models[
              this.models.findIndex((e) => e.name === 'permissionItem')
            ];
          const permisionItemPermissionEnum =
            permissionItemModel.fields[
              permissionItemModel.fields.findIndex(
                (e) => e.name === 'permission'
              )
            ].enumeration.options;
          console.log(n, permisionItemPermissionEnum);
          return permisionItemPermissionEnum[
            permisionItemPermissionEnum.findIndex((e) => e.value === n)
          ].label[
            permisionItemPermissionEnum[
              permisionItemPermissionEnum.findIndex((e) => e.value === n)
            ].label.findIndex((e) => e.language === 'de')
          ].text;
        } catch {
          return '';
        }
      },
    },
  });
</script>

<style scoped>
  ion-toolbar {
    --background: var(--ion-background-color, #fff);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-list {
    --ion-item-background: transparent;
  }
</style>
