<template>
  <ion-modal
    :is-open="isOpen"
    @didDismiss="setOpen(false)"
    :swipe-to-close="true"
    :show-backdrop="true"
    :presenting-element="presentingElement"
  >
    <ion-nav :root="rootPage" id="modal-nav-filter"></ion-nav>
  </ion-modal>
</template>

<script>
  import { defineComponent } from 'vue';
  import { IonModal, IonNav } from '@ionic/vue';
  import FilterModalOverview from '@/components/FilterModalOverview.vue';

  export default defineComponent({
    name: 'FilterModal',
    components: {
      IonModal,
      IonNav,
    },
    data() {
      return {
        isOpen: false,
        rootPage: FilterModalOverview,
      };
    },
    props: {
      presentingElement: {
        type: Element,
      },
      modelData: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    computed: {},
    methods: {
      async setOpen(state) {
        this.isOpen = state;
      },
    },
  });
</script>

<style scoped>
  ion-toolbar {
    --background: var(--ion-background-color, #fff);
    --border-color: transparent;
    --box-shadow: 0px;
  }

  ion-list {
    --ion-item-background: transparent;
  }
</style>
