import { Module } from 'vuex';
import { VuexPersistence } from 'vuex-persist';
import { State } from '..';
import { propertiesStorage } from '../helpers/propertiesStrorage';

export const uiPersistencePlugin = new VuexPersistence<State>({
  modules: ['ui'],
  key: 'ui',
  asyncStorage: true,
  storage: new propertiesStorage('ui'),
});

export type UIState = {
  showMenu: boolean;
  isOnline: boolean;
  isOfflineSince?: Date;
  filters: UIFilter[];
};

export type UIFilter = {
  name: string;
  sortBy?: UIFilterSortBy;
  filterField?: UIFilterField[];
};

export type UIFilterSortBy = {
  name: string;
  direction: string;
};

export type UIFilterField = {
  name: string;
  value?: string[];
};

const modelModule: Module<any, any> = {
  namespaced: true,
  state: {
    showMenu: true,
    isOnline: true,
    isOfflineSince: null,
    filters: [],
  },
  getters: {},
  actions: {},
  mutations: {
    async setIsOnline(state, isOnline) {
      if (state.isOnline !== isOnline) {
        console.log('Network status changed', isOnline);
        state.isOnline = isOnline;

        if (isOnline === false) {
          state.isOfflineSince = new Date();
        } else {
          state.isOfflineSince = null;
        }
      }
    },
    setShowMenu(state, showMenu) {
      state.showMenu = showMenu;
    },
    setFilter(state, filter: UIFilter) {
      if (
        state.filters.findIndex((e: UIFilter) => e.name === filter.name) === -1
      ) {
        state.filters.push(filter);
      } else {
        state.filters[
          state.filters.findIndex((e: UIFilter) => e.name === filter.name)
        ] = filter;
      }
    },
  },
};

export default modelModule;
