import { User } from "@/models/user";
import { Module } from "vuex";
import { VuexPersistence } from "vuex-persist";
import { dataStorage } from "../helpers/dataStorage";
import { State } from "..";

export const userPersistencePlugin = new VuexPersistence<State>({
  modules: ['user'],
  key: 'user',
  asyncStorage: true,
  storage: new dataStorage('user'),
})

export type UserState = {
  loading: boolean
  error: boolean
  errorMessage: string
  user: User
};


const userModule: Module<any, any> = {
    namespaced: true,
    state: {
        loading: true,
        error: false,
        errorMessage: '',
        user: {
            username: '',
            firstName: '',
            lastName: ''
        }
    },
    getters: {

    }, 
    actions: {
      setUser ({ commit }, user:User) {
        commit('setUser', user)
      }
    },
    mutations: {
      setUser (state, userData:User) {
        state.user = userData
      }
    }
}

export default userModule;